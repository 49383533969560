import React from 'react'

const UploadImageForm = () => {
  return (
    <div className='d-flex align-items-center gap-2 image-form-up'>
         <div className="green-dot_big"/> 
          <span>Upload Product Image </span>
    </div>
  )
}

export default UploadImageForm