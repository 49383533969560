export const actionTypes = {
    GET_CATEGORY: 'GET_CATEGORY',
    UPDATE_SIZES: 'UPDATE_SIZES',
    UPDATE_QUANTITY: 'UPDATE_QUANTITY',
    UPDATE_GENDER: "UPDATE_GENDER",
    CHANGE_INPUT : "CHANGE_INPUT",
    SUB_CATEGORY_SIZES: "SUB_CATEGORY_SIZES",
    SUB_CATEGORY: "SUB_CATEGORY",
    CATEGORY_ID: "CATEGORY_ID",
    UPDATE_COLOR: "UPDATE_COLOR",
    CLEAR_UPLOAD_STATE: "CLEAR_UPLOAD_STATE",
    RESTART_CATEGORY_SELECTION: "RESTART_CATEGORY_SELECTION",
    GET_MERCHANT_DETAILS: "GET_MERCHANT_DETAILS",
    SET_LOCATION_ERROR: "SET_LOCATION_ERROR",
    SET_COORDINATES: "SET_COORDINATES",
    SET_STATE: "SET_STATE", 
    SET_LOCALITY: "SET_LOCALITY",
    SET_NEIGHBORHOOD: "SET_NEIGHBORHOOD",
    SET_ADDRESS: "SET_ADDRESS",
    SET_LGA: "SET_LGA",
    SET_MERCHANT_STATION_ID: "SET_MERCHANT_STATION_ID",
    SET_LAT: "SET_LAT",
    SET_LONG: "SET_LONG", 
    SET_FRONTVIEW_IMAGE: "SET_FRONTVIEW_IMAGE",
    SET_LEFTVIEW_IMAGE: "SET_LEFTVIEW_IMAGE",
    SET_RIGHTVIEW_IMAGE: "SET_RIGHTVIEW_IMAGE",
    SET_REARVIEW_IMAGE: "SET_REARVIEW_IMAGE",
    SET_VIDEO: "SET_VIDEO",
    CLEAR_STATE: "CLEAR_STATE",
    EDIT_PRODUCT: "EDIT_PRODUCT",
    SET_PRODUCT: "SET_PRODUCT",
    REMOVE_PRODUCT: "REMOVE_PRODUCT",
    ADD_PRODUCT: "ADD_PRODUCT",
    SET_SUCCESS_DATA: "SET_SUCCESS_DATA",
    SET_USERID: "SET_USERID",
    SET_MERCHANT_FIRSTNAME: "SET_MERCHANT_FIRSTNAME",
    SET_MERCHANT_LASTNAME: "SET_MERCHANT_LASTNAME",
    SET_MERCHANT_PHONE: "SET_MERCHANT_PHONE",
    SET_MERCHANT_EMAIL: "SET_MERCHANT_EMAIL",
    SET_VEHICLE_TYPE: "SET_VEHICLE_TYPE"

  };
  