import React, { useRef, useEffect, useState,  useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { bgImages } from '../../../../helpers/category/category';
import { ListCategories } from '../../../../Actions/Category';
import { UploadSettings } from '../Settings';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { AppContext } from '../../../../AppContexts/AppContext';
import { actionTypes } from '../../../../AppContexts/constants';


const UploadCategories = () => {
    const dispatch = useDispatch();
    const {state,uploadDispatch, setShowColorPicker, setCurrentSizeIndex, editIndex} = useContext(AppContext);
    const slider = useRef(null);
    const [isCategoryIndex, setIsCategoryIndex] = useState(null);
    const {prefilledData} = state;
  
    const [hasSlide, setHasSlide] = useState({
        isBeginning: true,
        isEnd: false
    })
    const {category_error, list_categories,category_loading} = useSelector(state => state.allCategories);
    const {isBeginning, isEnd} = hasSlide;

    const handleSelectCategory = (data, index) => {
        const _id = data && data._id
        const newCategoryIndex = isCategoryIndex === index ? null : index;
        setIsCategoryIndex(newCategoryIndex);  
        uploadDispatch({ type: actionTypes.GET_CATEGORY, payload: newCategoryIndex !== null ? data : null });
        uploadDispatch({type: actionTypes.CATEGORY_ID, payload: _id});
        uploadDispatch({type: actionTypes.RESTART_CATEGORY_SELECTION});
        setShowColorPicker(false) 
        setCurrentSizeIndex(null)

      };
    const handleSelectedCategory = useCallback(() => {
        if(editIndex !== null && !category_loading){
            // const _id = category && category._id
            const category = list_categories && list_categories.find((category) => category._id === prefilledData.category);
            uploadDispatch({ type: actionTypes.GET_CATEGORY, payload: category });
        }
    }, [editIndex, uploadDispatch, category_loading, prefilledData.category,list_categories]);

    const handleActiveCategory = useCallback(() => {
        if(editIndex !== null){
            const _id = prefilledData.category
            setIsCategoryIndex(_id)
        }
     }, [editIndex,prefilledData.category])
     const fetchCategories = useCallback(() => {
        dispatch(ListCategories());
    }, [dispatch]);

    useEffect(() => {
        if (!category_loading && !list_categories.length) {
            fetchCategories();
        }
    }, [fetchCategories, category_loading, list_categories]);

    useEffect(() => {
        handleSelectedCategory()
    }, [handleSelectedCategory]);

    useEffect(() => {
        handleActiveCategory()
    }, [handleActiveCategory]);

    useEffect(() => {
        if(editIndex !== null){
            uploadDispatch({ type: actionTypes.GET_CATEGORY, payload: prefilledData?.get_category });
            uploadDispatch({type: actionTypes.CATEGORY_ID, payload: prefilledData?.category});
        }
    }, [editIndex, prefilledData.get_category, prefilledData.category, uploadDispatch])

  return (
    <section className='category-items'>
        {category_error && <p className="text-center text-danger">{category_error}</p>}
        {category_loading && <p>fetching categories...</p>}

        <div className='controlled-slider'>
            {
                !isBeginning ? (<button className="category-control-btn left" onClick={() => slider?.current?.slidePrev()}>
                <BsChevronLeft />
              </button>) : null
            }
            {
                !isEnd && list_categories?.length > 0 ? 
               (<button className="category-control-btn right" onClick={() => slider?.current?.slideNext()}><BsChevronRight /></button>) : null
            }
            <Swiper 
                {...UploadSettings}
                onSwiper={it => (slider.current = it)}
                onSlideChange={(e) => {
                     setHasSlide({
                          isBeginning: e.isBeginning,
                          isEnd: e.isEnd
                     })
                }}
            >
                {
                    list_categories && list_categories.map((category) => (
                        <SwiperSlide key={category._id} onClick={() => handleSelectCategory(category, category._id)}>
                            <div className='slides cursor-pointer position-relative'>
                                <img src={bgImages(category.name)} alt={`${category.name}`}
                                    className={isCategoryIndex === category._id ? "active-category" : ""}
                                 />
                                <span>{category.name}</span>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
           
        </div>
    </section>
  )
}

export default UploadCategories