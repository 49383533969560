const vehicleType = ["BIKE", "CAR", "VAN", "TRUCK"]
const vehicleTypeNumber = (arr) => {
    const type = arr.map((type) => {
        const isTruck =  type?.toUpperCase() === "TRUCK" ? 4 : "BIKE";
        const isMiniVan = type?.toUpperCase() === "MINI VAN" ? 3 : isTruck;
        const isVan = type?.toUpperCase() === "VAN" ? 3 : isMiniVan;
        const isCar = type?.toUpperCase() === "CAR" ? 2 : isVan
        return {
            type: type?.toUpperCase() === "MINI VAN" ? "VAN" : type,
            vehicleNumber: type?.toUpperCase() === "BIKE" ? 1 : isCar  
        }
    });
    return type;
}


const deliveryVehicle = (arr) => {
    const getVehicle =  arr?.sort((a,b) => {
        return b.vehicleNumber - a.vehicleNumber
    })
    return getVehicle && getVehicle[0] && getVehicle[0]["type"];
}

export {vehicleTypeNumber, deliveryVehicle, vehicleType}