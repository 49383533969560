import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";
export const searchAlphaApi = createApi({
    reducerPath: "searchAlphaApi",
    baseQuery: fetchBaseQuery({baseUrl:`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION,process.env.REACT_APP_PRODUCT_BASE_URL)}`}),
    endpoints: builder => ({
         searchAlpha: builder.query({
            query: ({searchTerm, page, limit}) => ({
                url: `/product/search?searchTerm=${searchTerm}&page=${page}&limit=${limit}`,
                method: "get"
            })
         })
    })
});

export const {useSearchAlphaQuery} = searchAlphaApi