import { Component } from "react";
import { IoIosWarning } from "react-icons/io";
import PageWrap from "./components/Layouts/PageWrap/PageWrap";
export class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
    reload(){
      window.location.reload(true);
    }
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    }
   
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
          <PageWrap>
            <div className="something__went-wrong">
                <div>
                  <IoIosWarning size={100} color="#cc3300"/>
                </div>
                <div>Something went wrong.</div>;
                <button onClick={() => {this.reload();}}>Reload</button>
            </div>
          </PageWrap>
        )
        
      }
  
      return this.props.children; 
    }
  }