import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";
export const cartApi = createApi({
        reducerPath: "cartApi",
        baseQuery: fetchBaseQuery({baseUrl:`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION,process.env.REACT_APP_PRODUCT_BASE_URL)}`}),
        tagTypes: ["Cart"],
        endpoints: (builder) => ({
            cartItems: builder.query({
                query: cartId => ({
                    url: `/cart/items/${cartId}`,
                    method: "get",
                }),
                providesTags: ["Cart"]
            }),
            addToCart: builder.mutation({
                query: body => ({
                    url: "/cart/add_to_cart",
                    method: "post",
                    body
                }),
                invalidatesTags: ["Cart"]
            }),
            updateCart: builder.mutation({
                query: body => ({
                    url: "/cart/update_quantity",
                    method: "put",
                    body
                }),
                invalidatesTags: ["Cart"]
            }),
            removeCartItem: builder.mutation({
                query: body => ({
                    url: "/cart/remove_cart_item",
                    method: "put",
                    body
                }),
                invalidatesTags: ["Cart"]
            }),
            clearCartItem: builder.mutation({
                query: cartId => ({
                    url: `/cart/clear_items/${cartId}`,
                    method: "put"
                }),
                invalidatesTags: ["Cart"]
            })
        })
});
export const {useCartItemsQuery, useAddToCartMutation, useClearCartItemMutation, useRemoveCartItemMutation, useUpdateCartMutation} = cartApi