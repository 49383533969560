import React, {useEffect, useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./delete.css";
import { useDeleteUserAccountMutation, useLoginUserMutation } from '../../../Actions/Auth/Auth';
import { logout, setAlphaUser } from '../../../Actions/Auth/authSlice';
import { FaTimes } from 'react-icons/fa';
import { handleAlphaError } from '../../../utils/handleAlphaError';
import PageWrap from '../PageWrap/PageWrap';


const DeleteAccount = () => {
    const [ deleteUserAccount, {error,isError, isSuccess, isLoading: isDeleteLoading, reset}] = useDeleteUserAccountMutation();
    const [loginUser, { isError: isLoginError, isLoading, error: loginError,data: loginData,isSuccess : isLoginSuccess}] = useLoginUserMutation();
    const [openModal, setOpenModal] = useState(false);
    const [authError, setAuthError] = useState("");
    const [err, setErr] = useState("")
    const [notAuthenticated, setNotAuthenticated] = useState(false)
    const deleteRef = useRef();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [password, setPassword] = useState("");
    const {token: userToken} = useSelector(state => state.auth);
    const errorMessage = isError && error?.hasOwnProperty("data") ? JSON.stringify(error.data) : JSON.stringify(error?.error);
    const errorCode = isError && JSON.stringify(error);
    const isExpired = error?.hasOwnProperty("data") && JSON.parse(errorMessage)["message"];
    const isExpiredCode = errorCode && JSON.parse(errorCode)["status"];
    const loginErrorMessage = handleAlphaError(isLoginError,loginError, "data");
    const _id = loginData?.results?.user?._id;
    const first_name = loginData?.results?.user?.first_name;
    const last_name = loginData?.results?.user?.last_name;
    const userEmail = loginData?.results?.user?.email;
    const phone = loginData?.results?.user?.phone;
    const token = loginData?.results?.token;
    const closeModal = () => {
        deleteRef?.current?.close();
        setOpenModal(false);
        reset();
    }
    
    const notEmpty = (e) => {
        e.preventDefault()
        if(email){
            setOpenModal(true)
           
        }else {
            setAuthError("Inputs must not be empty")
        }
    }
     const onSubmit = () => {
        if((isExpired === "Invalid token" && isExpiredCode === 401) || isExpiredCode === 403){
            setNotAuthenticated(false)
            dispatch(logout())
        }else {
            deleteUserAccount(email)
        }
     }

    const handleLogin = async (e) => {
        e.preventDefault();
        if(email && password){
                await loginUser({email, password, user_type: "user"})
        }else {
            setErr("Please Provide all details")
        }
    }
 
    useEffect(() => {
        if(deleteRef?.current && openModal && email){
            deleteRef.current?.showModal();
            setOpenModal(true)
        }else {
          deleteRef?.current?.close();
          setOpenModal(false)
        }
      }, [openModal, email]);
      useEffect(() => {
      const close = (e) => {
        if(e.key === "Escape"){
            deleteRef?.current?.close();
            setOpenModal(false)
        }
      }
      window.addEventListener('keydown', close)
         return () => window.removeEventListener('keydown', close)
      },[]);

      useEffect(() => {
        if(isExpired === "Invalid token" && isExpiredCode === 401){
            setNotAuthenticated(false)
            dispatch(logout())
        }
      }, [isExpired, isExpiredCode,dispatch]);
      useEffect(() => {
        if(isLoginSuccess && _id && first_name && last_name && userEmail && phone && token){
           dispatch(setAlphaUser({first_name, last_name, email: userEmail, phone, token, _id }));
        }
    },[isLoginSuccess,_id, first_name, last_name,userEmail, phone, token, dispatch]);
    useEffect(() => {
        if(userToken || isLoginSuccess){
            setNotAuthenticated(true)
        }
    }, [userToken, isLoginSuccess]);
  return (
      <PageWrap>
             <>
            <div className='account__delete-page'>
                <div className='account__delete-bg z-1'>
                    <div className='account__delete-container'>
                        <div className='account__name-display'>
                            <div className='account-title'> Delete User Account</div>
                        </div>         
                        
                    </div>
                </div>
                <form className='delete-form shadow p-3 bg-white rounded z-3' onSubmit={notEmpty}>
                    <div className='w-100'>
                        <input type='email' placeholder="Enter email address" className='user__acount-form' required value={email} onChange={(e) => {setEmail(e.target.value); setAuthError("")}}/>
                    </div>
                    <div className='w-100'>
                        <div>Reason for deleting account</div>
                        <textarea className='user__acount-form user__account-reason mt-1' onChange={(e) => {setMessage(e.target.value); setAuthError("")}}  value={message}>
                        </textarea>
                    </div>
                    {
                        authError && <p className='text-danger'>{authError}</p>
                    }
                    
                    <div className='w-100'>
                        <button className='account__delete-btn'>Delete account</button>
                    </div>
                </form>
            </div>
            {
            openModal &&
            <dialog ref={deleteRef} className='user__delete-modal shadow p-3 bg-white rounded'>
                {
                    !notAuthenticated ? <div className='login__user-delete'>
                        
                        <div className='login-header'>
                            Login to continue
                        </div>
                        <div className='cancel-login' onClick={closeModal}>
                            <FaTimes />
                        </div>
                        <div className='login-inputs'>
                            <div className='w-100'>
                                <input type='email' placeholder="Enter email address" className='user__acount-form' required value={email} onChange={(e) => {setEmail(e.target.value); setAuthError("")}}/>
                            </div>
                            <div className='w-100'>
                                <input type='password' placeholder="Enter password" className='user__acount-form' required value={password} onChange={(e) => {setPassword(e.target.value); setAuthError("")} }/>
                            </div>
                        </div>
                        {isLoginError && <p className='text-center text-danger login__error-msg'>{loginError.hasOwnProperty("data") ? JSON.parse(loginErrorMessage)["message"] : JSON.parse(loginErrorMessage)}</p>}
                    {err && <p className='text-center text-danger'>{err}</p>}
                    <div onClick={handleLogin}>
                        <button className='rounded shadow' disabled={isLoading}> {
                            isLoading ? 
                            <div className="spinner-border text-dark " role="status">
                            </div> : "sign in"
                        } </button>
                    </div>
                    </div> : 
                    <div className='confirm-delete'>
                        <div>Are you sure you want to delete {email}?</div>
                        {isSuccess && <p className='text-center text-success'>account has been deleted successfully</p>}
                        {isError && <p className='text-center text-danger login__error-msg'>{error.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>}
                        <div className='delete-actions'>
                            {
                                <button onClick={onSubmit} disabled={isDeleteLoading || isSuccess}>
                                    {
                                        isDeleteLoading ? 
                                        <div className="spinner-border text-light d-flex justify-content-center align-items-center" role="status">
                                        </div> : "Yes"
                                    }
                                </button>
                            }
                            
                            <button onClick={closeModal}>{isSuccess ? "Close" : "Cancel"}</button>
                    </div>
                    
                </div>
                }
                
            </dialog>
            }
        
            </>
      </PageWrap>
           
  )
}

export default DeleteAccount