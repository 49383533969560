import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {FaCheckCircle,FaTimes} from "react-icons/fa"
import { DisplayContext } from "../../../../AppContexts/DisplayContext";
import { useDispatch } from 'react-redux';
import { removeBasket } from '../../../../Actions/Cart/cartSlice';

const SuccessfulTransaction = () => {
    const {showMobileDeliveryCheckoutOption,storeLink,lastItemId} = useContext(DisplayContext);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const productLink = `/product/guest/${lastItemId}`;


const continueShopping = () => {
     if(storeLink){
         navigate(storeLink)
     }else {
        navigate(productLink);
        window.location.reload(true);
     }
}
function clearState() {
    continueShopping()
    dispatch(removeBasket())

}
useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

    return (
        <div className={showMobileDeliveryCheckoutOption === 8 ? "success__message_container mobile-success d-block" : "d-none"}>
             <div className="success-cancel"  onClick={clearState}><FaTimes className="cancel-success-modal" size={24}/></div>
             <div className="transaction_success">
                 <div className="transaction_content">
                      <div className="success_image">
                         <FaCheckCircle className="success_icon"/>
                      </div>
                      
                      <h4 className="success_text">Order has been placed successfully</h4>
                    
                      <p className="success_message">Your order has been placed successfully. <br />The order is on the way to the seller for acceptance, your tracking ID will be sent to your email.</p>
                 </div>
            </div>
            <div className="content_footer_mobile">
                <hr className="download_hr"/>
                <div className="download_placeholder">Download Alpha APP</div>
                <div className="download_app_links">
                    <div className="appstore">
                         <a href="https://apps.apple.com/us/app/alphashop/id1615734219" target="_blank" rel="noreferrer">
                            <img src="/appstore.png" alt="playstore" className="download_app"/>
                         </a>
                    </div>
                    <div className="playstore">
                      <a href="https://play.google.com/store/apps/details?id=com.thealphashops" target="_blank" rel="noreferrer">
                          <img src="/playstore.png" alt="playstore" className="download_app"/>
                       </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessfulTransaction;