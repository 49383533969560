import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";
import {config} from "../../config";

export const productApi = createApi({
        reducerPath: "authApi",
        baseQuery: fetchBaseQuery({baseUrl: `${getUrl(config.prodProductUrl,config.devProductUrl)}`,  prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
        
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
              headers.set('authorization', `Bearer ${token}`)
            }
        
            return headers
          }}),
        endpoints: (builder) => ({
            getAllProducts: builder.query({
                query: ({page, limit})=> `/product/all?page=${page}&limit=${limit}`,
                providesTags: ['products']
            }),
            closestProducts: builder.query({
                query: ({lat, long, page, limit})=> `/product/search_location?lat=${lat}&long=${long}&page=${page}&limit=${limit}`,
                providesTags: ['products']
            }),
        })
    })

export const { useGetAllProductsQuery, useClosestProductsQuery } = productApi