import React, {useCallback, useRef} from 'react'
import VideoPlayer from './VideoPlayer';
import { FaTimes } from 'react-icons/fa';
import "./alphavideoplayer.css"

const VideoPost = ({videoUrl, showVideoModal, setShowVideoModal}) => {
  const videoRef = useRef();
  const stopPlaying = useCallback(() => {
    if(videoRef.current){
        videoRef.current.pause()
    } 
  }, [videoRef])
  return (
    <div className={showVideoModal ? "alpha-video-post": "d-none"}>
        <VideoPlayer videoUrl={videoUrl} showVideoModal={showVideoModal} videoRef={videoRef}/>
        <div className={showVideoModal ? "close-video": "d-none"}  onClick={() => {setShowVideoModal(false); stopPlaying()}}>
            <FaTimes />   
        </div>
    </div>
  )
}

export default VideoPost