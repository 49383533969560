export const customColors = [
     '#333333', '#000000', '#FFFFFF','#696969', '#708090','#A9A9A9',
     '#778899','#B2BEB5','#343434','#353839', '#414A4C', '#000080',
     '#4169E1','#7B68EE','#6A5ACD','#483D8B','#B0C4DE','#4682B4','#6495ED',
     '#87CEEB','#87CEFA','#FF0000', '#00FF00', '#0000FF', '#FFFF00', 
     '#FF00FF', '#00FFFF', // Primary and secondary colors
    '#800000', '#008000', '#000080', '#808000', '#800080', '#008080', // Darker variants
    '#FFC0CB', '#FFD700', '#D3D3D3', '#A9A9A9', '#FF6347', '#7CFC00', // Lighter and other colors
    '#4682B4', '#ADFF2F', '#FFE4C4', '#FF69B4', '#E6E6FA', '#FFFACD',
    '#AFEEEE', '#DDA0DD', '#EEE8AA', '#98FB98', '#CD853F', '#FFDAB9',
    '#F4A460', '#D2B48C', '#66CDAA', '#FFB6C1', '#FAFAD2', '#E0FFFF',
    '#FAEBD7', '#8B4513', '#FF4500', '#32CD32', '#00FF7F', '#4682B4',
    '#6A5ACD', '#708090', '#FFFAF0', '#F0FFF0', '#F0FFFF', '#F0F8FF',
    '#FFF5EE', '#F5F5DC', '#FFE4C4', '#FFDEAD', '#FFD700', '#FFFACD',
    '#FAF0E6', '#FFF0F5', '#FFDAB9', '#FF1493', '#FF4500', '#FF8C00',
    '#32CD32', '#00FA9A', '#8A2BE2', '#A52A2A', '#5F9EA0', '#D2691E',
    '#FF7F50', '#6495ED', '#DC143C', '#00008B', '#008B8B', '#B8860B',
    '#A9A9A9', '#006400', '#BDB76B', '#8B008B', '#556B2F', '#FF8C00',
    '#9932CC', '#8B0000', '#E9967A', '#8FBC8F', '#483D8B', '#2F4F4F',
    '#00CED1', '#9400D3', '#FF1493', '#00BFFF', '#696969', '#1E90FF',
    '#B22222', '#FFFAF0', '#228B22', '#FF00FF', '#DCDCDC', '#FFD700',
    '#DAA520', '#ADFF2F', '#F0FFF0', '#FF69B4', '#CD5C5C', '#4B0082',
    '#FFFFF0', '#F0E68C', '#E6E6FA', '#FFF0F5', '#7CFC00', '#FFFACD',
    '#ADD8E6', '#F08080', '#E0FFFF', '#FAFAD2', '#D3D3D3', '#90EE90',
    '#FFB6C1', '#FFA07A', '#20B2AA', '#87CEFA', '#778899', '#B0C4DE',
    '#FFFFE0', '#00FF00', '#32CD32', '#FAF0E6', '#FF00FF', '#800000',
    '#66CDAA', '#0000CD', '#BA55D3', '#9370DB', '#3CB371', '#7B68EE',
    '#00FA9A', '#48D1CC', '#C71585', '#191970', '#F5FFFA', '#FFE4E1',
    '#FFE4B5', '#FFDEAD', '#000080', '#FDF5E6', '#808000', '#6B8E23',
    '#FFA500', '#FF4500', '#DA70D6', '#EEE8AA', '#98FB98', '#AFEEEE',
    '#DB7093', '#FFEFD5', '#FFDAB9', '#CD853F', '#FFC0CB', '#DDA0DD',
    '#B0E0E6', '#800080', '#663399', '#FF0000', '#BC8F8F', '#4169E1',
    '#8B4513', '#FA8072', '#FAA460', '#2E8B57', '#FFF5EE', '#A0522D',
    '#C0C0C0', '#87CEEB', '#6A5ACD', '#708090', '#FFFAFA', '#00FF7F',
    '#4682B4', '#D2B48C', '#008080', '#D8BFD8', '#FF6347', '#40E0D0',
    '#EE82EE', '#F5DEB3', '#FFFFFF', '#F5F5F5', '#FFFF00', '#9ACD32',
    '#00BFFF', '#1E90FF', '#6495ED', '#7FFFD4', '#D2691E', '#FF69B4',
    '#F0E68C', '#E6E6FA', '#FFF0F5', '#B22222', '#FFD700', '#DAA520',
    '#7FFF00', '#00FA9A', '#ADFF2F', '#4B0082', '#FF4500', '#FF8C00',
    '#FF6347', '#7CFC00', '#00FF7F', '#F5F5DC', '#FFE4C4', '#FFDEAD',
    '#FFD700', '#FAFAD2', '#FFEFD5', '#FFDAB9', '#FFE4B5', '#FF4500',
    '#FF8C00', '#FFA500', '#FF6347', '#FF4500', '#FF7F50', '#6495ED',
    '#DC143C', '#00008B', '#008B8B', '#B8860B', '#A9A9A9', '#006400',
    '#BDB76B', '#8B008B', '#556B2F', '#FF8C00', '#9932CC', '#8B0000',
    '#E9967A', '#8FBC8F', '#483D8B', '#2F4F4F', '#00CED1', '#9400D3',
    '#FF1493', '#00BFFF', '#696969', '#1E90FF', '#B22222', '#FFFAF0',
    '#228B22', '#FF00FF', '#DCDCDC', '#FFD700', '#DAA520', '#ADFF2F',
    '#F0FFF0', '#FF69B4', '#CD5C5C', '#4B0082', '#FFFFF0', '#F0E68C',
    '#E6E6FA', '#FFF0F5', '#7CFC00', '#FFFACD', '#ADD8E6', '#F08080',
    '#E0FFFF', '#FAFAD2', '#D3D3D3', '#90EE90', '#FFB6C1', '#FFA07A',
    '#20B2AA', '#87CEFA', '#778899', '#B0C4DE', '#FFFFE0', '#00FF00',
    '#32CD32', '#FAF0E6', '#FF00FF', '#800000', '#66CDAA', '#0000CD',
    '#BA55D3', '#9370DB', '#3CB371', '#7B68EE', '#00FA9A', '#48D1CC',
    '#C71585', '#191970', '#F5FFFA', '#FFE4E1', '#FFE4B5', '#FFDEAD',
    '#000080', '#FDF5E6', '#808000', '#6B8E23', '#FFA500', '#FF4500',
    '#DA70D6', '#EEE8AA', '#98FB98', '#AFEEEE', '#DB7093', '#FFEFD5',
    '#FFDAB9', '#CD853F', '#FFC0CB', '#DDA0DD', '#B0E0E6', '#800080',
    '#663399', '#FF0000', '#BC8F8F', '#4169E1', '#8B4513', '#FA8072',
    '#FAA460', '#2E8B57', '#FFF5EE', '#A0522D', '#C0C0C0', '#87CEEB',
    '#6A5ACD', '#708090', '#FFFAFA', '#00FF7F', '#4682B4', '#D2B48C',
    '#008080', '#D8BFD8', '#FF6347', '#40E0D0', '#EE82EE', '#F5DEB3',
    '#FFFFFF', '#F5F5F5', '#FFFF00', '#9ACD32', '#00BFFF', '#1E90FF',
    '#6495ED', '#7FFFD4', '#D2691E', '#FF69B4', '#F0E68C', '#E6E6FA',
    '#FFF0F5', '#B22222', '#FFD700', '#DAA520', '#7FFF00', '#00FA9A',
    '#ADFF2F', '#4B0082', '#FF4500', '#FF8C00', '#FF6347', '#7CFC00',
    '#00FF7F', '#F5F5DC', '#FFE4C4', '#FFDEAD', '#FFD700', '#FAFAD2',
    '#FFEFD5', '#FFDAB9', '#FFE4B5', '#FF4500', '#FF8C00', '#FFA500',
    '#FF6347', '#FF4500', '#FF7F50', '#6495ED', '#DC143C', '#00008B',
    '#008B8B', '#B8860B', '#A9A9A9', '#006400', '#BDB76B', '#8B008B',
    '#556B2F', '#FF8C00', '#9932CC', '#8B0000', '#E9967A', '#8FBC8F',
    '#483D8B', '#2F4F4F', '#00CED1', '#9400D3', '#FF1493', '#00BFFF',
    '#696969', '#1E90FF', '#B22222', '#FFFAF0', '#228B22', '#FF00FF',
    '#DCDCDC', '#FFD700', '#DAA520', '#ADFF2F', '#F0FFF0', '#FF69B4',
    '#CD5C5C', '#4B0082', '#FFFFF0', '#F0E68C', '#E6E6FA', '#FFF0F5',
    '#7CFC00', '#FFFACD', '#ADD8E6', '#F08080', '#E0FFFF', '#FAFAD2',
    '#D3D3D3', '#90EE90', '#FFB6C1', '#FFA07A', '#20B2AA', '#87CEFA',
    '#778899', '#B0C4DE', '#FFFFE0', '#00FF00', '#32CD32', '#FAF0E6',
    '#FF00FF', '#800000', '#66CDAA', '#0000CD', '#BA55D3', '#9370DB',
    '#3CB371', '#7B68EE', '#00FA9A', '#48D1CC', '#C71585', '#191970',
    '#F5FFFA', '#FFE4E1', '#FFE4B5', '#FFDEAD', '#000080', '#FDF5E6',
    '#808000', '#6B8E23', '#FFA500', '#FF4500', '#DA70D6', '#EEE8AA',
    '#98FB98', '#AFEEEE', '#DB7093', '#FFEFD5', '#FFDAB9', '#CD853F',
    '#FFC0CB', '#DDA0DD', '#B0E0E6', '#800080', '#663399', '#FF0000',
    '#BC8F8F', '#4169E1', '#8B4513', '#FA8072', '#FAA460', '#2E8B57',
    '#FFF5EE', '#A0522D', '#C0C0C0', '#87CEEB', '#6A5ACD', '#708090',
    '#FFFAFA', '#00FF7F', '#4682B4', '#D2B48C', '#008080', '#D8BFD8',
    '#FF6347', '#40E0D0', '#EE82EE', '#F5DEB3', '#FFFFFF', '#F5F5F5',
    '#FFFF00', '#9ACD32', '#00BFFF', '#1E90FF', '#6495ED', '#7FFFD4',
    '#D2691E', '#FF69B4', '#F0E68C', '#E6E6FA', '#FFF0F5', '#B22222',
    '#FFD700', '#DAA520', '#7FFF00', '#00FA9A', '#ADFF2F', '#4B0082',
    '#FF4500', '#FF8C00', '#FF6347', '#7CFC00', '#00FF7F', '#F5F5DC',
    '#FFE4C4', '#FFDEAD', '#FFD700', '#FAFAD2', '#FFEFD5', '#FFDAB9',
    '#FFE4B5', '#FF4500', '#FF8C00', '#FFA500', '#FF6347', '#FF4500',
    '#FF7F50', '#6495ED', '#DC143C', '#00008B', '#008B8B', '#B8860B',
    '#A9A9A9', '#006400', '#BDB76B', '#8B008B', '#556B2F', '#FF8C00',
    '#9932CC', '#8B0000', '#E9967A', '#8FBC8F', '#483D8B', '#2F4F4F',
    '#00CED1', '#9400D3', '#FF1493', '#00BFFF', '#696969', '#1E90FF',
    '#B22222', '#FFFAF0', '#228B22', '#FF00FF', '#DCDCDC', '#FFD700',
    '#DAA520', '#ADFF2F', '#F0FFF0', '#FF69B4', '#CD5C5C', '#4B0082',
    '#FFFFF0', '#F0E68C', '#E6E6FA', '#FFF0F5', '#7CFC00', '#FFFACD',
    '#ADD8E6', '#F08080', '#E0FFFF', '#FAFAD2', '#D3D3D3', '#90EE90',
    '#FFB6C1', '#FFA07A', '#20B2AA', '#87CEFA', '#778899', '#B0C4DE',
    '#FFFFE0', '#00FF00', '#32CD32', '#FAF0E6', '#FF00FF', '#800000',
    '#66CDAA', '#0000CD', '#BA55D3', '#9370DB', '#3CB371', '#7B68EE',
    '#00FA9A', '#48D1CC', '#C71585', '#191970', '#F5FFFA', '#FFE4E1',
    '#FFE4B5', '#FFDEAD', '#000080', '#FDF5E6', '#808000', '#6B8E23',
    '#FFA500', '#FF4500', '#DA70D6', '#EEE8AA', '#98FB98', '#AFEEEE',
    '#DB7093', '#FFEFD5', '#FFDAB9', '#CD853F', '#FFC0CB', '#DDA0DD',
    '#B0E0E6', '#800080', '#663399', '#FF0000', '#BC8F8F', '#4169E1',
    '#8B4513', '#FA8072', '#FAA460', '#2E8B57', '#FFF5EE', '#A0522D',
    '#C0C0C0', '#87CEEB', '#6A5ACD', '#708090', '#FFFAFA', '#00FF7F',
    '#4682B4', '#D2B48C', '#008080', '#D8BFD8', '#FF6347', '#40E0D0',
    '#EE82EE', '#F5DEB3', '#FFFFFF', '#F5F5F5', '#FFFF00', '#9ACD32',
    '#00BFFF', '#1E90FF', '#6495ED', '#7FFFD4', '#D2691E', '#FF69B4',
    '#F0E68C', '#E6E6FA', '#FFF0F5', '#B22222', '#FFD700', '#DAA520',
    '#7FFF00', '#00FA9A', '#ADFF2F', '#4B0082', '#FF4500', '#FF8C00',
    '#FF6347', '#7CFC00', '#00FF7F', '#F5F5DC', '#FFE4C4', '#FFDEAD',
    '#FFD700', '#FAFAD2', '#FFEFD5', '#FFDAB9', '#FFE4B5', '#FF4500',
    '#FF8C00', '#FFA500', '#FF6347', '#FF4500', '#FF7F50', '#6495ED',
    '#DC143C', '#00008B', '#008B8B', '#B8860B', '#A9A9A9', '#006400',
    '#BDB76B', '#8B008B', '#556B2F', '#FF8C00', '#9932CC', '#8B0000',
    '#E9967A', '#8FBC8F', '#483D8B', '#2F4F4F', '#00CED1', '#9400D3',
    '#FF1493', '#00BFFF', '#696969', '#1E90FF', '#B22222', '#FFFAF0',
    '#228B22', '#FF00FF', '#DCDCDC', '#FFD700', '#DAA520', '#ADFF2F',
    '#F0FFF0', '#FF69B4', '#CD5C5C', '#4B0082', '#FFFFF0', '#F0E68C',
    '#E6E6FA', '#FFF0F5', '#7CFC00', '#FFFACD', '#ADD8E6', '#F08080',
    '#E0FFFF', '#FAFAD2', '#D3D3D3', '#90EE90', '#FFB6C1', '#FFA07A',
    '#20B2AA', '#87CEFA', '#778899', '#B0C4DE', '#FFFFE0', '#00FF00',
    '#32CD32', '#FAF0E6', '#FF00FF', '#800000', '#66CDAA', '#0000CD',
    '#BA55D3', '#9370DB', '#3CB371', '#7B68EE', '#00FA9A', '#48D1CC',
    '#C71585', '#191970', '#F5FFFA', '#FFE4E1', '#FFE4B5', '#FFDEAD',
    '#000080', '#FDF5E6', '#808000', '#6B8E23', '#FFA500', '#FF4500',
    '#DA70D6', '#EEE8AA', '#98FB98', '#AFEEEE', '#DB7093', '#FFEFD5',
    '#FFDAB9', '#CD853F', '#FFC0CB', '#DDA0DD', '#B0E0E6', '#800080',
    '#663399', '#FF0000', '#BC8F8F', '#4169E1', '#8B4513', '#FA8072',
    '#FAA460', '#2E8B57', '#FFF5EE', '#A0522D', '#C0C0C0', '#87CEEB',
    '#6A5ACD', '#708090', '#FFFAFA', '#00FF7F', '#4682B4', '#D2B48C',
    '#008080', '#D8BFD8', '#FF6347', '#40E0D0', '#EE82EE', '#F5DEB3',
    '#FFFFFF', '#F5F5F5', '#FFFF00', '#9ACD32', '#00BFFF', '#1E90FF',
    '#6495ED', '#7FFFD4', '#D2691E', '#FF69B4', '#F0E68C', '#E6E6FA',
    '#FFF0F5', '#B22222', '#FFD700', '#DAA520', '#7FFF00', '#00FA9A',
    '#ADFF2F', '#4B0082', '#FF4500', '#FF8C00', '#FF6347', '#7CFC00',
    '#00FF7F', '#F5F5DC', '#FFE4C4', '#FFDEAD', '#FFD700', '#FAFAD2',
    '#FFEFD5', '#FFDAB9', '#FFE4B5', '#FF4500', '#FF8C00', '#FFA500',
    '#FF6347', '#FF4500', '#FF7F50', '#6495ED', '#DC143C', '#00008B',
    '#008B8B', '#B8860B', '#A9A9A9', '#006400', '#BDB76B', '#8B008B',
    '#556B2F', '#FF8C00', '#9932CC', '#8B0000', '#E9967A', '#8FBC8F',
    '#483D8B', '#2F4F4F', '#00CED1', '#9400D3', '#FF1493', '#00BFFF',
    '#696969', '#1E90FF', '#B22222', '#FFFAF0', '#228B22', '#FF00FF',
    '#DCDCDC', '#FFD700', '#DAA520', '#ADFF2F', '#F0FFF0', '#FF69B4',
    '#CD5C5C', '#4B0082', '#FFFFF0', '#F0E68C', '#E6E6FA', '#FFF0F5',
    '#7CFC00', '#FFFACD', '#ADD8E6', '#F08080', '#E0FFFF', '#FAFAD2',
    '#D3D3D3', '#90EE90', '#FFB6C1', '#FFA07A', '#20B2AA', '#87CEFA',
    '#778899', '#B0C4DE', '#FFFFE0', '#00FF00', '#32CD32', '#FAF0E6',
    '#FF00FF', '#800000', '#66CDAA', '#0000CD', '#BA55D3', '#9370DB',
    '#3CB371', '#7B68EE', '#00FA9A', '#48D1CC', '#C71585', '#191970',
    '#F5FFFA', '#FFE4E1', '#FFE4B5', '#FFDEAD', '#000080', '#FDF5E6',
    '#808000', '#6B8E23', '#FFA500', '#FF4500', '#DA70D6', '#EEE8AA',
    '#98FB98', '#AFEEEE', '#DB7093', '#FFEFD5', '#FFDAB9', '#CD853F',
    '#FFC0CB', '#DDA0DD', '#B0E0E6', '#800080', '#663399', '#FF0000',
    '#BC8F8F', '#4169E1', '#8B4513', '#FA8072', '#FAA460', '#2E8B57',
    '#FFF5EE', '#A0522D', '#C0C0C0', '#87CEEB', '#6A5ACD', '#708090',
    '#FFFAFA', '#00FF7F', '#4682B4', '#D2B48C', '#008080', '#D8BFD8',
    '#FF6347', '#40E0D0', '#EE82EE', '#F5DEB3', '#FFFFFF', '#F5F5F5',
    '#FFFF00', '#9ACD32', '#00BFFF', '#1E90FF', '#6495ED', '#7FFFD4',
    '#D2691E', '#FF69B4', '#F0E68C', '#E6E6FA', '#FFF0F5', '#B22222',
    '#FFD700', '#DAA520', '#7FFF00', '#00FA9A', '#ADFF2F', '#4B0082',
    '#FF4500', '#FF8C00', '#FF6347', '#7CFC00', '#00FF7F', '#F5F5DC',
    '#FFE4C4', '#FFDEAD', '#FFD700', '#FAFAD2', '#FFEFD5', '#FFDAB9',
    '#FFE4B5', '#FF4500', '#FF8C00', '#FFA500', '#FF6347', '#FF4500',
    '#FF7F50', '#6495ED', '#DC143C', '#00008B', '#008B8B', '#B8860B',
    '#A9A9A9', '#006400', '#BDB76B', '#8B008B', '#556B2F', '#FF8C00',
    '#9932CC', '#8B0000', '#E9967A', '#8FBC8F', '#483D8B', '#2F4F4F',
    '#00CED1', '#9400D3', '#FF1493', '#00BFFF', '#696969', '#1E90FF',
    '#B22222', '#FFFAF0', '#228B22', '#FF00FF', '#DCDCDC', '#FFD700',
    '#DAA520', '#ADFF2F', '#F0FFF0', '#FF69B4', '#CD5C5C', '#4B0082',
    '#FFFFF0', '#F0E68C', '#E6E6FA', '#FFF0F5', '#7CFC00', '#FFFACD',
    '#ADD8E6', '#F08080', '#E0FFFF', '#FAFAD2', '#D3D3D3', '#90EE90',
    '#FFB6C1', '#FFA07A', '#20B2AA', '#87CEFA', '#778899', '#B0C4DE',
    '#FFFFE0', '#00FF00', '#32CD32', '#FAF0E6', '#FF00FF', '#800000',
    '#66CDAA', '#0000CD', '#BA55D3', '#9370DB', '#3CB371', '#7B68EE',
    '#00FA9A'
]


export const uniqueColors = [...new Set(customColors)]