import React, { useContext, useEffect, useState } from 'react';
import { Search } from 'lucide-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { AppContext } from '../../../../AppContexts/AppContext';
import { actionTypes } from '../../../../AppContexts/constants';
import { useGetActiveLocationsQuery } from '../../../../Actions/BulkUpload/BulkUpload';
import { logout } from '../../../../Actions/Auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DisplayContext } from '../../../../AppContexts/DisplayContext';
import { useGetServiceCenterQuery } from '../../../../Actions/Account/Account';

const LocationSearchInput = () => {
  const [pickupId, setPickupId] = useState(null);
  const [isSelectAddress, setIsSelectedAddress] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state, uploadDispatch, setPickupAddressError} = useContext(AppContext);
  const { local_station_error, local_station,local_station_loading,search, setSearch, locationValues, setLocationValues, editIndex } = useContext(DisplayContext);
  const { isError, error, isLoading, data, isFetching } = useGetActiveLocationsQuery();
  const {prefilledData} = state

  const getGIGActiveLocations = data && data.results && data.results.Object;
  const LGAName = [];
  getGIGActiveLocations && getGIGActiveLocations.map(locality => LGAName.push(locality.LGAName));

  const getStationData = local_station && local_station.results && local_station.results.Object;
  const stateArea = state.state && state.state?.toLowerCase()?.includes("Federal Capital Territory".toLowerCase()) ? "Abuja" : state.state;

  const stationStateName = getStationData && getStationData.find(station => 
    (station?.StationName?.toLowerCase() === state.locality?.toLowerCase()) || 
    (station?.StationName?.toLowerCase() === stateArea?.toLowerCase())
  );
  const isPickupAddress = LGAName?.find(lga => lga?.toLowerCase() === state.lga?.toLowerCase());
  let merchantStationId = stationStateName && stationStateName?.StationId;


    const getId = pickupId && JSON.parse(pickupId)
    const id = getId && getId.StationId && Number(getId.StationId); 
    const {data: serviceCenterData, isFetching: isFetchingServiceCenter, isLoading: isLoadingServiceCenter} = useGetServiceCenterQuery({id})

    const serviceCenter = serviceCenterData && serviceCenterData?.results && serviceCenterData?.results?.Object;

    const filteredCenters = serviceCenter && serviceCenter.filter((center) =>
      center.Name?.toLowerCase().includes(search?.toLowerCase()) || center.Address?.toLowerCase().includes(search?.toLowerCase())
    );
  const dataToDisplay = search ? filteredCenters :  serviceCenter;
const merchantPickupStationId = locationValues && locationValues.StationId;
const merchantPickupLatitude = locationValues && locationValues.Latitude;
const merchantPickupLongitude = locationValues && locationValues.Longitude;
const serviceCenterLength = serviceCenter && serviceCenter.length;

  const handleStationId = (e) => {
    uploadDispatch({type: actionTypes.SET_MERCHANT_STATION_ID, payload: e.target.value});
    setPickupAddressError("")
  }

  const handleCordinateSet = (lat, long) => {
     uploadDispatch({type: actionTypes.SET_LAT, payload: lat})
     uploadDispatch({type: actionTypes.SET_LONG, payload: long})
  }

  const handleChange = (address) => {
    uploadDispatch({ type: actionTypes.SET_ADDRESS, payload: address });
    setIsSelectedAddress(false);
    setLocationValues([]);
    setPickupAddressError("")
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const formattedAddress = results[0].formatted_address;
      uploadDispatch({ type: actionTypes.SET_ADDRESS, payload: formattedAddress });

      if (results.length === 0) {
        uploadDispatch({ type: actionTypes.SET_LOCATION_ERROR, payload: '' });
        return;
      }

      const latLng = await getLatLng(results[0]);
      uploadDispatch({ type: actionTypes.SET_COORDINATES, payload: latLng });

      const addressComponents = results[0].address_components;
      const stateComponent = addressComponents.find(component => 
        component.types.includes('administrative_area_level_1')
      );
      const localityComponent = addressComponents.find(component => 
        component.types.includes('locality')
      );
      const areaLevel2Component = addressComponents.find(component => 
        component.types.includes('administrative_area_level_2')
      );
      const neighborhoodComponent = addressComponents.find(component => 
        component.types.includes('neighborhood')
      );

      uploadDispatch({
        type: actionTypes.SET_STATE,
        payload: stateComponent ? stateComponent.long_name : ''
      });
      uploadDispatch({
        type: actionTypes.SET_LOCALITY,
        payload: localityComponent ? localityComponent.long_name : ''
      });
      uploadDispatch({
        type: actionTypes.SET_LGA,
        payload: areaLevel2Component ? areaLevel2Component.long_name : ''
      });
      uploadDispatch({
        type: actionTypes.SET_NEIGHBORHOOD,
        payload: neighborhoodComponent ? neighborhoodComponent.long_name : ''
      });
      setIsSelectedAddress(true)
      uploadDispatch({ type: actionTypes.SET_LOCATION_ERROR, payload: '' });
    } catch (error) {
      uploadDispatch({
        type: actionTypes.SET_LOCATION_ERROR,
        payload: 'Error fetching coordinates. Please try again.'
      });
    }
  };

  
  useEffect(() => {
    const updateLocationDetails = async (lat, lng) => {
      try {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCl2wtzcjTd1ekKgpNNgQRNuqRjtM8qRic`);
        const data = await response.json();
        if (data.status === 'OK') {
          const addressComponents = data.results[0].address_components;
          const formattedAddress = data.results[0].formatted_address;

          const stateComponent = addressComponents.find(component =>
            component.types.includes('administrative_area_level_1')
          );
          const localityComponent = addressComponents.find(component =>
            component.types.includes('locality')
          );
          const areaLevel2Component = addressComponents.find(component =>
            component.types.includes('administrative_area_level_2')
          );
          const neighborhoodComponent = addressComponents.find(component =>
            component.types.includes('neighborhood')
          );

          uploadDispatch({
            type: actionTypes.SET_STATE,
            payload: stateComponent ? stateComponent.long_name : ''
          });
          uploadDispatch({
            type: actionTypes.SET_LOCALITY,
            payload: localityComponent ? localityComponent.long_name : ''
          });
          uploadDispatch({
            type: actionTypes.SET_LGA,
            payload: areaLevel2Component ? areaLevel2Component.long_name : ''
          });
          uploadDispatch({
            type: actionTypes.SET_NEIGHBORHOOD,
            payload: neighborhoodComponent ? neighborhoodComponent.long_name : ''
          });
          uploadDispatch({
            type: actionTypes.SET_ADDRESS,
            payload: formattedAddress
          });
        } else {
          uploadDispatch({
            type: actionTypes.SET_LOCATION_ERROR,
            payload: 'Error fetching address details. Please try again.'
          });
        }
      } catch (error) {
        uploadDispatch({
          type: actionTypes.SET_LOCATION_ERROR,
          payload: 'Error fetching address details. Please try again.'
        });
      }
    };

    if (merchantPickupLatitude && merchantPickupLongitude) {
      updateLocationDetails(merchantPickupLatitude, merchantPickupLongitude);
    }
  }, [merchantPickupLatitude, merchantPickupLongitude, uploadDispatch]);

  useEffect(() => {
    if(merchantPickupStationId){
        uploadDispatch({type: actionTypes.SET_MERCHANT_STATION_ID, payload: merchantPickupStationId});
       
    }
 }, [merchantPickupStationId, uploadDispatch]);

 useEffect(() => {
      if(merchantStationId){
         uploadDispatch({type: actionTypes.SET_MERCHANT_STATION_ID, payload: merchantStationId});
      }
 }, [merchantStationId, uploadDispatch]);
 useEffect(() => {
    if(editIndex !== null){
      uploadDispatch({type: actionTypes.SET_LAT, payload: prefilledData?.lat})
      uploadDispatch({type: actionTypes.SET_LONG, payload: prefilledData?.long});
      uploadDispatch({type: actionTypes.SET_MERCHANT_STATION_ID, payload: prefilledData?.merchant_station_id});
      uploadDispatch({
        type: actionTypes.SET_STATE,
        payload: prefilledData?.state || ""
      });
      uploadDispatch({
        type: actionTypes.SET_LOCALITY,
        payload: prefilledData?.locality
      });
      uploadDispatch({
        type: actionTypes.SET_LGA,
        payload: prefilledData?.lga || ''
      });
      uploadDispatch({
        type: actionTypes.SET_NEIGHBORHOOD,
        payload: prefilledData?.neighborhood
      });
      uploadDispatch({
        type: actionTypes.SET_ADDRESS,
        payload: prefilledData?.address
      });
    }
 }, [editIndex, prefilledData.lat, prefilledData.long, uploadDispatch, prefilledData.merchant_station_id, prefilledData.state, prefilledData.address, prefilledData.neighborhood, prefilledData.lga, prefilledData.locality])

 useEffect(() => {
    if (isError) {
      const errorMessage = error?.data?.message || error?.error || '';
      // const errorCode = error?.data?.code || '';

      if (
        (errorMessage === 'Invalid token') ||
        (errorMessage === 'Access denied. No token provided')
      ) {
        navigate('/login');
        dispatch(logout());
      }
    }
  }, [error, isError, navigate, dispatch]);

  return (
    <>
      {isLoading || isFetching ? (
        <div className="spinner-border shipment-spinner spinner-size" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : local_station_error ? (
        <div className="container alert alert-danger" role="alert">
          {local_station_error}
        </div>
      ) : (
        <div>
          <PlacesAutocomplete
            value={state.address  || ''}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <div className='d-flex flex-column gap-1 w-100 position-relative'>
                  <label>Enter Pickup Location</label>
                  <input
                    {...getInputProps({
                      placeholder: '102 Agbara Road, Badagry 110293',
                      className: 'w-100 px-2',
                    })}
                  />
                  <Search size={24} className='search__icon' />
                </div>
                {(loading || (Array.isArray(suggestions) && suggestions.length > 0)) && (
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {Array.isArray(suggestions) && suggestions.map((suggestion) => {
                       const { placeId, description, active } = suggestion;
                       const { key, ...suggestionProps } = getSuggestionItemProps(suggestion);
                       const style = {
                         backgroundColor: active ? "#08ffc8" : "#fff",
                         cursor: "pointer",
                         fontSize: "14px",
                         margin: "2px 0px"
                       };

                      return (
                        <div key={placeId} {...suggestionProps} style={style}>
                          {description}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>
          {isError && (
            <p className='text-danger text-xs'>
                {error?.data?.message || error?.error || 'An unknown error occurred'}
            </p>
            )}
          {
              isPickupAddress && !merchantStationId && state.address && (
                <div className="mt-2 delivery-details-input">
                    <label>Service Station</label>
                    <select onChange={handleStationId} className="select-state">
                        <option className="disabled-option" value="">Choose delivery state</option>
                        {
                            getStationData && getStationData.map((station) => (
                                <option key={station.StationId} value={station.StationId}>
                                    {station.StateName}
                                </option>
                            ))
                        }
                    </select>
                </div>
            ) 
          }
          {
               !isPickupAddress && state.address && isSelectAddress && <div style={{margin: "0.5rem 0", textAlign: "center", fontSize: "12px"}}>
                  <small className='text-info'>This address is not within GIGL pickup locations, please use alternative address or use the GIGL Pickup location closest to you</small>
               </div>
          }

          {
              !isPickupAddress && state.address && isSelectAddress && (<>
                    
              {local_station_loading ? 
                  <div style={{ zIndex: "9999999999999", height: "100%"}}  className="d-flex justify-content-center align-items-center">
                      <div  className="spinner-border shipment-spinner spinner-size"
                      role="status"
                      >
                      </div>
                  
                  </div>:
                  <>
                      <div className="card-body" style={{border: "1px solid #F5F5F5", borderRadius: "8px", padding: "0.5rem"}}>
                      <span style={{fontSize: "12px", fontWeight: 500, color: "#333333"}}>Select pickup location</span>
                      <select className="select-elem form-select" onChange={(e) => {setPickupId(e.target.value);}}>
                              <option>Select a GIG Service Center</option>
                              {getStationData && getStationData.map((pickup) => {
                                  return <option key={pickup.StationId} value={JSON.stringify(pickup)}>{pickup.StationName}</option>
                              })}     
                          </select>
                      
                          
                          {serviceCenter === undefined || serviceCenter?.length === 0 ? "" :  <div className="search-location-input mt-2">
                          
                          <input type="text" placeholder="Search for Service centers..." onChange={(e) => setSearch(e.target.value)} value={search} className="service-center-search"/>
                          </div>}
                          
                          {!isLoadingServiceCenter && isFetchingServiceCenter && serviceCenterLength === 0 ? <div className="text-center mt-2">Fetching service center...</div>: ''}
                          {isLoadingServiceCenter || isFetchingServiceCenter ? 
                              <div className="d-flex justify-content-center align-items-center" style={{width: "100%", height: "70%"}}>
                                  <div className="spinner-border shipment-spinner spinner-size" role="status" >
                                  </div> 
                              </div>
                              : 
                              <>
                                  {serviceCenterLength === 0 ? <p className="text-center text-danger mt-2">Please we do not have a pickup station here, select another service center</p> : dataToDisplay && dataToDisplay.map((center) => (
                                      <div  key={center.ServiceCentreId} className="mt-0">
                                          <label className="media-elem-pickup" htmlFor="pickup_stations">    
                                              <div className="delivery-details-pickup">
                                                      <div className="delivery-title">{center.Name}</div>
                                                      <div className="delivery-note service-station-address">{center.Address}</div>
                                                      
                                              </div>
                                              <input type="radio" name="pickup-address" className="address-checkbox-pickup" onClick={() => {handleCordinateSet(center.Latitude, center.Longitude);}} onChange={() => setLocationValues(center)} id="pickup_stations" value="pickup-station-value"/>    
                                      
                                          </label>
                                      <hr />
                                      </div>
                                  ))}
                              </>
                          
                          }
              
                  
          </div>
                  </>
              }
              </>)
          }
        </div>
      )}
    </>
  );
};

export default LocationSearchInput;
