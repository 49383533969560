
const config = {
    devOrderUrl: process.env.REACT_APP_ORDER_BASE_URL,
    devProductUrl: process.env.REACT_APP_PRODUCT_BASE_URL,
    devUserUrl: process.env.REACT_APP_USER_BASE_URL,
    devNotificationUrl: process.env.REACT_APP_NOTIFICATION_BASE_URL,
    devSubscriptionUrl: process.env.REACT_APP_SUBSCRIPTION_BASE_URL,
    flutterPubKey: process.env.REACT_APP_FLUTTERWAVE_PUBKEY,
    paystackPubKey: process.env.REACT_APP_PAYSTACK_PUBKEY,
    nodeEnv: process.env.REACT_APP_ENV,
    key: process.env.REACT_APP_SKEY,
    prodOrderUrl: process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION,
    prodProductUrl: process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION,
    prodUserUrl: process.env.REACT_APP_USER_BASE_URL_PRODUCTION,
    prodNotificationUrl: process.env.REACT_APP_NOTIFICATION_BASE_URL_PRODUCTION,
    prodSubscriptionUrl: process.env.REACT_APP_SUBSCRIPTION_BASE_URL_PRODUCTION,
    paystackSecretKey: process.env.REACT_APP_PAYSTACK_PUBKEY_LIVE
}

const generateSecretKey = () => {
    const keyLength = 32;
    const buffer = new Uint8Array(keyLength);
    crypto.getRandomValues(buffer);
    return Array.from(buffer, (byte) =>
        byte.toString(16).padStart(2, '0')
    ).join('');
};


export {config, generateSecretKey};