import React, {useCallback, useContext, useEffect, useState} from 'react'
import Switch from "react-switch";
import { AppContext } from '../../../../AppContexts/AppContext';
import { vehicleType } from '../../../../helpers/vehicleTypeNumber';
import { actionTypes } from '../../../../AppContexts/constants';
import LocationSearchInput from './Location';
import { useListPromtionsQuery } from '../../../../Actions/BulkUpload/BulkUpload';
import numbro from 'numbro';


const UploadForm = () => {
    const {data} = useListPromtionsQuery();
    const [value, setValue] = useState('');
    const [weightError, setWeightError] = useState("")
    const {state, uploadDispatch, setDiscountError, discountValue, setDiscountValue,priceValue, discountChecked, setDiscountCheck, editIndex, setPriceValue} = useContext(AppContext);
    
    const {prefilledData} = state
    const type =  state?.get_category?.type;
    const promoTypes = data && data.results;

    const handleInputChange = (e) => {
        const { name, value: inputValue } = e.target;
        if (name === 'weight') {
          // Remove unwanted characters and parse the number
          const sanitizedValue = inputValue.replace(/[^0-9.]/g, '');
          const numberValue = parseFloat(sanitizedValue);
    
          // Determine the vehicle type based on the weight
          let vehicleType = '';
          if (isNaN(numberValue) || numberValue < 0.5) {
            vehicleType = '';
            setWeightError("Weight must be at least 0.5 kg.")
          } else if (numberValue >= 0.5 && numberValue <= 24.5) {
            vehicleType = 'BIKE';
            setWeightError("")
          } else if (numberValue >= 25.0 && numberValue <= 99.5) {
            vehicleType = 'CAR';
          } else if (numberValue >= 100 && numberValue <= 499.5) {
            vehicleType = 'VAN';
          } else if (numberValue >= 500) {
            vehicleType = 'TRUCK';
          }
          
          setValue(sanitizedValue);
         uploadDispatch({
            type: actionTypes.CHANGE_INPUT,
            payload: {name, value: sanitizedValue },
          });
    
           uploadDispatch({
            type: actionTypes.SET_VEHICLE_TYPE,
            payload: vehicleType,
          });
        }
      };
      
    
      const handleChange = (e) => {
        const { type, value, name } = e.target;
        let newValue = value;
    
        if (type === 'number') {
            newValue = parseFloat(value) || 0;
        }
        uploadDispatch({ type: actionTypes.CHANGE_INPUT, payload: { name, value: newValue } });
    };
    

    const handleDiscountChange = (e) => {
        let inputValue = e.target.value;
      
        // Allow empty input to be cleared
        if (inputValue === "") {
          setDiscountValue("");
          uploadDispatch({
            type: actionTypes.CHANGE_INPUT,
            payload: {
              name: e.target.name,
              value: 0,
            },
          });
          return;
        }
      
        // Remove any characters that are not digits or decimal point
        const regex = /[^0-9.]/g;
        inputValue = inputValue.replace(regex, '');
      
        // Replace leading zero with typed number
        if (inputValue.length === 2 && inputValue[0] === '0' && inputValue[1] !== '.') {
          inputValue = inputValue[1];
        }
      
        // Ensure only one decimal point
        if (inputValue.includes('.')) {
          const parts = inputValue.split('.');
          if (parts[1].length > 2) {
            inputValue = parts[0] + '.' + parts[1].slice(0, 2);
          }
        }
      
        // Format the value with thousand separators
        const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      
        // Update the state
        setDiscountValue(formattedValue);
      
        // Convert to number for dispatch
        const numberValue = parseFloat(inputValue) || 0;
      
        // Dispatch the numerical value or an empty string
        uploadDispatch({
          type: actionTypes.CHANGE_INPUT,
          payload: {
            name: e.target.name,
            value: numberValue,
          },
        });
      
        // Ensure the cursor is always at the end
        setTimeout(() => {
          const inputElement = e.target;
          inputElement.focus();
          inputElement.selectionStart = inputElement.selectionEnd = inputElement.value.length;
        }, 0);
      };
      
      

    const handleSubCategorySize = (event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const sizesData = selectedOption.getAttribute('data-sizes');    
         let sizes = [];

        if (sizesData) {
            try {
                sizes = JSON.parse(sizesData);
                if (sizes.length === 1 && sizes[0] === '') {
                sizes = [];
                }
            } catch (error) {
                console.error("Error parsing sizes:", sizesData);
            }
        }
          uploadDispatch({type: actionTypes.SUB_CATEGORY_SIZES, payload: sizes})
        }

        const handleCheck = () => {
            setDiscountCheck(current => !current)
        }
        const checkValidDiscount = useCallback(() => {
            // Remove commas or other non-numeric characters from the input strings

            const discountStr = discountValue.toString();
            const discountNum = parseFloat(discountStr.replace(/,/g, ''));
            const priceStr = priceValue.toString(); // Convert to string before using replace
            const priceNum = parseFloat(priceStr.replace(/,/g, ''));
        
            // Perform the comparison only if both values are valid numbers
            if (discountValue !== "" && priceValue !== "" && discountNum >= priceNum) {
                setDiscountError("Discounted price must be less than actual price");
            } else {
                setDiscountError("");
            }
        }, [discountValue,setDiscountError,priceValue]);
        
        
        useEffect(() => {
            checkValidDiscount();
        }, [checkValidDiscount]);
   
    useEffect(() => {
        if(editIndex !== null){
            setValue(prefilledData.weight)
        }
    }, [editIndex, prefilledData.weight]);
    useEffect(() => {
       if(editIndex !== null){
         uploadDispatch({type: actionTypes.SUB_CATEGORY_SIZES, payload: prefilledData?.sub_category_sizes})  
       }
    }, [editIndex, uploadDispatch, prefilledData?.sub_category_sizes]);

    useEffect(() => {
        if(editIndex !== null){
            uploadDispatch({ type: actionTypes.CHANGE_INPUT, payload: { name : "sub_category", value: prefilledData?.sub_category } });
            uploadDispatch({ type: actionTypes.CHANGE_INPUT, payload: { name: "description", value: prefilledData?.description } });
            uploadDispatch({ type: actionTypes.CHANGE_INPUT, payload: { name: "promo_name", value: prefilledData?.promo_name } });
            uploadDispatch({ type: actionTypes.CHANGE_INPUT, payload: { name: "weight", value: prefilledData?.weight} });
            uploadDispatch({ type: actionTypes.CHANGE_INPUT, payload: { name: "tag", value: prefilledData?.tag} });
            uploadDispatch({
            type: actionTypes.SET_VEHICLE_TYPE,
            payload: prefilledData?.vehicleType,
            });
           
        }
    }, [editIndex, prefilledData.sub_category,prefilledData.description, uploadDispatch, prefilledData.vehicleType, prefilledData.weight, prefilledData.tag, prefilledData.promo_name])
    

    useEffect(() => {
        if(state.discounted_price === 0){
            setDiscountValue("")
        }
    }, [state.discounted_price, setDiscountValue]);

    useEffect(() => {
        if(editIndex !== null && prefilledData.discounted_price > 0){
             setDiscountCheck(true);
             uploadDispatch({
                type: actionTypes.CHANGE_INPUT,
                payload: { name: "discounted_price", value: prefilledData?.discounted_price },
              });
              const discountedPrice = numbro(prefilledData?.discounted_price).format({thousandSeparated: true});
              setDiscountValue(discountedPrice)
        }
    }, [prefilledData.discounted_price, setDiscountCheck, editIndex, setDiscountValue, uploadDispatch])

    useEffect(() => {
        if(editIndex !== null && prefilledData.price > 0){
             uploadDispatch({
                type: actionTypes.CHANGE_INPUT,
                payload: { name: "price", value: prefilledData?.price },
              });
              const price = numbro(prefilledData?.price).format({thousandSeparated: true});
              
              setPriceValue(price)
        }
    }, [prefilledData.price, editIndex, setPriceValue, uploadDispatch])


        
  return (
    <section className='d-flex flex-column gap-2 upload-part-form'>
        <div className='d-flex flex-column gap-1 w-100'>
            <label>Product type</label>
           <select value={state.sub_category} name='sub_category' className='w-100' onChange={(e) => {handleChange(e); handleSubCategorySize(e)}}>
              <option value={""}>choose product type</option>
                {
                   type && type.map(({_id, name, sizes}) => (
                        <option value={name} key={_id} data-sizes={JSON.stringify(sizes)}>{name}</option>
                   )) 
                }
           </select>
        </div>
        <div className='d-flex flex-column gap-1 w-100'>
            <label>Description</label>
             <textarea placeholder='Enter product description' name="description" value={state.description} onChange={handleChange}/>
        </div>
        <div className='d-flex flex-column gap-1 w-100 position-relative'>
            <label>Product tag</label>
            <input type='text' value={state.tag} placeholder='E.g. New or Used'  className='w-100 px-2' name='tag' onChange={handleChange}/>
        </div>
        <div className='custom-divider'/>
        <LocationSearchInput />
        <div className='d-flex flex-column gap-1 w-100 position-relative'>
            <label>Product Weight (kg)</label>
            <input type='text' placeholder='Enter weight of the product' className='w-100 px-2' name="weight" onChange={handleInputChange} value={value}/>
        </div>
        {weightError && (
            <div style={{ color: 'red', fontSize: "12px" }}>
            {weightError}
            </div>
         )}
        <div className='d-flex flex-column gap-1 w-100'>
            <label>Choose required pickup vehicle type</label>
           <select className='w-100' disabled name='vehicleType' value={state.vehicleType}>
               {
                   vehicleType.map((vehicle) => (
                       <option value={vehicle} key={vehicle}>
                             {vehicle}
                       </option>
                   ))
               }
           </select>
        </div>
        <div className='discount-apply'>
            <div>
                <h3>Apply Discount</h3>
                <p>Increase your sales for this product by creating an attractive discount.</p>
            </div>
            <Switch 
                onChange={handleCheck}
                checked={discountChecked} 
                offColor="#d3d3d3" 
                onColor="#192150"
                uncheckedIcon={false}
                checkedIcon={false}
                height={21}
                width={38}
            />
        </div>
        {
            discountChecked && <div className='w-100' style={{marginTop: "-1rem"}}>
                <div className='w-100 d-flex flex-column'>
                  <label>Select discount type</label>
                  <select onChange={handleChange} name="promo_name" value={state.promo_name}>
                    <option value={""}>select discount type</option>
                    {
                       promoTypes && promoTypes.map(({name, _id}) => (
                            <option key={_id} value={name}>{name}</option>
                        ))
                    }
                   </select>
                </div>
                {
                    state.promo_name && (
                      <div className='position-relative mt-1'>
                            <input 
                                type='text'
                                value={discountValue} 
                                placeholder='Enter discount amount' 
                                className='w-100  px-2 price-in-naira' 
                                name="discounted_price" 
                                onChange={handleDiscountChange}
                                style={{paddingRight: "0px !important"}}
                            />
                            <span className='naira-sign' style={{marginTop: "0.10rem"}}>₦</span>
                        </div>
                    )
                }
               
        </div>
        }
    </section>
  )
}

export default UploadForm