import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";

export const priceByQtyApi = createApi({
        reducerPath: "priceByQtyApi",
        baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION,process.env.REACT_APP_PRODUCT_BASE_URL)}`}),
        endpoints: (builder) => ({
            priceByQty: builder.query({
                query: ({id, qty}) => ({
                    url: `/product/price_by_quantity?productId=${id}&quantity=${qty}`,
                    method: "get"
                })
            })
        })
});
export const shipmentPriceApi = createApi({
    reducerPath: "shipmentPriceApi",
    baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION,process.env.REACT_APP_ORDER_BASE_URL)}`}),
    endpoints: (builder) => ({
        getDeliveryPrice: builder.mutation({
            query: (body) => ({
                url: `/order/guest/shipping_price`,
                method: "post", 
                body
            })
        })
    })
});
export const {usePriceByQtyQuery} = priceByQtyApi;
export const {useGetDeliveryPriceMutation} = shipmentPriceApi