import React, {useState, useCallback, useContext} from 'react';
import "./search.css";
import { useSearchAlphaQuery } from '../../../../Actions/Search/SearchAlpha';
import Loader from '../../../../utils/Loader';
import Card from '../../ProductCard/Card';
import { MdOutlineRotateRight } from 'react-icons/md';
import { useLocation } from "react-router-dom";
import { AppContext } from '../../../../AppContexts/AppContext';

const SearchCatalog = () => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const {removeSlug} = useContext(AppContext);
  const search = useLocation().search;
  const query = removeSlug(new URLSearchParams(search).get("q"));
  const {isLoading, isFetching, data, error, isError} = useSearchAlphaQuery({searchTerm: query, page: 1, limit: itemsPerPage});
  const searchData = data?.results?.docs;
  const hasNextPage = data?.results?.hasNextPage;
  const nextPage = data?.results?.nextPage;
  const totalItems = data?.results?.totalDocs;
  const currentData = searchData && searchData.slice(0,itemsPerPage);
  const errorMessage = isError && error && error.hasOwnProperty("data") ? JSON.stringify(error.data) : JSON.stringify(error?.error);
   const loadMore = useCallback(() => {
    if((!hasNextPage && nextPage === null)){
      return;
    }else {
          setItemsPerPage(prev => prev + 10)
    }
}, [hasNextPage, nextPage,setItemsPerPage]);

  return (
    <section className='search-catalog pt-4'>
         <div className='catalog-section rounded shadow'>
            
            <div className='px-4 pt-2 mt-2 catalog__items-count'>
                {searchData?.length >= 1 ? totalItems : 0} {searchData?.length <= 1 || searchData === undefined ? "product" : "products"} found
            </div>
            <hr />
            <div className='py-2 store_products category__product-cards'>
               {
                  isLoading && <Loader />
                }
                  {
                   currentData && currentData.length > 0 && !isLoading && <div className="row mt-4">
                   
                    {currentData && currentData?.map((product) => (
                        
                        <Card product={product} key={`${product._id}_search`} />
                    ))}
                   
                 </div>
           
                }
            </div>
            {
             isError && <p className='text-center text-sm login__error-msg'><strong>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</strong></p>}
            {
                    (!hasNextPage && nextPage === null) || currentData?.length === 0 || currentData === undefined  ? null : <button className='store__show-more' onClick={loadMore}><span className={`${isFetching ? "showmore-rotate" : ""}`}><MdOutlineRotateRight size={18} /></span>{isFetching ? "Loading..." : "Show More"}</button>
              }
         </div>
    </section>
  )
}

export default SearchCatalog