import React from 'react';
import { categoryImages } from '../../../helpers/images';

const DownloadSection = () => {
  return (
    <div className="download-section alpha-footer">
             <div className="download-content">
                  <div className='app-img'>
                      <img src={categoryImages.footerImageDesktop} alt="download"/>
                  </div>
                  <div className='alpha__download-sec'>
                       <h3>Do social commerce the better way</h3>
                       <p>
                           Enjoy seamless social commerce transactions with Alpha. Download our app to get started.
                       </p>
                       <div className="alpha-app-download-icons">
                          <a href="https://apps.apple.com/us/app/alphashop/id1615734219" target="_blank" rel="noreferrer">
                              <img src="/AppStore-eng.svg" alt="alpha-download-icons"/>
                          </a>
                          <a href="https://play.google.com/store/apps/details?id=com.thealphashops" target="_blank" rel="noreferrer">
                              <img src="/GooglePlay-eng.svg" alt="alpha-download-icons"/>
                          </a>
                      </div>
                  </div>
                 {/* <div className="alpha-app-img">
                      <img src="/app-one.svg" alt="alpha-app" />
                      <img src="/app-two.svg" alt="alpha-app" />
                 </div> */}
                 {/* <div className="alpha-app-download">
                      <h3>Download Alpha APP</h3>
                      <div className="alpha-app-download-icons">
                        <a href="https://apps.apple.com/us/app/alphashop/id1615734219" target="_blank" rel="noreferrer">
                             <img src="/AppStore-eng.svg" alt="alpha-download-icons"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.thealphashops" target="_blank" rel="noreferrer">
                            <img src="/GooglePlay-eng.svg" alt="alpha-download-icons"/>
                        </a>
                      </div>

                 </div> */}
                 {/* <div className="women-downloading">
                     <img src="/women-downloading.svg" alt="people" />
                 </div>   */}
             </div>
    </div>
  )
}

export default DownloadSection