import React from 'react';
import {createRoot} from 'react-dom/client';
import { Provider } from "react-redux";
import { ToastContainer} from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import Routing from './Routes/Routing';
import store from './store/store';
import AppContextProvider from './AppContexts/AppContext';
import DisplayContextProvider from './AppContexts/DisplayContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/video-react/dist/video-react.css";
import "swiper/swiper.min.css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "react-tooltip/dist/react-tooltip.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import './index.css';
import { ErrorBoundary } from './ErrorBoundary';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
   <Provider store={store}>
      <AppContextProvider>
      <DisplayContextProvider>
          <ErrorBoundary>
              <Routing/>
          </ErrorBoundary>
        
        <ToastContainer />
      </DisplayContextProvider>
      </AppContextProvider>
    </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();