import React from 'react'
import UploadImageForm from './UploadImageForm'
import DescriptionForm from './DescriptionForm'
import UploadForm from './UploadForm'
import ImagesVideosUpload from './ImagesVideosUpload'
import UploadDescriptionForm from './UploadDescriptionForm'

const WebImageUploadSection = () => {

  return (
    <div className='web-upload-section d-none d-lg-flex'>
         <div className="web-upload-media d-flex flex-column gap-2 "  style={{ minHeight: "100%", height: "auto" }}>
                
               <UploadImageForm />
               <ImagesVideosUpload />
         </div>
         <div className='d-flex flex-column  web-product-description gap-2'>
                <UploadDescriptionForm />
                <div className="d-flex justify-content-between">
                    <UploadForm />
                    <DescriptionForm />
                </div>
         </div>
    
    </div>
  )
}

export default WebImageUploadSection