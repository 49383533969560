import React, { useContext } from 'react';
import { AppContext } from '../../../AppContexts/AppContext';
import placeholder from "../../../assets/placeholder-images/default-placeholder.png"
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DisplayContext } from '../../../AppContexts/DisplayContext';
import "./card.css"

const Card = ({product, handleValidateError}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {numberSeperator} = useContext(AppContext);
    const {setIsSelectColor,setIsSelectSize, setQty,setColor, setSize,setIsAlphaStore} = useContext(DisplayContext); 
    const isPromoting = product?.is_promoting;
    const isDiscountedPrice = product?.discounted_price;
    const isPath = location.pathname.includes("/product/guest");
    const clearError = () => {
        if(isPath){
          handleValidateError()
        }else {
            return
        }
    }
  
  return (
    <div className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3 col col-height product__store-cards"> 
                                        
        <Link to={`/product/guest/${product._id}`} className="store_link_alpha" onClick={() => { setIsAlphaStore(""); setIsSelectColor(false); setIsSelectSize(false); setQty(1); setSize(""); setColor(""); clearError()}} >      
            <div className="product_content">
                    <div className="shop_img">
                        <img loading="lazy" src={product.front_view || placeholder} className="product_shop_img" alt="product img"/>
                    </div> 
                    <p title={product.productName}>{product.productName}</p>
                    {
                       isPromoting ? 
                        <div className='price-area mt-2'>
                            <span>&#x20A6; {numberSeperator(isDiscountedPrice)}</span>
                            <span className={isPromoting ? "promoting-items" : ""}>&#x20A6; {numberSeperator(product.price)}</span>
                       </div> :
                         <span className='mt-2 d-flex'>&#x20A6; {numberSeperator(product.price)}</span>
                    }
            
            </div>
            
        </Link>
        <button className='d-block add__to-cartBtn' onClick={() => {navigate(`/product/guest/${product._id}`);  setIsAlphaStore("");}}>View details</button>
    </div>
  )
}

export default Card