export const sliderSettings = {
    slidesPerView: 1,
    spaceBetween: 50,
    breakpoints: {
        480: {
            slidesPerView: 1
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
        }
   
    }
}


export const categorySliderSettings = {
    slidesPerView: 1,
    breakpoints: {
        280: {
            slidesPerView: 1
        },
        360: {
            slidesPerView: 1.5
        },
        768: {
            slidesPerView: 2.1,
        },
        820: {
            slidesPerView: 2.32,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 0
  
        },
        1280: {
            slidesPerView: 4.2,
            spaceBetween: 0
  
        },
        
     
     
    }
}

export const trackerSliderSettings = {
    slidesPerView: 1,
    spaceBetween: 50
 }