import {useState, useContext, useEffect} from "react"
import {FaTimes, FaBuilding, FaArrowLeft} from "react-icons/fa";
import { RiEBike2Fill } from 'react-icons/ri';
import { AppContext } from "../../AppContexts/AppContext";
import { DisplayContext } from "../../AppContexts/DisplayContext";
import { useSelector, useDispatch  } from 'react-redux';
import { RegisterGuest } from "../../Actions/RegisterGuest";
import { clearShippingFee } from "../../Actions/ShipmentPrice";
import { motion, AnimatePresence } from "framer-motion";
import { dropIn } from "../../utils/modalAnimate";

const Modal = ({desktopCheckoutRef}) => {
    const [inputError, setInputError] = useState("")
    const {check, handleCheck, fullName, setFullName,phone, setPhone, email, setEmail, toggle, setToggle, emailValidation, phoneValidation, setBuyerId,setDeliveryMethod, closeModal,deliveryMethod, clearCheckoutHistory,isAlphaStore,closeCheckoutOption, setNeighborhoodName,showDeliveryOption,handleMenuSwitch,refetchStations} = useContext(DisplayContext);
    const dispatch = useDispatch(null)
    const getNames = fullName && fullName.trim().split(/\s+/);
    const first_name = getNames && getNames.length > 0 && getNames[0]
    const last_name = getNames && getNames.length > 2 ? getNames[1] + " " + getNames[2] : getNames && getNames.length > 1 ? getNames[1] : ""
    const {phonePickupRef, emailPickupRef, handleErrorForDesktopPickup, genPassword} = useContext(AppContext);

    const { loading, guest_registered} = useSelector(state => state.RegisterGuestReducer);
    const userId = guest_registered && guest_registered.results._id;

    const handleModalSwitch = () => {  
        handleMenuSwitch(2);
        refetchStations();
        closeCheckoutOption();
    }

    function handleHomeDelivery(e){
         e.stopPropagation();
         dispatch(clearShippingFee())
         setToggle(false); 
         setDeliveryMethod("home-delivery-pay")
    }
    function handleServiceCenter(e){
        e.stopPropagation(); 
        dispatch(clearShippingFee())
        setToggle(true); 
        setDeliveryMethod("service-center-pay")
    }
   const checkFieldValidations = () => {
        if(!fullName || !email || !phone){
            setInputError("All field must be filled*");
            return
        }
        if(!emailValidation || !phoneValidation){
            setInputError("Invalid email or phone number");
            return
        };
        dispatch(RegisterGuest({first_name, last_name, email, phone, password: genPassword(1)}));
        if(!loading){
            handleMenuSwitch(8);
            setDeliveryMethod("service-center-pay");
        }
      
   }
   const experienceCenterNavigate = () => {
      checkFieldValidations();
      refetchStations()
   }

function clearState(){
   closeModal();
   setToggle(false);
   clearCheckoutHistory();
   setNeighborhoodName("")
}

useEffect(() => {
    if(userId){
        setBuyerId(userId)
    }

}, [userId, setBuyerId])
   useEffect(() => {
        const timeout = setTimeout(() => {
            setInputError("")
        }, 3000);
     return () => clearTimeout(timeout)
   }, [])
 
 
    return (
        <>   
        {
           showDeliveryOption === 1 && <AnimatePresence mode='wait'>
                   <motion.dialog 
                        variants={dropIn}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                   ref={desktopCheckoutRef} className= "desktop__checkout-modal card">
               
               <div className="card-header card__header modal__header-title"><span onClick={clearState}><FaArrowLeft /></span><span className="checkout-header">Checkout</span><span className="close-modal"><FaTimes  onClick={clearState}/></span></div>
               <div className="card-body">
                   <p className="card__title">
                       Select a delivery method
                   </p>
                   {
                        isAlphaStore === "gigl sales" ?     
                        "" : <div className="delivery_container">
                        <div className="icon_container">
                            < RiEBike2Fill className="radio__icon"/>
                        </div>
                        <label className="radio-b" htmlFor="home_delivery">
                            <div className="delivery_modal_details">
                                <div className="delivery_modal_title">Door delivery</div>
                                <div className="delivery_modal_note">Choose this option to have a partner rider deliver to your provided address</div>
                            </div>
                            <input type="radio" name="delivery-selector" value="door-delivery-modal" className="radio_input" onChange={(e) => {handleCheck(e); handleHomeDelivery(e)}} id="home_delivery" checked={check === "door-delivery-modal" && deliveryMethod === "home-delivery-pay"}/>
                            <div className="radio__btn"></div>
                        </label>    
                        
                    </div>
                   }
           
                   <div className="mobile-delivery-hr"></div>
                       <div className="delivery_container">
                           <div className="icon_container">
                               <FaBuilding className="radio__icon"/>
                           </div>
                           <label className="radio-b" htmlFor="pickup_delivery">
                               <div className="delivery_modal_details">
                                   <div className="delivery_modal_title">GIGL Experience center</div>
                                   <div className="delivery_modal_note">Choose this option to pick-up from any GIGL experience center close to you</div>
                               </div>
                               <input type="radio" value="pick-up-modal" name="delivery-selector" className="radio_input" onChange={(e) => {handleCheck(e); handleServiceCenter(e)}} id="pickup_delivery" checked={check === "pick-up-modal" && deliveryMethod === "service-center-pay" && toggle}/>
                               <div className="radio__btn"></div>
                           </label> 
                   </div>

                   {toggle && <>
                           <div className="delivery-details-input">
                               <label>Full Name</label>
                               <input type="text" placeholder="Full Name" className="input-text" onChange={(e) => {setFullName(e.target.value); setInputError("")}} value={fullName}/>
                           </div>
                       <div className="delivery-details-input mt-2">
                           <label>Email address</label>
                           <input type="email" placeholder="example@giglogistics.ng" className="input-text" onChange={(e) => {setEmail(e.target.value); setInputError("")}} value={email} ref={emailPickupRef} name="email" onKeyUp={() => handleErrorForDesktopPickup(emailPickupRef.current, emailPickupRef.current.name)}/>
                           <p>Email is invalid</p>
                       </div>
                       <div className="delivery-details-input mb-4">
                           <label>Phone Number</label>
                           <input type="tel" placeholder="080********" className="input-text" onChange={(e) => {setPhone(e.target.value);setInputError("")}} value={phone} ref={phonePickupRef} name="telephone" onKeyUp={() => handleErrorForDesktopPickup(phonePickupRef.current, phonePickupRef.current.name)}/>
                           <p>Phone number must be an 11 digit number</p>
                       </div>    
                       
               </>}
               

                   <div>{inputError ? <p className="text-danger text-center mt-1" style={{fontSize: "12px"}}>{inputError}</p> : ""}
                   </div>
                   {check === "pick-up-modal" && <div className="delivery-btn pick-up-btn" onClick={experienceCenterNavigate}>
                   <button>Gig Pickup Location</button>
                   </div>}
                   {check ===  "door-delivery-modal" &&  <div className="delivery-btn mt-4" onClick={handleModalSwitch}>
                   <button> Door Delivery</button>
                   </div>}
                   {check !== "door-delivery-modal" && check !== "pick-up-modal" &&  <div className={check === "" ? "delivery-btn-disabled delivery-btn sticky" : "delivery-btn sticky"} >
                   <button disabled={check === ""}> Choose Delivery method</button>
                   </div>} 
               </div>
             </motion.dialog>
           </AnimatePresence>
        }      

     </>
    )
}

export default Modal