import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";
import {config} from "../../config";

export const contactApi = createApi({
        reducerPath: "contactApi",
        baseQuery: fetchBaseQuery({baseUrl:`${getUrl(config.prodUserUrl,config.devUserUrl)}`,  prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
        
            if (token) {
              headers.set('authorization', `Bearer ${token}`)
            }
        
            return headers
        }}),
        tagTypes: ["Contact"],
        endpoints: (builder) => ({
            newContact: builder.mutation({
                query: body => ({
                    url: "/contacts/create",
                    method: "post",
                    body
                }),
                invalidatesTags: ["Contact"]
            }),
            checkMobileVerification: builder.query({
                query: ({_id}) => ({
                    url: `/user/check/mobile_verification?userId=${_id}`,
                    method: 'get'
                }),
                providesTags: ['Contact']
            }),
            userDetails: builder.query({
                query: ({userId})=>({
                    url: `/user/detail/${userId}`,
                    method: 'GET'
                }),
                providesTags: ['Contact']
            }),
        })
});
export const {useNewContactMutation, useUserDetailsQuery, useCheckMobileVerificationQuery} = contactApi