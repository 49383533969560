import CryptoJS from "crypto-js";

export const decryptData = (encryptedData, secretKey) => {
    if (encryptedData && secretKey) {
        try {
            // Step 1: Decode base64 encoded data
            const decData = CryptoJS.enc.Base64.parse(encryptedData).toString(CryptoJS.enc.Utf8);
            // Step 2: Decrypt the data
            const bytes = CryptoJS.AES.decrypt(decData, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
         
            // Step 3: Check if decrypted data is empty or not valid JSON
            if (!decryptedData) {
                console.error("Decryption resulted in empty string");
                return null;
            }

            // Step 4: Parse the decrypted data as JSON
            return JSON.parse(decryptedData);
        } catch (error) {
            console.error("Decryption or JSON parsing failed:", error.message);
            return null;
        }
    } else {
        console.error("Missing encrypted data or secret key");
        return null;
    }
};
