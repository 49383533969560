export const dropIn = {
    hidden: { opacity: 0, y: -100 },
    visible: {
         opacity: 1 ,
         y: 0,
         transition: {
             duration: 0.3,
             type: "string",
             damping: 25,
             stiffness: 500
         }
    },
    exit: { opacity: 0, y: 100 }
}