import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useContext, useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { Check,Edit,Trash2 } from "lucide-react"
import { AppContext } from "../../../../AppContexts/AppContext"
import { actionTypes } from "../../../../AppContexts/constants"
import { useLocalStorage } from "../../../../utils/useLocalStorage";
import { DisplayContext } from './../../../../AppContexts/DisplayContext';
import numbro from 'numbro';



const ModalComponent = ({isModalOpen, handleCloseModal, prodName, handleDeleteItem}) => {
    return (
        <>
        {isModalOpen && (
            <div className="modal show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Are you sure you want to delete?</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseModal}></button>
                  </div>
                  <div className="modal-body">
                    <p>{prodName}</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>No</button>
                    <button type="button" style={{backgroundColor: "#192150", color: "#FFFFFF"}} className="btn" onClick={handleDeleteItem}>Yes</button>
                  </div>
                </div>
              </div>
            </div>
          )}
       </>   
    )
}


const BulkProductCard = () => {
    const {getItem} = useLocalStorage("batch_upload_items")
    const {state, uploadDispatch, setEditIndex} = useContext(AppContext);
    const {setCheckPickup} = useContext(DisplayContext)
    const bulkItems = state.batch_upload_items || getItem();
    const navigate = useNavigate();

    const [prodName, setProdName] = useState("");
    const [prodIndex, setProdIndex] = useState(null)
   
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = (name) => {
      setIsModalOpen(true);
      setProdName(name)
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    const handleDeleteItem = () => {
        uploadDispatch({ type: actionTypes.REMOVE_PRODUCT, payload: prodIndex });
        setIsModalOpen(false);
    };

    const handleEditItem = (index) => {
      
      const itemToEdit = state.batch_upload_items[index];
      
      // uploadDispatch({ type: actionTypes.UPDATE_QUANTITY, payload: itemToEdit.quantity });
      
      uploadDispatch({
        type: actionTypes.EDIT_PRODUCT,
        index: index,
        payload: itemToEdit,
      });

      setCheckPickup("");
      setEditIndex(index);
      navigate(-1);
    };
    

    useEffect(() => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
  return (
    <div>
        <div className='store-card my-4'>
            {
                bulkItems?.length > 0 && bulkItems.map((product, index) => {
                    return (
                        <div key={index} className='card-details bulk-card'>
                            <img src={product.front_view} alt={product.productName}/>
                            <div className='bulk-product-desc'>
                                <div className="title" title={product.productName}>{product.productName}</div>
                                <h3 className="mt-2">N {numbro(product.price).format({thousandSeparated: true})}</h3>
                                <div className="verify-upload-tag">
                                    <span className="check-upload">
                                    <Check size={10}/>
                                    </span>
                                    <span className="check-text">Ready for upload</span>
                                </div>
                            </div>
                        <div className="edit-delete-action">
                            <span onClick={() => handleEditItem(index)}>
                                <Edit size={18} color="#333333"/>
                            </span>
                            <span onClick={() => {handleOpenModal(product.productName); setProdIndex(index)}}>
                                <Trash2 size={18} color="#ED3237"/>
                            </span>
                        </div>
                        </div>
                    )
                })
            }
      
        
        </div>
       <ModalComponent isModalOpen={isModalOpen} handleCloseModal={handleCloseModal} prodName={prodName} handleDeleteItem={handleDeleteItem}/>
    </div>
  )
}

export default BulkProductCard