import {FaFacebookF,FaInstagram, FaTwitter, FaLinkedinIn} from "react-icons/fa";
import { Link } from "react-router-dom/dist";

const Socials = () => {
   
    return (
        <div className="scroll-to-top">
                <div className="social-cover">
                    <div className="social-icons">
                        <div  className="icon">
                            <a href="https://www.facebook.com/ShoponAlpha" target="__blank" rel="noreferrer"><FaFacebookF/></a>
                        </div>
                        <div  className="icon">
                            <a href="https://www.linkedin.com/company/shoponalpha/" target="__blank" rel="noreferrer"><FaLinkedinIn/></a>
                        </div>
                        <div  className="icon">
                            <a href="https://www.twitter.com/ShoponAlpha" target="__blank" rel="noreferrer"><FaTwitter/></a>
                        </div>
                        <div  className="icon">
                            <a href="https://www.instagram.com/shoponalpha" target="__blank" rel="noreferrer"><FaInstagram/></a>
                        </div>  
                    </div>
                    <div className="footer-policy">
                        <Link to='terms&conditions'>Terms and conditions</Link>
                        <Link to='privacy-policy'>Privacy policy</Link>
                    </div>
                </div>
                <hr className="scroll-border"/>
                <div className="text-center footer-snippet">
                    &copy;{new Date().getFullYear()} Alpha. All rights reserved.
                </div>
        </div>
    )
}

export default Socials;