import { categoryImages } from "../images"

export const bgImages = (name) => {
    return name.toLowerCase().includes("Groceries".toLowerCase()) ? categoryImages.groceriesImg : name.toLowerCase().includes("Automobile Accessories".toLowerCase()) ? categoryImages.automobileImg : name.toLowerCase().includes("Babies and Kids".toLowerCase()) ? categoryImages.babiesImg : name.toLowerCase().includes("Clothing".toLowerCase()) ? categoryImages.clothingImg : name.toLowerCase().includes("Shoes".toLowerCase()) ? categoryImages.shoesImg : name.toLowerCase().includes("Hair and Wigs".toLowerCase()) ? categoryImages.hairImg : name.toLowerCase().includes("Home and Kitchen".toLowerCase()) ? categoryImages.kitchenImg : name.toLowerCase().includes("Electronics".toLowerCase()) ? categoryImages.electronicImg : name.toLowerCase() === "Accessories".toLowerCase() ? categoryImages.accessoriesImg : name.toLowerCase().includes("Computers and Computer Accessories".toLowerCase()) ? categoryImages.computerImg : name.toLowerCase().includes("Health and wellness".toLowerCase()) ? categoryImages.wellnessImg : name.toLowerCase().includes("Phones and Gadgets".toLowerCase()) ? categoryImages.phoneImg : categoryImages.automobileImg
 }

export const content = {
    accessories: 'Find all the gadgets you need from verified stores. From smartphones, VR gear, game consoles, headsets, and smart devices for your homes at your fingertips.',
    babies: "Shop all your babies and kids' products seamlessly. We offer everything you need for your little ones: baby gear, toys, clothing, safety essentials, and more.",
    computer: "Explore our vast collection of cutting-edge computers and accessories for all your digital needs. We've got everything to boost your tech game.",
    clothing: "Discover a wide range of stylish and comfortable clothing for you. From trendy outfits to everyday essentials, there’s a style for every occasion.",
    health: "Browse our health and wellness section to shop nourishing products for your well-being such as vitamins and supplements, fitness equipment, and relaxation aids.",
    automobiles: "Upgrade your vehicle with amazing accessories to experience smoother, safer, and more stylish driving.",
    groceries: "Stock your kitchen and fridge with fresh groceries, delivered straight to your doorstep.",
    home: "Create the home you’ve always dreamt about. Buy quality home and kitchen appliances on Alpha Shop.",
    phone: "Enhance your digital experience with sleek smartphones and must-have tech accessories for your everyday needs.",
    shoes: "Find quality shoes and bags to glam up your outfit any day and time.",
    hair: "Shop glamorous wigs and high-quality hair products. Explore our virtual salon to discover endless possibilities for fabulous hair days.",
    electronics: "Get quality electronic and portable power products for your homes and offices, such as TVs, speakers, power banks, inverters, and solar panels.",
  }

  export const bgContent = (name) => {
    return name.toLowerCase().includes("Groceries".toLowerCase()) ? content.groceries : name.toLowerCase().includes("Automobile Accessories".toLowerCase()) ? content.automobiles : name.toLowerCase().includes("Babies and Kids".toLowerCase()) ? content.babies : name.toLowerCase().includes("Clothing".toLowerCase()) ? content.clothing : name.toLowerCase().includes("Shoes".toLowerCase()) ? content.shoes : name.toLowerCase().includes("Hair and Wigs".toLowerCase()) ? content.hair : name.toLowerCase().includes("Home and Kitchen".toLowerCase()) ? content.home : name.toLowerCase().includes("Electronics".toLowerCase()) ? content.electronics : name.toLowerCase() === "Accessories".toLowerCase() ? content.accessories : name.toLowerCase().includes("Computers and Computer Accessories".toLowerCase()) ? content.computer : name.toLowerCase().includes("Health and wellness".toLowerCase()) ? content.health : name.toLowerCase().includes("Phones and Gadgets".toLowerCase()) ? content.phone : ""
 }