import { useState } from 'react';
import { Plus, Check, Minus } from 'lucide-react';
import React, { useContext, useEffect } from 'react';
import { uniqueColors } from './../../../../utils/colorPalette';
import { AppContext } from '../../../../AppContexts/AppContext';
import { actionTypes } from '../../../../AppContexts/constants';

const ListSizes = () => {
  const {state, uploadDispatch, showColorPicker, setShowColorPicker, currentSizeIndex, setCurrentSizeIndex, editIndex} = useContext(AppContext);
  const {prefilledData} = state
  const [toggleColorBtn, setToggleColorBtn] = useState(false)
  const isColor =  state?.get_category?.color;
  const isSize =  state?.get_category?.size;
  const isGender =  state?.get_category?.gender
  

  const handleInputChange = (e) => {
    const { value: inputValue } = e.target;
    // Remove any non-digit characters
    const cleanedValue = inputValue.replace(/[^0-9]/g, '');
    // Dispatch the value
    uploadDispatch({
      type: actionTypes.CHANGE_INPUT,
      payload: {
        name: e.target.name,
        value: cleanedValue === '' ? 0 : parseInt(cleanedValue, 10),
      },
    });
  };
  
  const handleSizeClick = (size) => {
    let newSelectedSizes = [...state.sizes];
    const index = newSelectedSizes.findIndex((item) => item.value === size);
  
    if (index !== -1) {
      // Remove the size from the array if it exists
      newSelectedSizes = newSelectedSizes.filter((item) => item.value !== size);
    } else {
      // Add the size to the array if it doesn't exist
      newSelectedSizes.push({ value: size.trim(), quantity: '', color: '', status: true });
    }
  
    // Convert quantity back to a number, reset to empty string if zero
    const convertedSizes = newSelectedSizes.map(item => ({
      ...item,
      quantity: item.quantity === '' || Number(item.quantity) === 0 ? '' : Number(item.quantity),
    }));
  
    uploadDispatch({ type: actionTypes.UPDATE_SIZES, payload: convertedSizes });
    setShowColorPicker(false);
    setToggleColorBtn(false);
  };
  
  const handleQuantityChange = (index, quantity) => {
    // Only allow numeric values (including empty string to allow clearing input)
    if (/^\d*$/.test(quantity)) {
        const newSelectedSizes = [...state.sizes];
        
        // Update quantity as a string in the state
        newSelectedSizes[index].quantity = quantity;
        
        // Convert quantity to a number before dispatching
        const updatedSizes = newSelectedSizes.map(item => ({
          ...item,
          quantity: Number(item.quantity),  // Convert to number here
        }));
        
        uploadDispatch({ type: actionTypes.UPDATE_SIZES, payload: updatedSizes });
    }
};

  const handleAddColor = (index, color) => {
    const newSelectedSizes = [...state.sizes];
    const colorArray = newSelectedSizes[index].color ? newSelectedSizes[index].color.split(',') : [];
    if (!colorArray.includes(color)) {
      colorArray.push(color);
      newSelectedSizes[index].color = colorArray.join(',');
      uploadDispatch({ type: actionTypes.UPDATE_SIZES, payload: newSelectedSizes });
    }
  };

  const handleRemoveColor = (index, color) => {
    const newSelectedSizes = [...state.sizes];
    const colorArray = newSelectedSizes[index].color.split(',');
    newSelectedSizes[index].color = colorArray.filter(c => c !== color).join(',');
    uploadDispatch({ type: actionTypes.UPDATE_SIZES, payload: newSelectedSizes });
  };

  const toggleColorPicker = (index) => {
    if (currentSizeIndex === index) {
      setShowColorPicker(!showColorPicker);
      setToggleColorBtn(!toggleColorBtn)
    } else {
      setShowColorPicker(true);
      setToggleColorBtn(true)
    }
    setCurrentSizeIndex(index);
  };

  const handleGenderClick = (genderId) => {
    uploadDispatch({ type: actionTypes.UPDATE_GENDER, payload: genderId });
  };
  
   
  const isGenderSelected = (genderId) => {
    if(editIndex !== null && !state.gender){
        return prefilledData.gender === genderId
    }else {
      return state.gender === genderId;
    }
  
  }

  const handleColorClick = (color) => {
    uploadDispatch({ type: actionTypes.UPDATE_COLOR, payload: color });
  };


  useEffect(() => {
    const totalQuantity = state.sizes.reduce((acc, size) => acc + (Number(size.quantity) || 0), 0);
    if (totalQuantity > 0) {
       uploadDispatch({ type: actionTypes.UPDATE_QUANTITY, payload: totalQuantity });
    }
  }, [state.sizes, uploadDispatch]);


  useEffect(() => {
    if (editIndex !== null) {
      uploadDispatch({ type: actionTypes.UPDATE_GENDER, payload: prefilledData?.gender });
    }
  }, [editIndex, prefilledData?.gender, uploadDispatch]);

  useEffect(() => {
     if(editIndex !== null){
        uploadDispatch({type: actionTypes.UPDATE_QUANTITY, payload: prefilledData.quantity})
     }
  }, [prefilledData.quantity, editIndex, uploadDispatch])

  useEffect(() => {
     if(editIndex !== null){
        uploadDispatch({ type: actionTypes.UPDATE_SIZES, payload: prefilledData?.sizes });
        uploadDispatch({ type: actionTypes.UPDATE_COLOR, payload: prefilledData?.color });
     }
  }, [uploadDispatch, editIndex, prefilledData.sizes, prefilledData.color]);

 

  return (
    <div>
      <div className='d-flex gap-2 size-color-option'>
            {state?.sub_category_sizes?.length > 0 && state.sub_category_sizes.map((size) => (
                <button 
                    key={size.trim()} 
                    onClick={() => handleSizeClick(size.trim())}
                    className={state?.sizes?.some(item => item.value === size.trim()) ? "active__size-button" : "upload__size-button"}
                >
                    {size}
                </button>
            ))}
      </div>

       { state?.sizes?.length > 0 && <label>Stock Quantity <small>(Enter available quantity)</small></label> }
         
      {state?.sizes?.map((item, index) => (
        <div key={index} className='mb-2'>
          <div className='color-qty-size'>
            <div className='position-relative size-input'>
              <button className='size-btn-text'>{item.value}</button>
              <input
                type="text"
                value={item.quantity === 0 ? "" : item.quantity}  // Display the quantity as a string
                onChange={(e) => handleQuantityChange(index, e.target.value)}  // Handle string input
                className='qty-input w-100'
                placeholder='Enter available quantity'
              />

            </div>
           {
              item?.color?.length > 0 ?  <div className='position-relative d-flex align-items-center '>
              {item.color.split(',').map((color, i) => (
                <div title={"click to remove color"} key={i} style={{ backgroundColor: color, width: "20px", height: "20px", borderRadius: "50%", border: `1px solid black`, cursor: "pointer"}} className='selected-color-style' onClick={() => {handleRemoveColor(index, color)}}/>
              ))}
          </div> : null 
           }

           {
               isSize && isColor && <div className='add-colors' onClick={() => {toggleColorPicker(index)}}><span className='add-btn-icon'>{toggleColorBtn ? <Minus size={14}/> : <Plus size={14}/>}</span> {item?.color?.length < 1  && <span className='add-text'>{toggleColorBtn ? "close colors" : "Add color"}</span>}</div>
           }
           {
               state?.sub_category_sizes?.length > 0 && !isSize && isColor && <div className='add-colors' onClick={() => {toggleColorPicker(index)}}><span className='add-btn-icon'>{toggleColorBtn ? <Minus size={14}/> : <Plus size={14}/>}</span> {item?.color?.length < 1  && <span className='add-text'>{toggleColorBtn ? "close colors" : "Add color"}</span>}</div>
              
           }
           
          </div>
               <div className="my-2 color-palette-section">
                  {
                      isColor && isSize ? <>
                      {showColorPicker && currentSizeIndex === index && (
             
                    uniqueColors.map((color) => (
                      <button key={color} style={{backgroundColor: color, height: "30px", width: "30px", border: "1px solid #000000", borderRadius: "50%"}} 
                      onClick={() => {
                        if (item.color.split(',').includes(color)) {
                          handleRemoveColor(index, color);
                        } else {
                          handleAddColor(index, color);
                        }
                      }}
                          title={item?.color?.split(',')?.includes(color) ? "Click to remove color" : "Click to add color"}
                      >
                            {item?.color?.includes(color) ? <Check size={20} color={color === "#000000" ? "#FFFFFF" : "#000000"}/> : null}
                      </button>
                    ))
           
           
                     )}
                    </> : 
                    isColor && !isSize && state?.sub_category_sizes?.length > 0 ? <>

                          {showColorPicker && currentSizeIndex === index && (
             
                          uniqueColors.map((color) => (
                            <button key={color} style={{backgroundColor: color, height: "30px", width: "30px", border: "1px solid #000000", borderRadius: "50%"}} 
                            onClick={() => {
                              if (item.color.split(',').includes(color)) {
                                handleRemoveColor(index, color);
                              } else {
                                handleAddColor(index, color);
                              }
                            }}
                                title={item?.color?.split(',')?.includes(color) ? "Click to remove color" : "Click to add color"}
                            >
                                  {item?.color?.includes(color) ? <Check size={20} color={color === "#000000" ? "#FFFFFF" : "#000000"}/> : null}
                            </button>
                          ))

                       )}
                    </> : null

                  }
 
              </div>
            </div>
      ))}
         
          {
             !isSize && state?.sub_category_sizes?.length === 0 && <div className='position-relative'>
             <label>Stock Quantity <small>(Enter available quantity)</small></label>
             <input
                 type="text"
                 name="quantity"
                 value={state.quantity || ""}
                 onChange={handleInputChange}
                 className='w-100'
                //  placeholder='Enter available quantity'
             />
           </div>
          }
          {
             isSize && state?.sub_category_sizes?.length === 0 && state.sizes.length === 0 && <div className='position-relative'>
             <label>Stock Quantity <small>(Enter available quantity)</small></label>
             <input
                 type="text"
                 name="quantity"
                 value={
                   state.quantity || ""
                }
              
                 onChange={handleInputChange}
                 className='w-100'
                //  placeholder='Enter available quantity'
             />
           </div>
          }
        <div>
           {
              isGender &&   <label><label>Select gender</label></label>
           }
            <div className='gender-btn-sections'>

                {
                  
                    isGender && state.get_category.sex.map(({name, _id}) => (
                        <button className={isGenderSelected(_id) ? 'size-btn-active' : 'btn-default'}  key={_id} onClick={() => handleGenderClick(_id)}>{name}</button>
                    )) 
                
                }
            </div>
            <div className="my-2 color-palette-section">
               {
                   isColor && !isSize && state?.sub_category_sizes?.length === 0 ?
                   <>
                    {

                        uniqueColors.map((color) => (
                          <button onClick={() => handleColorClick(color)} key={color} style={{backgroundColor: color, height: "30px", width: "30px", border: "1px solid #000000", borderRadius: "50%"}} 
                        
                              title={state?.color?.split(',').includes(color) ? "Click to remove color" : "Click to add color"}
                          >
                                {state?.color?.split(',').includes(color) ? <Check size={20} color={color === "#000000" ? "#FFFFFF" : "#000000"}/> : null}
                          </button>
                        ))
                     
                      }
                    </> : null
               }
          </div>
          <div className="my-2 color-palette-section">
               {
                   isColor && isSize && state?.sub_category_sizes?.length === 0 && state.sub_category !== "" && state.sizes.length === 0
                    ?
                   <>
                    {

                        uniqueColors.map((color) => (
                          <button onClick={() => handleColorClick(color)} key={color} style={{backgroundColor: color, height: "30px", width: "30px", border: "1px solid #000000", borderRadius: "50%"}} 
                        
                              title={state?.color?.split(',').includes(color) ? "Click to remove color" : "Click to add color"}
                          >
                                {state?.color?.split(',').includes(color) ? <Check size={20} color={color === "#000000" ? "#FFFFFF" : "#000000"}/> : null}
                          </button>
                        ))
                     
                      }
                    </> : null
               }
          </div>
        </div>
      
    </div>
  );
};

export default ListSizes;
