import { CLEAR_ORDER_SEARCH, ORDER_SEARCH_FAIL, ORDER_SEARCH_REQUEST, ORDER_SEARCH_SUCCESS } from "../constants/constants";

const initialState = {
    order_search: [],
    order_search_docs: [],
    order_search_loading: false,
    order_search_error: ""
}


export const orderSearchReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
         case ORDER_SEARCH_REQUEST:
              return {
                  ...state,
                  order_search_loading: true
              }
         case ORDER_SEARCH_SUCCESS:
              return {
                  ...state,
                  order_search_loading: false,
                  order_search: payload,
                  order_search_docs: payload.docs
              }
         case ORDER_SEARCH_FAIL:
              return {
                  ...state,
                  order_search_loading: false,
                  order_search_error: payload
              }
         case CLEAR_ORDER_SEARCH: 
              return {
                  ...state,
                  order_search_loading: false,
                  order_search_error: ""
              }
         default:
             return state;
    }
}