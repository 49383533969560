export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const REGISTER_GUEST_USER_REQUEST = "REGISTER_GUEST_USER_REQUEST";
export const REGISTER_GUEST_USER_SUCCESS = "REGISTER_GUEST_USER_SUCCESS";
export const REGISTER_GUEST_USER_FAIL = "REGISTER_GUEST_USER_FAIL"


export const PRODUCT_GET_REQUEST = "PRODUCT_GET_REQUEST";
export const PRODUCT_GET_SUCCESS = "PRODUCT_GET_SUCCESS";
export const PRODUCT_GET_FAIL = "PRODUCT_GET_FAIL";
export const PRODUCT_GET_CLEAR = "PRODUCT_GET_CLEAR";

export const HOME_DELIVERY_GET_REQUEST = "HOME_DELIVERY_GET_REQUEST";
export const HOME_DELIVERY_GET_SUCCESS = "HOME_DELIVERY_GET_SUCCESS";
export const HOME_DELIVERY_GET_FAIL = "HOME_DELIVERY_GET_FAIL";


export const LOCAL_STATIONS_GET_REQUEST = "LOCAL_STATIONS_GET_REQUEST";
export const LOCAL_STATIONS_GET_SUCCESS = "LOCAL_STATIONS_GET_SUCCESS";
export const LOCAL_STATIONS_GET_FAIL = "LOCAL_STATIONS_GET_FAIL";

export const PAYMENT_METHOD_GET_REQUEST = "PAYMENT_METHOD_GET_REQUEST";
export const PAYMENT_METHOD_GET_SUCCESS = "PAYMENT_METHOD_GET_SUCCESS";
export const PAYMENT_METHOD_GET_FAIL = "PAYMENT_METHOD_GET_FAIL";


export const GIG_PICKUP_LOCATION_GET_REQUEST = "GIG_PICKUP_LOCATION_GET_REQUEST";
export const GIG_PICKUP_LOCATION_GET_SUCCESS = "GIG_PICKUP_LOCATION_GET_SUCCESS";
export const GIG_PICKUP_LOCATION_GET_FAIL = "GIG_PICKUP_LOCATION_GET_FAIL";


export const SHIPMENT_PRICE_GET_REQUEST = "SHIPMENT_PRICE_GET_REQUEST";
export const SHIPMENT_PRICE_GET_SUCCESS = "SHIPMENT_PRICE_GET_SUCCESS";
export const SHIPMENT_PRICE_GET_FAIL = "SHIPMENT_PRICE_GET_FAIL";


export const INITIATE_TRANSACTION_REQUEST = "INITIATE_TRANSACTION_REQUEST";
export const INITIATE_TRANSACTION_SUCCESS = "INITIATE_TRANSACTION_SUCCESS";
export const INITIATE_TRANSACTION_FAIL = "INITIATE_TRANSACTION_FAIL";
export const CLEAR_INITIATE_TRANSACTION = "CLEAR_INITIATE_TRANSACTION";

export const GET_SERVICE_CENTER_DETAILS_REQUEST = "GET_SERVICE_CENTER_DETAILS_REQUEST";
export const GET_SERVICE_CENTER_DETAILS_SUCCESS = "GET_SERVICE_CENTER_DETAILS_SUCCESS";
export const GET_SERVICE_CENTER_DETAILS_FAIL = "GET_SERVICE_CENTER_DETAILS_FAIL";

export const VERIFY_ORDER_REQUEST = "VERIFY_ORDER_REQUEST";
export const VERIFY_ORDER_SUCCESS = "VERIFY_ORDER_SUCCESS";
export const VERIFY_ORDER_FAIL = "VERIFY_ORDER_FAIL";
export const CLEAR_VERIFY_ORDER = "CLEAR_VERIFY_ORDER";



export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST"
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL"
export const CLEAR_CREATE_ORDER = "CLEAR_CREATE_ORDER"

export const GET_STORE_REQUEST = "GET_STORE_REQUEST"
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS"
export const GET_STORE_FAIL = "GET_STORE_FAIL";



export const TRACK_ORDER_REQUEST = "TRACK_ORDER_REQUEST";
export const TRACK_ORDER_SUCCESS = "TRACK_ORDER_SUCCESS";
export const TRACK_ORDER_FAIL = "TRACK_ORDER_FAIL";

export const WAITLIST_CREATE_REQUEST = "WAITLIST_CREATE_REQUEST";
export const WAITLIST_CREATE_SUCCESS = "WAITLIST_CREATE_SUCESS";
export const WAITLIST_CREATE_FAIL = "WAITLIST_CREATE_FAIL";

export const REFUND_PAYMENT_REQUEST = "REFUND_PAYMENT_REQUEST";
export const REFUND_PAYMENT_SUCCESS = "REFUND_PAYMENT_SUCCESS";
export const REFUND_PAYMENT_FAIL = "REFUND_PAYMENT_FAIL";

export const BANK_LIST_REQUEST = "BANK_LIST_REQUEST";
export const BANK_LIST_SUCCESS = "BANK_LIST_SUCCESS";
export const BANK_LIST_FAIL = "BANK_LIST_FAIL";

export const GET_ETA_REQUEST = "GET_ETA_REQUEST";
export const GET_ETA_SUCCESS = "GET_ETA_SUCCESS";
export const GET_ETA_FAIL = "GET_ETA_FAIL";

export const GET_SHIPMENT_PRICE_BY_QUANTITY_REQUEST = "GET_SHIPMENT_PRICE_BY_QUANTITY_REQUEST";
export const GET_SHIPMENT_PRICE_BY_QUANTITY_SUCCESS = "GET_SHIPMENT_PRICE_BY_QUANTITY_SUCCESS";
export const GET_SHIPMENT_PRICE_BY_QUANTITY_FAIL = "GET_SHIPMENT_PRICE_BY_QUANTITY_FAIL";

export const LIST_CATEGORY_REQUEST = "LIST_CATEGORY_REQUEST";
export const LIST_CATEGORY_SUCCESS = "LIST_CATEGORY_SUCCESS";
export const LIST_CATEGORY_FAIL = "LIST_CATEGORY_FAIL";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";


export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAIL = "GET_SUBSCRIPTION_FAIL";


export const ORDER_SEARCH_REQUEST = "ORDER_SEARCH_REQUEST";
export const ORDER_SEARCH_SUCCESS = "ORDER_SEARCH_SUCCESS";
export const ORDER_SEARCH_FAIL = "ORDER_SEARCH_FAIL";

export const SEARCH_ALPHA_REQUEST = "SEARCH_ALPHA_REQUEST";
export const SEARCH_ALPHA_SUCCESS = "SEARCH_ALPHA_SUCCESS";
export const SEARCH_ALPHA_FAIL = "SEARCH_ALPHA_FAIL";

export const CLEAR_SEARCH_ALPHA = "CLEAR_SEARCH_ALPHA";
export const CLEAR_ETA_DATA = "CLEAR_ETA_DATA";
export const CLEAR_ORDER_SEARCH = "CLEAR_ORDER_SEARCH";
export const CLEAR_SHIPMENT_PRICE = "CLEAR_SHIPMENT_PRICE";
export const CLEAR_SHIPMENT_PRICE_REFETCH = "CLEAR_SHIPMENT_PRICE_REFETCH";

export const HOME_URL = "/";
export const STORE_URL = "/store";
export const CART_URL = "/cart"


