import React, { useCallback } from 'react';
import {MdOutlineRotateRight} from "react-icons/md"

const StoreCard = ({data, setLimit, lastIndex, currentLength, isFetching}) => {
    const loadMore = useCallback(() => {
        if(lastIndex === currentLength){
           return;
        }else {
            setLimit(prev => prev + 12)
        }
   }, [currentLength, lastIndex, setLimit])

  return (
    <div>
        <div className='store-card my-4'>
            {/* {totalDocs <= 0 && <div>No products in this store</div>} */}
            {
                data && data.length > 0 && data?.map((product) => (
                    <div key={product._id} className='card-details'>
                        <img src={product.front_view} alt={product.productName}/>
                        <div className='price-description'>
                            <div className='text-center' title={product.productName}>{product.productName}</div>
                            <h3 className='text-center'>N {product.price}</h3>
                        </div>
                    </div>
                ))
            }
        
        </div>
        {lastIndex === currentLength || data?.length === 0  ? <div className='nomore__show-more storecard'>No more Items to show</div> : <button className='store__show-more storecard' onClick={loadMore}><span className={`${isFetching && "showmore-rotate"}`}><MdOutlineRotateRight size={18} /></span>Show More</button>}
    </div>
     
  )
}

export default StoreCard