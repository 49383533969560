import React, { useContext, useEffect } from 'react'
import ListSizes from './ListSizes';
import { AppContext } from '../../../../AppContexts/AppContext';
import { actionTypes } from '../../../../AppContexts/constants';



const DescriptionForm = () => {
  const {state, uploadDispatch, priceValue, setPriceValue, editIndex} = useContext(AppContext);
  const {prefilledData} = state

  const handleChange = (e) => {
    uploadDispatch({type: actionTypes.CHANGE_INPUT, payload:{name: e.target.name, value: e.target.value}})
  }

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
  
    // Allow empty input to be cleared
    if (inputValue === "") {
      setPriceValue("");
      uploadDispatch({
        type: actionTypes.CHANGE_INPUT,
        payload: {
          name: e.target.name,
          value: 0,
        },
      });
      return;
    }
  
    // Remove any characters that are not digits or decimal point
    const regex = /[^0-9.]/g;
    inputValue = inputValue.replace(regex, '');
  
    // Replace leading zero with typed number
    if (inputValue.length === 2 && inputValue[0] === '0' && inputValue[1] !== '.') {
      inputValue = inputValue[1];
    }
  
    // Ensure only one decimal point
    if (inputValue.includes('.')) {
      const parts = inputValue.split('.');
      if (parts[1].length > 2) {
        inputValue = parts[0] + '.' + parts[1].slice(0, 2);
      }
    }
  
    // Format the value with thousand separators
    const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Update the state
    setPriceValue(formattedValue);
  
    // Convert to number for dispatch
    const numberValue = parseFloat(inputValue) || 0;
  
    // Dispatch the numerical value or 0
    uploadDispatch({
      type: actionTypes.CHANGE_INPUT,
      payload: {
        name: e.target.name,
        value: numberValue,
      },
    });
  
    // Ensure the cursor is always at the end
    setTimeout(() => {
      const inputElement = e.target;
      inputElement.focus();
      inputElement.selectionStart = inputElement.selectionEnd = inputElement.value.length;
    }, 0);
  };
  
  
  useEffect(() => {
     if(editIndex !== null){
        setPriceValue(prefilledData.price)
     }
  }, [editIndex,setPriceValue, prefilledData.price]);

  useEffect(() => {
    if(editIndex !== null){
       uploadDispatch({type: actionTypes.CHANGE_INPUT, payload: {name: "productName", value: prefilledData.productName} })
    }
 }, [editIndex, prefilledData.productName, uploadDispatch])
  
  
return (
    <section className='d-flex flex-column gap-2 upload-part-form'>
        <div className='d-flex flex-column gap-1 w-100 position-relative'>
            <label>Enter product name</label>
            <input name="productName" value={state.productName} onChange={handleChange} type='text' placeholder='Nike New Balance'  className='w-100 px-2'/>
        
        </div>
        <div className='d-flex flex-column gap-1 w-100 position-relative'>
            <label>Enter product price</label>
            <div className='position-relative'>
              <input
                name="price"
                value={priceValue}
                onChange={handleInputChange}
                type='text'
                className="w-100 px-2 price-in-naira"
              />
              <span className='naira-sign'>₦</span>
            </div>
        </div>
    
        <div>
          {
              state?.sub_category_sizes?.length > 0 && <label>Available Sizes (You can select more than one)</label>
          }
           
            <>
                <ListSizes/>
            </>
        
        </div>
        
    </section>
  )
}

export default DescriptionForm