import { CLEAR_ORDER_SEARCH, CLEAR_SEARCH_ALPHA, ORDER_SEARCH_FAIL, ORDER_SEARCH_REQUEST, ORDER_SEARCH_SUCCESS, SEARCH_ALPHA_FAIL, SEARCH_ALPHA_REQUEST, SEARCH_ALPHA_SUCCESS } from "../constants/constants";
import { getUrl } from './../helpers/getUrl';

export function AlphaSearchAction ({alphaCode, page,limit}) {
    return async (dispatch) => {
       dispatch({type: SEARCH_ALPHA_REQUEST});
       try {
         const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION,process.env.REACT_APP_PRODUCT_BASE_URL)}/product/search?searchTerm=${alphaCode}&page=${page}&limit=${limit}`, {
             method: "GET",
             headers: {
                "Content-Type": "application/json",
                ACCEPT: "application/json"
             }
         })

         const data = await res.json();
         if(data.error){
             return dispatch({type: SEARCH_ALPHA_FAIL, payload : data.message})
         }
            dispatch({type: SEARCH_ALPHA_SUCCESS, payload: data})     
         
       }catch (error) {
       const message =
       error.response && error.response.data
         ? error.response.data.message
         : error.message;
         dispatch({type: SEARCH_ALPHA_FAIL, payload: message})
       }
   }
}


export function OrderSearchAction({waybillId,offset,limit}) {

   return async(dispatch) => {
   dispatch({type: ORDER_SEARCH_REQUEST});
   try {
       const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION,process.env.REACT_APP_ORDER_BASE_URL)}/order/search_order?searchTerm=${waybillId}&offset=${offset}&limit=${limit}`, {
           method: "GET",
           headers: {
               "Content-Type": "application/json",
               ACCEPT: "application/json"
           }
       })
       const data = await res.json();
       if(data.error){
          return dispatch({type: ORDER_SEARCH_FAIL, payload: data.message})
       }
       dispatch({type: ORDER_SEARCH_SUCCESS, payload: data.results})
   } catch (error) {

       const message =
       error.response && error.response.data
         ? error.response.data
         : error.message;
         dispatch({type: ORDER_SEARCH_FAIL, payload: message})
   }
 }
}


export const clearAlphaSearchState = () => async (dispatch) => {
   dispatch({type: CLEAR_SEARCH_ALPHA});
   dispatch({type: CLEAR_ORDER_SEARCH})
}