import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import UploadImageForm from './UploadImageForm';
import ImagesVideosUpload from './ImagesVideosUpload';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import UploadDescriptionForm from './UploadDescriptionForm';
import UploadForm from './UploadForm';
import DescriptionForm from './DescriptionForm';
import { AppContext } from '../../../../AppContexts/AppContext';
import { actionTypes } from '../../../../AppContexts/constants';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { DisplayContext } from '../../../../AppContexts/DisplayContext';


const TabletImageUploadSection = () => {
  const [goTo, setGoTo] = useState(1);
  const {state, uploadDispatch,  setSaveProductErrors, errorRef, discountErrorRef, editIndex, pickupErrorRef, setPickupAddressError} = useContext(AppContext);
  const {checkPickUp} = useContext(DisplayContext)
  const {setItem} = useLocalStorage("batch_upload_items");

  const navigate = useNavigate()
  const dataToSubmit = useMemo(() => ({
    tag: state?.tag,
    vehicleType: state?.vehicleType,
    address: state?.address,
    productName: state?.productName,
    color: state?.color,
    front_view: state?.front_view,
    description: state?.description,
    rear_view: state?.rear_view,
    left_view: state?.left_view,
    right_view: state?.right_view,
    video_url: state?.video_url,
    category: state?.category,
    sub_category: state?.sub_category,
    price: state?.price,
    quantity: state?.quantity,
    customer_code: state?.customer_code,
    promo_name: state?.promo_name,
    discounted_price: state?.discounted_price,
    lat: state?.lat,
    long: state?.long,
    sizes: state?.sizes,
    gender: state?.gender,
    productImage: state?.productImage,
    userId: state?.userId,
    gift_wrapping: state?.gift_wrapping,
    merchant_first_name: state?.merchant_first_name,
    merchant_last_name: state?.merchant_last_name,
    merchant_phone_number: state?.merchant_phone_number,
    merchant_email: state?.merchant_email,
    neighborhood: state?.neighborhood,
    locality: state?.locality,
    merchant_station_id: state?.merchant_station_id,
    weight: state?.weight,
    sub_category_sizes: state?.sub_category_sizes,
    get_category: state?.get_category,
    lga: state?.lga,
    state: state?.state
  }), [
    state.vehicleType,
    state.address,
    state.productName,
    state.front_view,
    state.description,
    state.category,
    state.sub_category,state.price,
    state.quantity,
    state.customer_code,
    state.lat,
    state.long,
    state.userId,
    state.merchant_first_name,
    state.merchant_last_name,
    state.merchant_phone_number,
    state.merchant_email,
    state.neighborhood,
    state.locality,
    state.merchant_station_id,
    state.weight,
    state.gift_wrapping,
    state.color,
    state.discounted_price,
    state.gender,
    state.get_category,
    state.left_view,
    state.lga,
    state.productImage,
    state.promo_name, 
    state.rear_view, 
    state.right_view, 
    state.sizes, 
    state.state, 
    state.sub_category_sizes, 
    state.tag,
    state.video_url
  ]);

  const checkValues = useMemo(() => ({
    vehicleType: state?.vehicleType,
    address: state?.address,
    productName: state?.productName,
    front_view: state?.front_view,
    description: state?.description,
    category: state?.category,
    sub_category: state?.sub_category,
    price: state?.price,
    quantity: state?.quantity,
    customer_code: state?.customer_code,
    lat: state?.lat,
    long: state?.long,
    userId: state?.userId,
    merchant_first_name: state?.merchant_first_name,
    merchant_last_name: state?.merchant_last_name,
    merchant_phone_number: state?.merchant_phone_number,
    merchant_email: state?.merchant_email,
    neighborhood: state?.neighborhood,
    locality: state?.locality,
    merchant_station_id: state?.merchant_station_id,
    weight: state?.weight

  }), [
        state.vehicleType,
        state.address,
        state.productName,
        state.front_view,
        state.description,
        state.category,
        state.sub_category,state.price,
        state.quantity,
        state.customer_code,
        state.lat,
        state.long,
        state.userId,
        state.merchant_first_name,
        state.merchant_last_name,
        state.merchant_phone_number,
        state.merchant_email,
        state.neighborhood,
        state.locality,
        state.merchant_station_id,
        state.weight
  ]);

const validateData = useCallback(() => {
  const newErrors = {};
  
  Object.keys(checkValues).forEach((key) => {
    if (!checkValues[key]) {
      // Convert camelCase or snake_case to readable format
      const readableKey = key
        .replace(/([a-z])([A-Z])/g, '$1 $2')  // Replace camelCase with spaces
        .replace(/_/g, ' ')                   // Replace underscores with spaces
        .toLowerCase();                       // Convert to lowercase

      newErrors[key] = `${readableKey} is required`;
    }
  });
  
  return newErrors;
}, [checkValues]);
    
    const handleSaveProduct = useCallback(() => {
      const newErrors = validateData();
      setSaveProductErrors(newErrors);
    
      // Clear errors if validation passes
      if (Object.keys(newErrors).length === 0) {
        setSaveProductErrors({});
      }
    
      // Check for discount error
      if (!isNaN(state.price) && state.discounted_price >= state.price) {
        discountErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    
      // Check for pickup address error
      if (checkPickUp === undefined) {
        setPickupAddressError("This address is not within GIGL pickup locations, please use an alternative address or use the GIGL Pickup location closest to you");
        pickupErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    
      if (Object.keys(newErrors).length > 0) {
        errorRef.current?.scrollIntoView({ behavior: 'smooth' });
      } else {
        uploadDispatch({ type: actionTypes.ADD_PRODUCT, payload: dataToSubmit });
        navigate('products');
      }
    }, [navigate, uploadDispatch, pickupErrorRef, errorRef, checkPickUp, setSaveProductErrors, state.price, state.discounted_price, discountErrorRef, dataToSubmit, setPickupAddressError, validateData]);
    
    
    const handleEditSave = useCallback(() => {
      const updatedItems = [...state.batch_upload_items];
      updatedItems[editIndex] = { ...state, ...dataToSubmit };
    
      const newErrors = validateData();
      setSaveProductErrors(newErrors);
    
      // Clear errors if validation passes
      if (Object.keys(newErrors).length === 0) {
        setSaveProductErrors({});
      }
    
      // Check for discount error
      if (!isNaN(state.price) && state.discounted_price >= state.price) {
        discountErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    
      // Check for pickup address error
      if (checkPickUp === undefined) {
        setPickupAddressError("This address is not within GIGL pickup locations, please use an alternative address or use the GIGL Pickup location closest to you");
        pickupErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    
      if (Object.keys(newErrors).length > 0) {
        errorRef.current?.scrollIntoView({ behavior: 'smooth' });
      } else {
        setItem(updatedItems);
        uploadDispatch({ type: actionTypes.SET_PRODUCT, payload: updatedItems });
        navigate('products');
      }
    }, [navigate, uploadDispatch, setItem, errorRef, pickupErrorRef, checkPickUp, discountErrorRef, editIndex, setPickupAddressError, setSaveProductErrors, state, validateData, dataToSubmit]);
    
    
    const handleSave = useCallback(() => {
      if (editIndex !== null) {
        handleEditSave();
      } else {
        handleSaveProduct();
      }
    }, [editIndex, handleEditSave, handleSaveProduct]);

  const handleGoTo = (action) => {
       if(action === "next" && goTo < 2){
          setGoTo(2)
       }else if(action === "prev" && goTo > 1){
          setGoTo(1)
       }
       else {
         handleSave();
       }
  }

  useEffect(() => {
    const newErrors = validateData();
  
    // Clear errors if validation passes
    if (Object.keys(newErrors).length === 0) {
      setSaveProductErrors({});
    }
  }, [setSaveProductErrors, validateData])

  return (
    <div className='d-none d-md-block d-lg-none tablet-upload-section'>
         <div className='d-flex flex-column gap-4 align-items-center py-4'>
             <div>
                  {
                     goTo === 1 && <div className="tablet-upload-media d-flex flex-column gap-2 ">
                        <UploadImageForm />
                        <ImagesVideosUpload />
                      </div>
                  }
                  {
                     goTo === 2 &&    <div className='d-flex flex-column  web-product-description gap-2'>
                        <UploadDescriptionForm />
                        <div className="d-flex justify-content-between">
                            <UploadForm />
                            <DescriptionForm />
                        </div>
                    </div>
                  }
             </div>
             <div className='d-flex align-items-center gap-2'>
                  
                 <button onClick={() => handleGoTo("prev")} disabled={goTo === 1} className={goTo === 1 ? "disable-goto goto-btn" : "goto-btn"}><ChevronLeft /> Prev</button>
                 <button onClick={() => handleGoTo("next")} className={"goto-btn"}>Next <ChevronRight /></button>
             </div>
         </div>
    </div>
  )
}

export default TabletImageUploadSection