import React from 'react'
import FadeAnimatedDiv from './FadeAnimateDiv'
import { Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
// import SuccessCard from './SuccessCard';


const UploadSuccess = () => {
    const navigate = useNavigate()
  return (
    <article className='upload-success-section'>
        <section className='upload-notes-section'>
            <div>
                <FadeAnimatedDiv>
                    <div className='success-check'>
                        <div className='check-mark'>
                            <Check />
                        </div>
                    </div>
                </FadeAnimatedDiv>
            </div>
            <div className='success-text-sec'>
                <h3 className='text-center'>Products Upload Successful</h3>
                <p className='text-center'> 
                   Your new product is being reviewed and will be visible to buyers within 24 hours. You will receive a notification when the upload is successful.
                </p>
            </div>
            <div>
                <button type="button" className='go-home' onClick={() => navigate("/merchant/store")}>Go Home</button>
            </div>
        </section>
        {/* <section>
             <SuccessCard />
        </section> */}
    </article>
  )
}

export default UploadSuccess
