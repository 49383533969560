import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";

export const verifyCreatedOrderApi = createApi({
    reducerPath: 'verifyCreatedOrderApi',
    baseQuery: fetchBaseQuery({baseUrl:`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION,process.env.REACT_APP_ORDER_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token
    
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }}),
    tagTypes: ['VerifyCreatedOrder'],
    endpoints: (builder) => ({
        reinitiateTransaction: builder.query({
            query: (data)=>({
                url: `/transaction/initiate/v1`,
                method: 'POST',
                body: data
            }), 
            invalidatesTags: ['VerifyCreatedOrder']
        }),
        reverifyOrder: builder.mutation({
            query: (data)=>({
                url: `/transactions/verify/v1`,
                method: 'POST',
                body: data
            }), 
            invalidatesTags: ['VerifyCreatedOrder']
        })
    })
})


export const {useReinitiateTransactionQuery, useReverifyOrderMutation} = verifyCreatedOrderApi