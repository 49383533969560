import {createSlice} from "@reduxjs/toolkit";
import CryptoJS from 'crypto-js';

const initialState = {
     cartId: ""
}
export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
         setCartId: (state, action) => {
              const {payload} = action
              let data = {cartId: payload?.cartId};
              state.cartId = payload?.cartId;
             let encryptJson = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SKEY).toString()
             let encryptedData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptJson))
             localStorage.setItem("basket", encryptedData);
         },
         removeBasket: (state) => {
             localStorage.removeItem("basket");
             state.cartId = "";
         }

    }
});

export const {setCartId, removeBasket} = cartSlice.actions;
export default cartSlice.reducer