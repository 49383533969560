import React, {useState, useContext, useCallback} from 'react';
import {IoPlay} from "react-icons/io5";
import { FaShareAlt } from 'react-icons/fa';
import { AppContext } from '../../../AppContexts/AppContext';

const VideoPlayer = ({videoUrl,showVideoModal, videoRef}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const {shareOnSocialMedia} = useContext(AppContext)
    const playOrPause = useCallback(() => {
        if(videoRef.current.paused || videoRef.current.ended){
            videoRef.current.play()
        }else {
            videoRef.current.pause()
        }
    },[videoRef])

   const onPlay = () => setIsPlaying(true)
   const onPause = () => setIsPlaying(false)
    return (
        <div className={showVideoModal ? 'video-wrapper' : "video-wrapper hide-player"}>
             <video src={videoUrl} className="video-play" ref={videoRef} onPlay={onPlay} onPause={onPause} onClick={playOrPause}/>
             <div className="controls" onClick={playOrPause}><IoPlay className={`video-controls clickable ${isPlaying ? "control-hidden" : "control-shown"}`}/></div>
             <div className="share-video" onClick={() => shareOnSocialMedia(videoUrl, "Click to watch product video")}><FaShareAlt /></div>
        </div>
      )
}

export default VideoPlayer