export const UploadSettings = {
    slidesPerView: 1,

    breakpoints: {
        280: {
            slidesPerView: 1
        },
        360: {
            slidesPerView: 3,
            spaceBetween:15
        },
        414: {
            slidesPerView: 3,
            spaceBetween:0
        },
        768: {
            slidesPerView: 5,
            
        },
        
        1024: {
            slidesPerView: 7,
           
  
        },
        1280: {
            slidesPerView: 9,
            // spaceBetween: 10,
  
        },
        
     
     
    }
}