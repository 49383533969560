import { GET_STORE_FAIL, GET_STORE_REQUEST, GET_STORE_SUCCESS } from "../constants/constants";
import { getUrl } from "../helpers/getUrl";

export function GetStore (slug) { 
  return async (dispatch) => {
    dispatch({type: GET_STORE_REQUEST});
    try {
      const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION,process.env.REACT_APP_PRODUCT_BASE_URL)}/store/details/${slug}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            ACCEPT: "application/json",
        }
      })
       const data = await res.json();
       if(data.error){
           return dispatch({type: GET_STORE_FAIL, payload: data.message})
       }

       dispatch({type: GET_STORE_SUCCESS, payload: data})    
    } catch (error) {
     const message =
     error.response && error.response.data
       ? error.response.data.message
       : error.message;
       dispatch({type: GET_STORE_FAIL, payload: message})
    }
 }
}