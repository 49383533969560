import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Plus } from 'lucide-react';
import { MdLinkedCamera } from "react-icons/md";
import MoonLoader from 'react-spinners/MoonLoader';
import {
  useUploadFrontViewImageMutation,
  useUploadLeftViewImageMutation,
  useUploadRearViewImageMutation,
  useUploadRightViewImageMutation,
  useUploadVideoMutation
} from '../../../../Actions/BulkUpload/BulkUpload';
import { AppContext } from '../../../../AppContexts/AppContext';
import { actionTypes } from '../../../../AppContexts/constants';

const override = {
  margin: "0",
  borderColor: "none",
  height: "50px",
  width: "100%",
  alignItems: "center",
  justifyContent: "center"
};

const ImagesVideosUpload = () => {
  const { state, uploadDispatch, editIndex} = useContext(AppContext);
  const [uploadVideo, { data: videoData, isSuccess: isVideoSuccess, isError: isVideoError, error: videoError, isLoading: isLoadingVideo, reset: resetVideo }] = useUploadVideoMutation();
  const [uploadFrontViewImage, { data: frontViewData, isSuccess: isFrontViewSuccess, isError: isFrontViewError, error: frontViewError, isLoading: isLoadingFrontView, reset: resetFrontview }] = useUploadFrontViewImageMutation();
  const [uploadRightViewImage, { data: rightViewData, isSuccess: isRightViewSuccess, isError: isRightViewError, error: rightViewError, isLoading: isLoadingRightView, reset: resetRightview }] = useUploadRightViewImageMutation();
  const [uploadLeftViewImage, { data: leftViewData, isSuccess: isLeftViewSuccess, isError: isLeftViewError, error: leftViewError, isLoading: isLoadingLeftView, reset: resetLeftview }] = useUploadLeftViewImageMutation();
  const [uploadRearViewImage, { data: rearViewData, isSuccess: isRearViewSuccess, isError: isRearViewError, error: rearViewError, isLoading: isLoadingRearView, reset: resetRearview }] = useUploadRearViewImageMutation();
  const {prefilledData} = state;


  const handleFileChange = async (e, uploadFunc, type) => {
    const file = e.target.files[0];
    if (file) {
      const error = await validateFileSize(file, type);
      if (error) {
        toast.error(error);
        return;
      }
      try {
        await uploadFunc(file);
      } catch (err) {
        console.error("File upload failed:", err);
      }
    }
  };


  
  

  const handleFrontViewReset = () => {
    resetFrontview();
    uploadDispatch({ type: actionTypes.SET_FRONTVIEW_IMAGE, payload: "" });
  };

  const handleLeftViewReset = () => {
    resetLeftview();
    uploadDispatch({ type: actionTypes.SET_LEFTVIEW_IMAGE, payload: "" });
  };

  const handleRightViewReset = () => {
    resetRightview();
    uploadDispatch({ type: actionTypes.SET_RIGHTVIEW_IMAGE, payload: "" });
  };

  const handleRearViewReset = () => {
    resetRearview();
    uploadDispatch({ type: actionTypes.SET_REARVIEW_IMAGE, payload: "" });
  };
  const handleVideoReset = () => {
    resetVideo();
    uploadDispatch({ type: actionTypes.SET_VIDEO, payload: "" });
  };
  

  useEffect(() => {
    if(isFrontViewSuccess) {
      uploadDispatch({ type: actionTypes.SET_FRONTVIEW_IMAGE, payload: frontViewData?.url });
    }
  }, [isFrontViewSuccess, frontViewData, uploadDispatch]);
  
  useEffect(() => {
    if(isLeftViewSuccess) {
      uploadDispatch({ type: actionTypes.SET_LEFTVIEW_IMAGE, payload: leftViewData?.url });
    }
  }, [isLeftViewSuccess, leftViewData, uploadDispatch]);
  
  useEffect(() => {
    if(isRightViewSuccess) {
      uploadDispatch({ type: actionTypes.SET_RIGHTVIEW_IMAGE, payload: rightViewData?.url });
    }
  }, [isRightViewSuccess, rightViewData, uploadDispatch]);
  
  useEffect(() => {
    if(isRearViewSuccess) {
      uploadDispatch({ type: actionTypes.SET_REARVIEW_IMAGE, payload: rearViewData?.url });
    }
  }, [isRearViewSuccess, rearViewData, uploadDispatch]);
  
  useEffect(() => {
    if(isVideoSuccess) {
      uploadDispatch({ type: actionTypes.SET_VIDEO, payload: videoData?.url });
    }
  }, [isVideoSuccess, videoData, uploadDispatch]);
  

  useEffect(() => {
    if(isFrontViewError) {
      const errorMessage = frontViewError?.data ? frontViewError.data.message : frontViewError?.error;
      toast.error(`Front View Upload Error: ${errorMessage}`);
    }
    if(isLeftViewError) {
      const errorMessage = leftViewError?.data ? leftViewError.data.message : leftViewError?.error;
      toast.error(`Left View Upload Error: ${errorMessage}`);
    }
    if(isRightViewError) {
      const errorMessage = rightViewError?.data ? rightViewError.data.message : rightViewError?.error;
      toast.error(`Right View Upload Error: ${errorMessage}`);
    }
    if(isRearViewError) {
      const errorMessage = rearViewError?.data ? rearViewError.data.message : rearViewError?.error;
      toast.error(`Rear View Upload Error: ${errorMessage}`);
    }
    if(isVideoError) {
      const errorMessage = videoError?.data ? videoError.data.message : videoError?.error;
      toast.error(`Video Upload Error: ${errorMessage}`);
    }
  }, [isFrontViewError, frontViewError, isLeftViewError, leftViewError, isRightViewError, rightViewError, isRearViewError, rearViewError, isVideoError, videoError]);

  useEffect(() => {
    if (editIndex !== null) {
      uploadDispatch({ type: actionTypes.SET_FRONTVIEW_IMAGE, payload: prefilledData?.front_view });
      uploadDispatch({ type: actionTypes.SET_LEFTVIEW_IMAGE, payload: prefilledData?.left_view });
      uploadDispatch({ type: actionTypes.SET_RIGHTVIEW_IMAGE, payload: prefilledData?.right_view });
      uploadDispatch({ type: actionTypes.SET_REARVIEW_IMAGE, payload: prefilledData?.rear_view });
      uploadDispatch({ type: actionTypes.SET_VIDEO, payload: prefilledData?.video_url });
    }
  }, [uploadDispatch, prefilledData.front_view, editIndex, prefilledData.left_view, prefilledData.right_view, prefilledData.rear_view,prefilledData.video_url]);

  return (
    <div className='d-flex flex-column gap-2'>
      <label className='front-img-vid cursor-pointer flex flex-column gap-1 justify-content-center' title='upload product front view' onClick={handleFrontViewReset}>
        {
          isLoadingFrontView ? (
            <div className="loader-container">
              <MoonLoader size={40} color={"#000000"} loading={isLoadingFrontView} cssOverride={override} />
            </div>
          ) : (
           state.front_view ? (
              <img src={state.front_view} alt="front view" style={{ width: "100%", height: "100%", borderRadius: "8px", objectFit: "cover"}} />
            ) : (
              <>
                <input disabled={isLoadingFrontView} type="file" id='front-img' accept="image/png, image/webp, image/jpeg" onChange={(e) => handleFileChange(e, uploadFrontViewImage, "image")} />
                <MdLinkedCamera size={24} color='#262641' />
                <span className='upload-label'>(.jpg, .png - Max 5MB)</span>
                <div className='upload__vid_img-btn'>Upload photo</div>
              </>
            )
          )
        }
      </label>
      <div className='other-imgs'>
        <label onClick={handleLeftViewReset} title="upload product left view">
          {
            isLoadingLeftView ? (
              <div className="loader-container">
                <MoonLoader size={40} color={"#000000"} loading={isLoadingLeftView} cssOverride={override} />
              </div>
            ) : (
              state.left_view ? (
                <img src={state.left_view} alt="left view" style={{ width: "100%", height: "100%", borderRadius: "8px", objectFit: "cover" }} />
              ) : (
                <>
                  <input disabled={isLoadingLeftView} type="file" id='left-img' accept="image/png, image/webp, image/jpeg" onChange={(e) => handleFileChange(e, uploadLeftViewImage, "image")}  />
                  <Plus size={24} color='#5C5C77' />
                </>
              )
            )
          }
        </label>
        <label onClick={handleRightViewReset} title="upload product right view">
          {
            isLoadingRightView ? (
              <div className="loader-container">
                <MoonLoader size={40} color={"#000000"} loading={isLoadingRightView} cssOverride={override} />
              </div>
            ) : (
              state.right_view ? (
                <img src={state.right_view} alt="right view" style={{ width: "100%", height: "100%", borderRadius: "8px",objectFit: "cover" }} />
              ) : (
                <>
                  <input disabled={isLoadingRightView} type="file" id='right-img' accept="image/png, image/webp, image/jpeg" onChange={(e) => handleFileChange(e, uploadRightViewImage, "image")}  />
                  <Plus size={24} color='#5C5C77' />
                </>
              )
            )
          }
        </label>
        <label onClick={handleRearViewReset} title="upload product rear view">
          {
            isLoadingRearView ? (
              <div className="loader-container">
                <MoonLoader size={40} color={"#000000"} loading={isLoadingRearView} cssOverride={override} />
              </div>
            ) : (
              state.rear_view ? (
                <img src={state.rear_view} alt="rear view" style={{ width: "100%", height: "100%", borderRadius: "8px", objectFit: "cover"}} />
              ) : (
                <>
                  <input disabled={isLoadingRearView} type="file" id='rear-img' accept="image/png, image/webp, image/jpeg" onChange={(e) => handleFileChange(e, uploadRearViewImage, "image")}  />
                  <Plus size={24} color='#5C5C77' />
                </>
              )
            )
          }
        </label>
      </div>
      <label className='video-upload-sec' title='upload product video'>
        {
          isLoadingVideo ? (
            <div className="loader-container">
              <MoonLoader size={40} color={"#000000"} loading={isLoadingVideo} cssOverride={override} />
            </div>
          ) : (
            state.video_url ? (
              <div style={{ width: "100%", height: "50px !important", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              <video 
                src={state.video_url} 
                controls 
                onContextMenu={(e) => e.preventDefault()}
                style={{ width: "100%", height: "auto", borderRadius: "8px" }}
              >
                Your browser does not support the video tag.
              </video>
              <div onClick={handleVideoReset}  style={newUpload}>
                    Upload a new video
              </div>
            </div>
            ) : (
              <>
                <input disabled={isLoadingVideo} type='file' id='video-upload' accept="video/*" onChange={(e) => handleFileChange(e, uploadVideo, "video")} />
                <div className='upload__vid_img-btn'>Upload Video</div>
                <span className='upload-label'>(.mp4, .mov - Max 10MB)</span>
              </>
            )
          )
        }
      </label>
    </div>
  );
};

const newUpload = { 
  fontSize: "12px", 
  width: "auto", 
  height: "40px", 
  paddingLeft: "1rem",
  paddingRight: "1rem",
  display: "flex", 
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #192150",
  backgroundColor: "#192150",
  borderRadius: "5px",
  color: "#FFFFFF",
  marginTop: "0.5rem",
} 
const validateFileSize = (file, type) => {
   // 10MB for video, 5MB for images
  const sizeLimit = type === 'video' ? 10 * 1024 * 1024 : 5 * 1024 * 1024;
  const sizeError = file.size > sizeLimit ? `The ${type} size exceeds the limit of ${sizeLimit / (1024 * 1024)}MB.` : null;

  if (sizeError) return sizeError;

  // If the file is a video, validate its duration
  if(type === 'video') {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src); 
        const duration = video.duration;
        if(duration > 30) {
          resolve('The video duration exceeds the limit of 30 seconds.');
        } else {
          resolve(null);
        }
      };

      video.onerror = () => {
        resolve('Failed to load video metadata for duration check.');
      };

      video.src = URL.createObjectURL(file);
    });
  }
  // Return null if there's no error and it's not a video
  return Promise.resolve(null);
};


export default ImagesVideosUpload;
