import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";

export const auctionApi = createApi({
        reducerPath: "auctionApi",
        baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION,process.env.REACT_APP_PRODUCT_BASE_URL)}`}),
        endpoints: (builder) => ({
            auctionProducts: builder.query({
                query: () => ({
                    url: `/product/aution`,
                    method: "get"
                })
            })
        })
});
export const {useAuctionProductsQuery} = auctionApi