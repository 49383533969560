import { CLEAR_SHIPMENT_PRICE, CLEAR_SHIPMENT_PRICE_REFETCH, GET_SHIPMENT_PRICE_BY_QUANTITY_FAIL, GET_SHIPMENT_PRICE_BY_QUANTITY_REQUEST, GET_SHIPMENT_PRICE_BY_QUANTITY_SUCCESS, SHIPMENT_PRICE_GET_FAIL, SHIPMENT_PRICE_GET_REQUEST, SHIPMENT_PRICE_GET_SUCCESS } from "../constants/constants";
import { getUrl } from "../helpers/getUrl";


export function ShipmentPrice(dataToSubmit) {
   return async (dispatch) => {
    try {
       dispatch({type: SHIPMENT_PRICE_GET_REQUEST});
       const res = await fetch(`${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION,process.env.REACT_APP_ORDER_BASE_URL)}/order/guest/shipping_price`, {
           method: "POST",
           headers: {
               "Content-Type": "application/json",
               ACCEPT: "application/json"
           },
           body: JSON.stringify(dataToSubmit)
       })
       const  data  = await res.json()
       if(data.results.Code === "404"){
          dispatch({type: SHIPMENT_PRICE_GET_FAIL, payload: data.results.ShortDescription})
       }else if(data.results.Code === "500"){
         dispatch({type: SHIPMENT_PRICE_GET_FAIL, payload: data.results.ShortDescription})
       }else {
        dispatch({type: SHIPMENT_PRICE_GET_SUCCESS, payload: data})    
       }
    
    } catch (error) {
     const message =
     error.response && error.response.data
       ? error.response.data.message
       : error.message;
       dispatch({type: SHIPMENT_PRICE_GET_FAIL, payload: message})
    }
 }
}


export function ShipmentPriceByQty({id, qty}) {
   return async (dispatch) => {
    try {
       dispatch({type: GET_SHIPMENT_PRICE_BY_QUANTITY_REQUEST});
       const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION,process.env.REACT_APP_PRODUCT_BASE_URL)}/product/price_by_quantity?productId=${id}&quantity=${qty}`, {
           method: "GET",
           headers: {
               "Content-Type": "application/json",
               ACCEPT: "application/json"
           },
       })
       const  data  = await res.json()
       if(data.results.Code === "404"){
          dispatch({type: GET_SHIPMENT_PRICE_BY_QUANTITY_FAIL, payload: data.results.ShortDescription})
       }else if(data.results.Code === "500"){
         dispatch({type: GET_SHIPMENT_PRICE_BY_QUANTITY_FAIL, payload: data.results.ShortDescription})
       }else {
        dispatch({type: GET_SHIPMENT_PRICE_BY_QUANTITY_SUCCESS, payload: data})    
       }
    
    } catch (error) {
     const message =
     error.response && error.response.data
       ? error.response.data.message
       : error.message;
       dispatch({type: SHIPMENT_PRICE_GET_FAIL, payload: message})
    }
 }
}

export const clearShippingFee = () => async (dispatch) => {
   dispatch({type: CLEAR_SHIPMENT_PRICE});
}

export const clearShippingReftched = () => async (dispatch) => {
    dispatch({type: CLEAR_SHIPMENT_PRICE_REFETCH})
}
