import React from 'react'

const UploadDescriptionForm = () => {
  return (
    <div className='d-flex align-items-center gap-2 image-form-up'>
        <div className="green-dot_big"/> 
        <span>Product Description</span>
    </div>
  )
}

export default UploadDescriptionForm