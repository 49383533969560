import React, {useCallback, useState, useContext, useEffect} from 'react';
import {useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { BsCaretLeftFill } from 'react-icons/bs';
import { MdOutlineRotateRight } from 'react-icons/md';
import "./promotion.css"
import Card from '../ProductCard/Card';
import { useGetPromotionQuery } from '../../../Actions/Promotions/Promotions';
import Loader from '../../../utils/Loader';
import { AppContext } from '../../../AppContexts/AppContext';
import {CiSearch} from "react-icons/ci";
const ListPromotions = () => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalDocs, setTotalDocs] = useState(0);
  const [search, setSearch] = useSearchParams({query: ""});
  const query = search.get("query")
  const {removeSlug,normalizeCase} = useContext(AppContext);
  const match = useMatch("/:firstRoute/:secondRoute/*");
  const { secondRoute } = match.params;
  const promoName = removeSlug(secondRoute)
  const {data, isLoading, isError} = useGetPromotionQuery({sales_name: promoName, page: 1, limit: itemsPerPage});
  const promoData = data?.results?.docs;
  const hasNextPage = data?.results?.hasNextPage;
  const nextPage = data?.results?.nextPage;
  const totalItems = data?.results?.totalDocs;
  const currentData = promoData && promoData.slice(0,itemsPerPage);
  const navigate = useNavigate();

  const searchResult = () => {
            if(query === "" || query === null){
                return currentData
            } 
          const newData = currentData && currentData.filter((product) => {
            const storeName = product?.store?.name;
            const alphaCode = product?.alphaCode;
            const locality = product?.locality;
            const productName = product?.productName;
            const neighborhood = product?.neighborhood; 
            const price = product?.price
          if(storeName?.toLowerCase()?.includes(query?.toLowerCase()) || alphaCode?.toLowerCase()?.includes(query?.toLowerCase()) || locality?.toLowerCase()?.includes(query?.toLowerCase()) || productName?.toLowerCase()?.includes(query?.toLowerCase()) || neighborhood?.toLowerCase()?.includes(query?.toLowerCase()) || price?.toString()?.includes(query?.toString())){
              return product
            }else {
              return null
            }
              
          });
          return newData;
  }
  const promotionDatas = searchResult();
    const loadMore = useCallback(() => {
      if((!hasNextPage && nextPage === null)){
        return;
      }else {
            setItemsPerPage(prev => prev + 10)
      }
  }, [hasNextPage, nextPage,setItemsPerPage]);
  useEffect(() => {
    if(totalItems > 0){
        setTotalDocs(totalItems)
    }
  }, [setTotalDocs, totalItems]);
  useEffect(() => {
    if(query === ""){
      setItemsPerPage(10)
    }else {
      setItemsPerPage(totalDocs)
    }
  }, [query,totalDocs]);

  return (
    <div className='promotions__details-page'>
         <div className='promotions__details-bg'>
            
            <div className='promotions__details-container'>
                <div className='back__to-page' onClick={() => {navigate(-1)}}><BsCaretLeftFill size={16}/>Back</div>
                 <div className='promotions__name-display'>
                     <div className='promotions-title' title={normalizeCase(removeSlug(promoName))}>{normalizeCase(removeSlug(promoName))}</div>
                     <div className='promotions-breadcrumb'>Promotions/<span>{normalizeCase(removeSlug(promoName))}</span></div>
                 </div>
            </div>
        </div>
      
       
             <section className="store_products category__product-cards">
              {
                  !isLoading &&  <div className='search__promotions-list'>
                  <form>
                      <CiSearch className='category__search-icon' size={20}/> 
                      <input type='text' placeholder='Search for products...'  className="category__details-search" onChange={(e) => setSearch(prev => {
                        prev.set("query", e.target.value);
                        return prev
                      })} value={query}/>
                  </form>
              </div> 
              }
                {
                  isLoading && <Loader />
                }
                {
                   promotionDatas && promotionDatas.length > 0 && !isLoading && <div className="row mt-4">
                   
                    {promotionDatas && promotionDatas?.map((product) => (
                        
                        <Card product={product} key={`${product._id}_promotion`}/>
                    ))}
                   
                 </div>
           
                }

                {
                  ((!isError && !isLoading && promotionDatas?.length < 1) || promotionDatas === undefined) &&  !isLoading && <div className='d-flex align-items-center justify-content-center mt-4 pt-4' style={{fontWeight:700, fontSize: "18px", color: "#333333"}}>No product found!</div>
                }   
              
           
              {
                    (!hasNextPage && nextPage === null) || promotionDatas?.length === 0 || promotionDatas === undefined  ? null : <button className='store__show-more' onClick={loadMore}><span className={`${isLoading ? "showmore-rotate" : ""}`}><MdOutlineRotateRight size={18} /></span>Show More</button>
              }
        </section>
 
    </div>
    
  )
}

export default ListPromotions