import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";

export const reviewsApi = createApi({
        reducerPath: "reviewsApi",
        baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION,process.env.REACT_APP_PRODUCT_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
        
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
              headers.set('authorization', `Bearer ${token}`)
            }
        
            return headers
          }}),
        tagTypes: ['reviews'],
        endpoints: (builder) => ({
            newReview: builder.mutation({
                query: (data) => ({
                    url: `/rating/new`,
                    method: "POST",
                    body: data
                }),
                invalidatesTags: ['reviews']
            })
        })
});

export const {useNewReviewMutation} = reviewsApi;
