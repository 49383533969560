import { useState} from 'react';
import StarRatings from 'react-star-ratings';
import moment from 'moment';
import ReviewPagination from './ReviewPagination';
import "./styles/review.css"

const Reviews = ({getReviews}) => {
    const [reviewData] = useState(getReviews);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(3) 
    const prevPage = (page) => {
        if(page?.length === 1 || currentPage === 1){
            return
        }else {
            setCurrentPage(prev => prev - 1)
        }
        
    }
    const nextPage = (page) => {
        if(page?.length === 1 || currentPage === page.length){
            return
        }else {
            setCurrentPage(next => next + 1)
        }
        
    }

    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = reviewData?.slice(firstPostIndex,lastPostIndex)

    return (
        <div className="review-section">
              <div className="desktop-review-container">
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12">
                        <div className="prod-desc-card">
                            <div className="review-header-section">
                                <div className="review-header-desc">
                                      <div>Review for this item</div> <div>{getReviews?.length > 0 ? getReviews?.length : "0"}</div>
                                 </div>
                            </div>
                            <>
                        {getReviews && getReviews.length > 0 ? currentPosts.map((review) => {
                          
                            const customerInitials = review && review.name.split(" ").map(word => word.at(0)).join("")
                            return <div className="desktop-star-ratings" key={review._id}>    
                           
                                <div className="rated-n-review">
                                    <div className="reviewer-name-desktop">
                                        <div className='initials'>{customerInitials?.toUpperCase()}</div>
                                        <div className='reviewer__name-section'>
                                           <div className="name">{review.name}</div>
                                           <div className="date">{moment(review?.updatedAt).format("MMM Do YYYY")}</div>  
                                        </div>
                                        
                                    </div>
                                    <div className='ratings__n_stars-section'>
                                        <div className="desktop-rated-stars">
                                        <StarRatings starDimension={"15px"} starSpacing={"1px"} numberOfStars={5} starRatedColor="#FFC107" rating={review.rating}/>
                                        </div>
                                        <div className="review-details-desktop">{review.message}</div>
                                    </div>
                                </div> 
                       </div>
                        }): <p className="review-notfound-desktop">No Review Yet</p>}  
                        {reviewData?.length > 0 &&
                            <div className="review-pagination">
                                <ReviewPagination totalPosts={reviewData.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} prevPage={prevPage} nextPage={nextPage} currentPage={currentPage}/>
                            </div>      
                        }
                      </>
                        </div>                
                        
                    </div>
                  </div>
              </div>
        </div>
    )
}


export default Reviews;