import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

// Initialize the S3 client
const s3Client = new S3Client({
  region: process.env.REACT_APP_S_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  },
});

// Function to handle file uploads
export const uploadToS3 = async (file) => {
  try {
    // Create a PutObjectCommand instance with the necessary parameters
    const key = `${Date.now()}_${file.name}`;
    const command = new PutObjectCommand({
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: key,
      Body: file,
      ContentType: file.type,
    });

    // Send the command to S3
    await s3Client.send(command);

    // Construct the file URL
    const fileUrl = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S_REGION}.amazonaws.com/${key}`;
    
    return { data: { url: fileUrl } };
  } catch (error) {
    return { error: error.message };
  }
};
