import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";

export const getPromotionApi = createApi({
        reducerPath: "getPromotionApi",
        baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION,process.env.REACT_APP_PRODUCT_BASE_URL)}`}),
        endpoints: (builder) => ({
            getPromotion: builder.query({
                query: ({sales_name,page,limit}) => ({
                    url: `product/sales/products?sales_name=${sales_name}&page=${page}&limit=${limit}`,
                    method: "get"
                })
            })
        })
});
export const listBannersApi = createApi({
    reducerPath: "listBannersApi",
    baseQuery: fetchBaseQuery({baseUrl:`${getUrl(process.env.REACT_APP_USER_BASE_URL_PRODUCTION,process.env.REACT_APP_USER_BASE_URL)}`}),
    endpoints: builder => ({
         listBanners: builder.query({
            query: () => ({
                url: "setting/banners/list",
                method: "get"
            })
         })
    })
});
export const {useGetPromotionQuery} = getPromotionApi;
export const {useListBannersQuery} = listBannersApi