import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";

export const etaApi = createApi({
        reducerPath: "etaApi",
        baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_USER_BASE_URL_PRODUCTION,process.env.REACT_APP_USER_BASE_URL)}`,  prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
              headers.set('authorization', `Bearer ${token}`)
            }
        
            return headers
          }}),
        tagTypes: ["Eta"],
        endpoints: (builder) => ({
            getEta: builder.mutation({
                query: body => ({
                    url: "/setting/eta/mobile",
                    method: "post",
                    body
                }),
                invalidatesTags: ["Eta"]
            })
        })
});
export const {useGetEtaMutation} = etaApi