// FadeAnimatedDiv.js
import React from 'react';
import './bulk.css'; // Import the CSS file

const FadeAnimatedDiv = ({ children }) => {
  return (
    <div className="outer-div">
      {children}
    </div>
  );
};

export default FadeAnimatedDiv;

