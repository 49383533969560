import { useRef, useEffect, useContext,useState, useCallback } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { DisplayContext } from "../../../../AppContexts/DisplayContext";
import { clearAlphaSearchState } from "../../../../Actions/AlphaSearchResult";
import cartImg from "../../../../assets/store/bag.svg";
import defaultStoreImg from "../../../../assets/store/default-store-img.jpg";
import { AiFillCaretDown } from "react-icons/ai";
import { AppContext } from "../../../../AppContexts/AppContext";
import { logout } from "../../../../Actions/Auth/authSlice";
import { useCartItemsQuery } from "../../../../Actions/Cart/Cart";
import "../../styles/home.css";
import "../store.css";


const GiglStoreNavbar = ({storeImg, storeName}) => {
     const {setProductId,handleMenuSwitch,handleDeliveryCheckout, handleMobileMenuSwitch, handleMobileDeliveryCheckout} = useContext(DisplayContext);
     const {setCurrentUrl} = useContext(AppContext)
     const {first_name, token} = useSelector(state => state.auth);
     const [profileModal, setProfileModal] = useState(false);
     const {cartId} = useSelector(state => state.cartId);
     const {data} = useCartItemsQuery(cartId);
    const cartLength = data && data?.results?.cart?.items.length
    const navRef = useRef(null);
    const headerRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const closeModals = () => {
      handleMenuSwitch(0);
      handleDeliveryCheckout(0);
      handleMobileMenuSwitch(0);
      handleMobileDeliveryCheckout(0);
    }

  const showNavbar = useCallback(() => {
        navRef.current?.classList?.toggle("responsive_nav");
        setProfileModal(false)
    }, [])
    function toLink(){
      setProfileModal(false); 
      setCurrentUrl(location.pathname)
    }
    const navigateToStore = () => {
      dispatch(clearAlphaSearchState())       
       navigate(`/stores/alpha/${storeName.trim().replace(/\s+/g, "_")}`,{ replace: true });
       setProductId("")
 }

    const activeLink = (link) => {
         return location.pathname === link ? "active-link" : ""
    }
    useEffect(() => {
        document.addEventListener("scroll", () => {
            if (window?.scrollY > 150 && headerRef && headerRef.current) {
              headerRef.current?.classList?.add('scrolled');
            } else {
              headerRef.current?.classList?.remove('scrolled');
            }
          })
    },[headerRef]);

    return (

            <div className="navigation-bar sticky-top" ref={headerRef}>
             
              
                    <div className="navigation-items">
                        <div className="store-logo" onClick={navigateToStore}>
                          <img src={storeImg || defaultStoreImg} alt="store-logo"/>
                        </div>
                        
                            <div className="home-navbar" ref={navRef}>
                                <NavLink to="/" className={() => activeLink("/")} onClick={showNavbar}>Go to Alpha</NavLink>
                                <div className="my-profile" onClick={() => setProfileModal(prev => !prev)}><AiFillCaretDown  className={profileModal ? "caret rotate-caret" : "caret"}/>{" "}<span>{first_name ? `Hi, ${first_name}` : "My Profile"}</span></div>
                                {
                                   profileModal && <div className="user-modal">
                                      {token ? <button onClick={() => {dispatch(logout()); setProfileModal(false)}}>Log out</button> : <Link to="/login" onClick={toLink}>Sign In</Link>}
                                   </div>
                                }
                                <div className="mobile-hide-icons">                                
                                    <NavLink to="/cart" onClick={() => {showNavbar(); closeModals()}}><div className="basket-badge"><img src={cartImg} alt=""/>{cartLength > 0 && <div className="item-count">{cartLength}</div>}</div></NavLink>
                                  <div className="nav-btn nav-close-btn" onClick={showNavbar}>
                                      <FaTimes />
                                  </div> 
                                </div>
                            </div>
                            <div className="cart-mobile-icons">
                              <NavLink to="/cart" onClick={closeModals} className={() => activeLink("/cart")}>
                                    <div className="basket-badge"><img src={cartImg} alt=""/>{cartLength > 0 && <div className="item-count">{cartLength}</div>}</div></NavLink>
                              <div className="nav-btn" onClick={showNavbar}>
                                <FaBars />
                              </div>
                            </div>
                            
                    </div>
                </div>
        
    )
}

export default GiglStoreNavbar