import footerImageDesktop from "../assets/Category-image/alpha-screen.svg"
import mobileBanner from "../assets/Banner/banner-mobile.png";
import tabletBanner from "../assets/Banner/banner-tablet.png";
import desktopBanner from "../assets/Banner/banner-desktop.png";

export const categoryImages = {
     groceriesImg: require("../assets/Category-image/Groceries.png"),
     automobileImg: require("../assets/Category-image/Automobile-Accessories.png"),
     babiesImg: require("../assets/Category-image/Kids-and-toys.png"),
     clothingImg: require("../assets/Category-image/Clothing.png"),
     shoesImg: require("../assets/Category-image/Shoes-and-Bags.png"),
     hairImg: require("../assets/Category-image/Hair-and-Wigs.png"),
     kitchenImg: require("../assets/Category-image/Home-and-Kitchen-Equipment.png"),
     electronicImg: require("../assets/Category-image/Electronics-and-Portable-Power.png"),
     accessoriesImg: require("../assets/Category-image/Accesories.png"),
     wellnessImg: require("../assets/Category-image/Health-and-Wellness.png"),
     phoneImg: require("../assets/Category-image/Phones-and-Gadgets.png"),
     computerImg: require("../assets/Category-image/Computer-and-Computer-Accessories.png"),
     footerImageDesktop: footerImageDesktop
}


export const bannerImages = (size) => {
    return size <= 560 ? mobileBanner : size <= 920 ? tabletBanner : desktopBanner
}


