import { useState,useEffect, useContext, useRef, useCallback } from "react";
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import moment from "moment";
import Modal from '../../Modal';
import { useDispatch, useSelector } from "react-redux";
import { Product } from "../../../../Actions/Product";
import {FaCheck,FaPlayCircle,FaCopy, FaShareAlt,FaShare} from "react-icons/fa";
import {IoLocationSharp} from "react-icons/io5";
import Reviews from "../../Reviews";
import { AppContext } from "../../../../AppContexts/AppContext";
import CheckoutModal from '../../MobileDevice/CheckoutModal';
import { DisplayContext } from "../../../../AppContexts/DisplayContext";
import {Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination  } from "swiper";
import { GetColorName } from 'hex-color-to-color-name';
import VideoModal from "../../VideoModal/VideoModal";
import VideoCard from "../../VideoModal/VideoCard";
import ShareOptionsModal from "../../Share/ShareOptionsModal";
import Loader from "../../../../utils/Loader";
import { clearAlphaSearchState } from "../../../../Actions/AlphaSearchResult";
import { GetStore } from "../../../../Actions/GetStore";
import { BsCaretLeftFill } from "react-icons/bs";
import { usePriceByQtyQuery } from "../../../../Actions/Price/PriceByQuantity";
import { toast } from "react-toastify";
import { useAddToCartMutation } from "../../../../Actions/Cart/Cart";
import { setCartId } from "../../../../Actions/Cart/cartSlice";
import { getUrl } from "../../../../helpers/getUrl";
import colorVariation from "../../../../utils/ColorVariations";
import borderVariation from "../../../../utils/BorderVariation";
import useWindowSize from "../../../../useWindowSize";
import GiglCard from "./GiglCard";
import "./../../styles/rating.css"
import PageWrap from "../../PageWrap/PageWrap";

const GiglProductDetails = () => { 
    const {pathname} = useLocation();
    const {width} = useWindowSize();
    const navigate = useNavigate(null);
    const [showVideoText, setShowVideoText] = useState(false);
    const [readMore, setReadMore] = useState(false)
    const {id} = useParams();
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showVideoModalMobile, setShowVideoModalMobile] = useState(false);
    const [validateError, setValidateError] = useState("");
    const { store:merchantStore } = useSelector(state => state.GetStoreReducer);
    const {cartId} = useSelector(state => state.cartId);
    const [addToCart, { isSuccess, isError, error, isLoading: isAddToCartLoading, data: cartData}] = useAddToCartMutation();
    const errorMessage = isError && error && error.hasOwnProperty("data") ? JSON.stringify(error.data) : JSON.stringify(error?.error);
    const ref = useRef();
    const myVideo = useRef()
    const dispatch = useDispatch();
    const {numberSeperator,copyToClipBoard,copySuccess,copied,setOpenShareModal,shareOnSocialMedia} = useContext(AppContext);
    const {check,qty, setQty, color, setColor, size, setSize, styleColor, setStyleColor, styleSize, setStyleSize, setSenderLat, setSenderLong, setSenderName, setSenderPhoneNumber, setSenderStationId, setSenderLocality,setSenderAddress, itemWeight, setItemWeight, setSenderNeighborhood, setItemName, setCustomerCode, setProductImg, setProductId, itemColor, setItemColor, handleMobileMenuSwitch, showMobileDeliveryOption,  setUserId, initialQty, setInitialQty,isSelectedColor, setIsSelectColor,isSelectedSize, setIsSelectSize,setSenderEmail,setStoreLink,borderColor, setBorderColor,isAlphaStore,setIsAlphaStore } = useContext(DisplayContext);
    const {product_loading, product_error, product} = useSelector(state => state.GetProductReducer);
    const originalUrl =  window.location.origin;
    const results = product && product.results;
    const prodId = results && results._id;
    const store = results && results.store;
    const storeName = store && store.name;
    const storeId = store && store.storeId
    const slug = store && store.slug;
    const storeLogo = store && store.picture;
    const alphaCode = results && results.alphaCode
    const productName = results && results.productName;
    const price = results && results.price;
    const isPromoting = results && results.is_promoting;
    const discountedPrice = results && results.discounted_price;
    const newColor = itemColor && itemColor.split(",");
    const merchantOtherProducts = merchantStore && merchantStore?.results && merchantStore?.results?.products && merchantStore?.results?.products.filter(({_id}) => _id !== id);
    const getOtherItems = merchantOtherProducts && merchantOtherProducts.length > 4 ? merchantOtherProducts?.slice(0,4) : merchantOtherProducts
    const itemSize = results && results.size;
    const sizeCheck = itemSize && itemSize[0] && itemSize[0].value;
    const gender = results && results.gender;
    const frontView = results && results.front_view;
    const rightView = results && results.right_view;
    const leftView = results && results.left_view;
    const rearView = results && results.rear_view;
    const quantity = results && results.quantity;
    const merchantAddress = results && results.address;
    const merchantCode = results && results.customer_code;
    const merchantFirstName = results && results.merchant_first_name
    const merchantLastName = results && results.merchant_last_name
    const merchantPhoneNumber = results && results.merchant_phone_number
    const merchantEmail = results && results.merchant_email
    const merchantStationId = results && results.merchant_station_id
    const merchantLocality = results && results.locality;
    const merchantNeighborhood = results && results.neighborhood;
    const merchantLat = results && results.lat
    const merchantLong = results && results.long
    const merchantItemWeight = results && results.weight && results.weight.replace(/[^\d.-]/g, '');
    const user_id = results && results.createdBy
    const availableColor = results && results.color
    const prodImage = results && results.front_view;
    const prodDescription = results && results.description;
    const getReviews = results && results.ratings_reviews;
    const vehicleType = results && results.vehicleType
    const giftWrapping = results && results.gift_wrapping;
    const videoUrl = results && results.watermark_video;
    const storeLink = `${originalUrl}/stores/${slug}`;
    const productLink = `${originalUrl}/product/alpha/${prodId}`;
    const storeLinkTo = isAlphaStore === "gigl sales" ? `/stores/alpha/${slug}` : `/stores/${slug}`;
    const backToStore = isAlphaStore === "gigl sales" ? `${originalUrl}/stores/alpha/${slug}` : `${originalUrl}/stores/${slug}`
    const category = results && results.category;
    const categoryColor = category && category.color;
    const categorySize = category && category.size;
    const [tab, setTab] = useState(0);
    const [productTab, setProductTab] = useState(0);
    const tabItem = ["Description", "Review(s)"]
    const images = [{id: 1, url: videoUrl}, {id: 2, url: frontView}, {id: 3, url: rightView}, {id: 4, url: leftView}, {id: 5, url: rearView}];
    const newImage = images.filter((image) => image.url !== "");
    const len = newImage.length - 1;
    const image = len === 0 ? newImage[0].url : newImage[tab].url;
    const getRatingStars = getReviews && getReviews.map(star => {
        return star.rating
    });
    const getAverageRatings = getRatingStars && getRatingStars.reduce((a,b) => {
       return  a + b/(getRatingStars.length)
    }, 0)
    const isProdutActive = (index) => {
        return productTab === index ? "product-tab active-prod-tab" : "product-tab"
    }
    const isActive = (index) => {
        return tab === index ? "image-thumbnails active" : "image-thumbnails rounded"
    }
    const isActiveSize = index => {
         return styleSize === index && isSelectedSize ? "selected-active-size-btn" : "selected-size-btn"
    }

    const isActiveColor = index => {
        return styleColor ===  index && isSelectedColor ? "show-active-color" : "hide-active-color"
   }
    const {data} = usePriceByQtyQuery({id, qty});
    const addToCartData = {
         cartId: cartId || "",
         category,
         color: color || "",
         customer_code: getUrl("ACC001164", "IND265936"),
         alphaCode,
         front_view: frontView,
         gender: gender || "",
         gift_wrapping: false,
         lat: merchantLat,
         locality: merchantLocality,
         long: merchantLong,
         merchant_email:merchantEmail,
         merchant_first_name: merchantFirstName,
         merchant_id: user_id,
         merchant_last_name: merchantLastName,
         merchant_phone_number: merchantPhoneNumber,
         merchant_station_id: merchantStationId,
         vehicleType:  vehicleType?.toUpperCase() || "BIKE",
         product_description: prodDescription,
         quantity_in_stock: quantity,
         stock_message: quantity < 1 ? "This product is out of stock" : "Product is in stock",
         price : isPromoting ? discountedPrice : price,
         product_address: merchantAddress,
         productId: prodId,
         productName: productName,
         quantity: qty,
         size: size ? {size,quantity: qty } : {},
         store_id: storeId,
         weight: itemWeight || "0.5"
      }
const handleAddToCart = async () => {
     if(merchantFirstName && merchantLastName && prodId && merchantAddress && user_id && price && merchantLat && merchantLong && merchantStationId && qty && storeId && productName){
        await addToCart(addToCartData)
     }
}

const validateOrderCriteria = () => {
    if(itemSize.length > 0 && itemSize[0].value !== "" && categorySize && size === ""){
       setValidateError("Please choose a size.*");
    }else if(categorySize && itemSize.length > 0 && color === ""){
       setValidateError("Please select a color.*");
    }else if(qty === 0){
       setValidateError("select item quantity.*");
    }else if(availableColor && color === ""  && !validateOrderCriteria){
         setValidateError("Please choose a color.*");
    }else {
        handleAddToCart()
    }
     
}

const validateShipNowCriteria = () => {
    if(itemSize.length > 0 && itemSize[0].value !== "" && categorySize && size === ""){
       setValidateError("Please choose a size.*");
    }else if(categorySize && itemSize.length > 0 && color === ""){
       setValidateError("Please select a color.*");
    }else if(qty === 0){
       setValidateError("select item quantity.*");
    }else if(availableColor && color === ""){
         setValidateError("Please choose a color.*");
    }else {
      navigate("/cart")
    }
     
}

const handleValidateError = useCallback(() => {
    if(validateError){
        setValidateError("")
    }

}, [validateError]);
useEffect(() => {
    if(prodDescription?.length > 55){
        setReadMore(true)
    }else {
        setReadMore(false)
    }
}, [prodDescription])

    useEffect(() => {
        if(id){
            dispatch(Product(id))
        }
     
    }, [dispatch, id]);
    useEffect(() => {
        setStoreLink(storeLinkTo)
    }, [storeLinkTo, setStoreLink])

    useEffect(() => {
        dispatch(GetStore(slug))
   }, [dispatch, slug]);
    useEffect(() => {
        setItemName(productName);
        setSenderAddress(merchantAddress)
        setCustomerCode(merchantCode)
        setSenderName(`${merchantFirstName} ${merchantLastName}`)
        setSenderLat(merchantLat);
        setSenderLong(merchantLong);
        setSenderNeighborhood(merchantNeighborhood);
        setSenderPhoneNumber(merchantPhoneNumber);
        setSenderLocality(merchantLocality)
        setItemWeight(merchantItemWeight)
        setSenderStationId(merchantStationId)
        setProductImg(prodImage)
        setProductId(prodId);
        setUserId(user_id);
        setInitialQty(quantity);
        setSenderEmail(merchantEmail)
       
    }, [merchantLastName, merchantLat, merchantLocality, merchantLong, merchantNeighborhood, merchantPhoneNumber, merchantStationId, merchantFirstName, merchantAddress, merchantCode, productName, setCustomerCode, setSenderAddress, setItemName, setSenderName, setSenderLat, setSenderLong, setSenderNeighborhood, setSenderPhoneNumber, setSenderLocality, setItemWeight, setSenderStationId, merchantItemWeight, setProductImg, setProductId, prodImage, prodId, setUserId, setInitialQty, user_id, initialQty, quantity, setSenderEmail, merchantEmail]);
   const mouseOverEffect = () => {
        ref.current.style.width = ref.current.clientWidth + 152 + "px";
        ref.current.style.backgroundColor = "#72FFC9";
        ref.current.style.color = "#FFFFFF";
        ref.current.style.borderRadius = "12px"
        ref.current.style.padding = "4px 5px"
   }
   const mouseLeaveEffect = () => {
      ref.current.style.width = "48px";
      ref.current.style.backgroundColor = "";
      ref.current.style.padding = "0px"
  }
    useEffect(() => {
        if(len === 0){
            return;
        }
       const interval = setInterval(() => {
             setTab(tab === len ? 0 : tab + 1)
        }, 4000);
        return () => clearInterval(interval)
    }, [tab, len]);

    useEffect(() => {
        dispatch(clearAlphaSearchState())
     },[dispatch]);
   useEffect(() => {
      if(isSuccess){
         toast.success("Item has been added to cart", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
         })
      }
   }, [isSuccess]);
   useEffect(() => {
      if(isSuccess){
         dispatch(setCartId({cartId: cartData?.results?._id}))
      }
   }, [isSuccess,cartData?.results?._id, dispatch])
    useEffect(() => {
        if(isError){
            if(error.hasOwnProperty("data")){
                toast.info(JSON.parse(errorMessage)["message"], {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                 })
            }else {
                toast.info(JSON.parse(errorMessage), {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                 }) 
            }
        }
    }, [isError, error, errorMessage]);
    useEffect(() => {
        if((pathname.includes("product/alpha"))){
            setIsAlphaStore("gigl sales") 
        }
     
    }, [setIsAlphaStore, pathname]);
     return (
        <PageWrap>
            <div className="product__detail-container">
            <div onClick={() => navigate(-1)} className='back-btn'>
                    <BsCaretLeftFill /> <span>Back</span>
                </div>
            
                <>
                    {
                            product_error ?
                            <div className="error_detail_page">
                                <div className="container alert alert-danger" role="alert">
                                    {product_error}
                                </div> 
                            </div>
                            :
                        <div>
                        <div className="product-card" id="top">   
                        <div>
                            {
                                
                                product_loading ? (<Loader />)
                            :
                            <>
                            
                                <div className="mobile-display">
                                    <div className={showMobileDeliveryOption === 2  || (showMobileDeliveryOption !== 0 && showMobileDeliveryOption !== 1) || check === "door-delivery-mobile" || check === "pickup-station-mobile" ? "d-none": "d-block"}>
                                        <Swiper
                                                modules={[Pagination, Autoplay]}
                                                spaceBetween={0}
                                                slidesPerView={1}
                                                pagination={{ clickable: true }}
                                                autoplay={videoUrl && videoUrl ? {
                                                    delay: 40000,
                                                    disableOnInteraction: true
                                                }: {
                                                    delay: 4000,
                                                    disableOnInteraction: true
                                                }}
                                                
                                                className="image-slider"
                                    >
                                            {
                                                newImage && newImage.map((image, index) => (
                                                
                                                    <SwiperSlide key={index + image.id} className="current-image">
                                                        {image && image.url && (image.url?.endsWith(".jpg") || image.url?.endsWith(".jpeg") || image.url?.includes(".png") || image.url?.endsWith(".gif") || image.url?.endsWith(".svg") ) ?  <img src={image.url} alt={productName}/> :
                                                        <video ref={myVideo} className="video-image" autoPlay={!image.url?.endsWith(".jpg") || !image.url?.endsWith(".jpeg") || !image.url?.includes(".png") || !image.url?.endsWith(".gif") || !image.url?.endsWith(".svg")} muted>
                                                            <source src={image.url} muted/>
                                                        </video>
                                                        
                                                        }
                                                        </SwiperSlide>
                                                
                                                ))
                                            } 
                                    </Swiper>
                                
                                    <div className="mobile-page-content">
                                        <div className="mobile-player-sec">
                                        <>
                                            <div className="alpha-code" onClick={() => {copyToClipBoard(alphaCode);}}>Alpha Code: {alphaCode} <div className="copy-alpha-code-mobile">{copied ? <span className="copied-text">{copySuccess}</span> : <FaCopy />}</div></div>
                                        
                                        </>
                                        {videoUrl &&
                                        <div className="item-video-mobile" onClick={() => setShowVideoModalMobile(true)}><FaPlayCircle className="video-icon"/><span>Watch product video</span></div>
                                        }
                                        </div>
                                        <div className="out-of-stock">
                                    <div><h5 className="product-name-for-mobile">{productName}</h5></div> <div className="share-product" onClick={() => setOpenShareModal(true)}><FaShareAlt className="share-product-mobile"/></div>
                                    </div>
                                        <div className="location-address-section"><div><IoLocationSharp /></div><div>{merchantAddress}</div></div>
                                        <div className="price-rating-section">
                                            <div className="price-section-mobile">
                                                {
                                                    isPromoting && <h6 className="product-price-for-mobile" style={width < 600 &&  isPromoting ? {
                                                        fontSize: "18px !important"
                                                    } : {}}>&#8358; {discountedPrice !== null && numberSeperator(discountedPrice)}</h6>
                                                }
                                            
                                            <h6 style={width < 600 && isPromoting ? {
                                                        fontSize: "14px !important",
                                                        marginTop: "1.5px"
                                                    } : {}} className={`product-price-for-mobile ${isPromoting ? "discounted-price" : ""}`}>&#8358; {price !== null && numberSeperator(price)}</h6>
                                            </div>
                                            <div className="all-ratings">
                                                {getReviews?.length > 0 && <div className="ratings-space">
                                                <div><StarRatings starDimension={width < 600 && isPromoting ? "14px" : "20px"} starSpacing={"1px"} numberOfStars={5} starRatedColor="#FFC107" rating={getAverageRatings}/></div><div className="total-reviews">{getReviews?.length === 1 ? `${getReviews.length} review` : `${getReviews?.length} Reviews`}</div>
                                                </div> }

                                                {getReviews?.length === 0 &&  <div className="ratings-space">
                                                <div><StarRatings starDimension={width < 600 && isPromoting ? "14px" : "20px"} starSpacing={"1px"} numberOfStars={5} starRatedColor="#FFC107" rating={getAverageRatings}/></div><div className="total-reviews">0 Review</div>
                                                </div>}
                                                
                                            </div>
                                        </div>
                                        
                                        <div className={quantity === 0 ? "pay-button-on-mobile-device checkout-btn-disabled" : "pay-button-on-mobile-device"}>
                                            <button className="pay-button-on-mobile-device" onClick={(e) => {e.preventDefault(); handleMobileMenuSwitch(1)}} disabled={quantity === 0}>{quantity === 0  ? "Out of stock" : "Proceed"}</button>
                                        </div>
                                        
                                        
                                    </div>
                                
                                    <a href={backToStore} target="_blank" rel="noreferrer" style={{cursor: "pointer", textDecoration: "none"}}>
                                        <div className="mobile-device-store-name">
                                            <div className="mobile-display-store-image"><img src={storeLogo} alt={storeName} /></div>
                                            <div className="mobile-device-store">
                                                <div>Product store</div>
                                                <h5>{storeName}</h5>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="mobile-device-item-description">
                                        <div className="tab-item">
                                            {tabItem.map((item, index) => (
                                                    <div key={index} className={`${isProdutActive(index)}`} onClick={() => setProductTab(index)}>{item}</div>
                                            ))}
                                        </div>
                                        {productTab === 0 && <p className="product-description-det">{prodDescription}</p>}
                                        {
                                            productTab === 1 && <div>{ getReviews.length > 0 ? getReviews.map(review => (
                                                <div key={review._id} className="display-review-mb">
                                                    <div className="rating-n-name">
                                                        <div className="rating-date-n-name">
                                                            <span className="rate-name">{review.name}</span>
                                                            <span className="rated-on">{moment(review.createdAt).format("MMM DD, YYYY")}</span>
                                                        </div>
                                                        <div className="review-stars">
                                                        <StarRatings starDimension={"14px"} starSpacing={"1px"} numberOfStars={5} starRatedColor="#FFC107" rating={review.rating}/></div>
                                                </div>
                                                <p className="review-msg">{review.message}</p>
                                                </div>
                                            )) : <p className="no-reviews-yet">No review yet!</p>
                                            }
                                        </div>
                                        }
                                        
                                    </div>
                                    </div>
                                    <CheckoutModal itemSize={itemSize} isActiveSize={isActiveSize}  quantity={quantity} colorVariation={colorVariation} borderVariation={borderVariation} isActiveColor={isActiveColor} newColor={newColor} giftWrapping={giftWrapping} availableColor={availableColor} color={color} initialQty={initialQty} setInitialQty={setInitialQty} price={price} categoryColor={categoryColor} categorySize={categorySize} id={id} validateOrderCriteria={validateOrderCriteria} validateShipNowCriteria={validateShipNowCriteria} setValidateError={setValidateError} validateError={validateError} isPromoting={isPromoting} discountedPrice={discountedPrice} isAddToCartLoading={isAddToCartLoading}/>
                                    <VideoModal setShowVideoModal={setShowVideoModal} showVideoModal={showVideoModalMobile} >
                                        <VideoCard setShowVideoModal={setShowVideoModalMobile} showVideoModal={showVideoModalMobile} videoUrl={videoUrl}/>
                                    </VideoModal>     
                                    <ShareOptionsModal>
                                        <div className="share-modal-page">
                                            {videoUrl && <div className="share-video-option" onClick={() => {shareOnSocialMedia(videoUrl, "Click to watch product video"); setOpenShareModal(false)}}><div>Share product video</div><div><FaShare /></div></div>}
                                            <div className="share-video-option" onClick={() => {shareOnSocialMedia(storeLink, "Click to view more products on merchants store"); setOpenShareModal(false)}}><div>Share store link</div><div><FaShare /></div></div>
                                            <div className="share-video-option" onClick={() => {shareOnSocialMedia(productLink, "Click to purchase product"); setOpenShareModal(false)}}><div>Share product link</div><div><FaShare /></div></div>
                                        </div>
                                    </ShareOptionsModal>
                                
                                </div>
                                <div className="product-card-details desktop-display">        
                                        <div className="row">
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 product-img-desktop-slide">
                                            
                                                <div className="desktop__slider-section">
                                                    <div className="thumbnail-images-styles" style={{cursor: "pointer"}}>
                                                        {newImage.map((img, index) => (
                                                            <div key={index + img.id}>
                                                            {img && img.url && (img.url?.endsWith(".jpg") || img.url?.endsWith(".jpeg") || img.url?.includes(".png") || img.url?.includes(".svg") || img.url?.includes(".gif")) ? <img 
                                
                                                                src={img.url}
                                                                alt={productName}
                                                                className={`${isActive(index)}`}
                                                                style={{height: "65px", width: "20%"}}
                                                                onClick={() => setTab(index)}
                                                        /> :  <video
                                                        autoPlay
                                                        muted
                                                        className={`${isActive(index)}`}
                                                        style={{height: "65px", width: "20%"}}
                                                        onClick={() => setTab(index)}
                                                        >
                                                            <source src={img.url} />
                                                        </video>
                                                        
                                                        }
                                                        </div>
                                                        )
                                                        
                                                    )}
                                                        
                                                    </div>
                                                    <div className="card card-image">
                                                        {(image?.includes("jpeg") || image?.includes("jpg")) || image?.includes("png") || image?.includes("svg") || image?.includes("gif") ? <img src={image} alt={productName}/> : <video
                                                            muted
                                                            autoPlay
                                                            className="video-image-desktop"
                                                        >
                                                            <source src={image} />
                                                        </video>
                                                    }
                                                        
                                                    </div>
                                                </div>
                                                { !product_loading &&
                    
                                                <Reviews prodDescription={prodDescription} getReviews={getReviews} giftWrapping={giftWrapping}/>
                                                }
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 offset-md-1 desktop-prod-details-col">
                                        
                                        
                                                <p className="product-name">{productName}</p>
                                                    <div className="store-name-placeholder">
                                                        
                                                        <div className="reviews-n-store-name">
                                                        <a href={backToStore} rel="noreferrer" target="_blank" style={{cursor: "pointer", textDecoration: "none"}}>
                                                            <div className="store-img-n-name">
                                                                <div>
                                                                <img src={storeLogo} alt={storeName} />
                                                                </div>
                                                                <span className="store-desc">{storeName}</span>
                                                            </div>
                                                            </a>
                                                        </div>
                                            
                                            </div>
                                            <div> 
                                                
                                        
                                                <div className="alpha-code-desktop" onClick={() => {copyToClipBoard(alphaCode);}}><span>Alpha Code:</span> <span>{alphaCode}</span> <span className="copy-alpha-code">{copied ?  <span className="copied-text">{copySuccess}</span>   : <FaCopy />}</span></div> 
                                            </div>
                                            <div className="desktop__item-price">
                                                <div className="desktop-price">
                                                    {
                                                        isPromoting && <div className="price">&#8358; {discountedPrice !== null && numberSeperator(qty > 1 ? data?.results : discountedPrice)}</div>
                                                    }
                                                    <div className={`price ${isPromoting ? "desktop-discounted-price" : ""}`}>&#x20A6; {numberSeperator(qty > 1 && discountedPrice === 0 ? data?.results : price)}</div>
                                                </div>
                                                {
                                            quantity === 0 &&
                                                <div className="check-available"> 
                                                    <div className="text-danger" style={{textDecoration: "line-through"}}><strong>Out of Stock</strong></div></div>
                                            }
                                            </div>
                                            {
                                            quantity > 0 && <div className="qty-btn-section"><div className="add-remove-qty"><button className={qty <= 1 ? "btn-minus-desktop item-add-desktop-disabled" : "btn-minus-desktop"} onClick={() => {setQty(prev => prev - 1); setValidateError("")}} disabled={qty <= 1}>-</button><button className="btn-qty-desktop">{qty}</button><button className={qty === initialQty ? "btn-plus-desktop item-add-desktop-disabled" : "btn-plus-desktop"} onClick={() => {setQty(prev => prev + 1); setValidateError("")}} disabled={qty === initialQty}>+</button></div></div>
                                            }
                                            
                                        
                                                {itemSize && itemSize.length > 0 && itemSize[0].value !== "" && categorySize &&
                                                    <div className="available-size-desktop">
                                                        <div className= "sizes-select sizes-select-desktop">
                                                            {
                                                        categorySize &&  itemSize?.length > 0 && itemSize.map((size, index) => {
                                                                return size.value !== "" &&
                                                                <button key={size._id} className={isActiveSize(index)} onClick={() => {setSize(size.value); setStyleSize(index); setItemColor(size.color); setIsSelectSize(true); setInitialQty(size.quantity); setValidateError(""); setIsSelectColor(false); setQty(1); setColor(""); setBorderColor(null)}}>{size.value}</button>
                                                            })  
                                                            }
                                                        </div>
                                                    
                                                    </div>
                                                }
                                                {itemColor && itemSize?.length > 0 && isSelectedSize &&
                                                    <div className="prefered-color-desktop">
                                                        <div className="color-picker">
                                                            {
                                                            itemColor && newColor.map((color, index) => (
                                                                    <button key={color + `-${index}`} style={{backgroundColor: color}} className={`${borderColor === index ? borderVariation(color) : ""}`}  onClick={() => {setColor(color); setStyleColor(index); setValidateError(""); setIsSelectColor(true); setBorderColor(index)}} ><FaCheck className={`${colorVariation(color)}  ${isActiveColor(index)}`}/></button>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                            {
                                                availableColor && !sizeCheck && <div className="prefered-color-desktop">
                                                    <div className="color-picker">
                                                        {
                                                            availableColor.includes(",") ? availableColor.split(",").map((newColor, index) => (
                                                                <button className={`${borderColor === index ? borderVariation(newColor) : ""}`} key={newColor} style={{backgroundColor: newColor}}  onClick={() => {setColor(newColor); setStyleColor(index); setBorderColor(index); setIsSelectColor(true); setValidateError("")}} ><FaCheck className={`${colorVariation(newColor)}  ${isActiveColor(index)}`}/></button>
                                                            )) :
                                                            <button className={`${borderColor === 1 ? borderVariation(color) : ""}`} style={{backgroundColor: availableColor}}  onClick={() => {setColor(availableColor); setStyleColor(1); setIsSelectColor(true); setValidateError(""); setBorderColor(1)}} ><FaCheck className={`${colorVariation(color)} ${isActiveColor(1)}`}/></button>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {
                                            prodDescription && prodDescription.length <= 55 ? <div className="desktop__product-description">{ prodDescription}</div> :
                                                readMore ?
                                                <div className="desktop__product-description" id="prod_description">{`${prodDescription.slice(0, 56)}...`} <span className="description_readmore" onClick={() => setReadMore(false)}>Read more...</span></div> : <div className="desktop__product-description">{ prodDescription + "."} {" "}<span className="description_readmore" onClick={() => setReadMore(true)}>Read less...</span></div>
                                            }
                                    
                                            {validateError && <div className="text-danger">{validateError}</div>}
                                        
                                                
                                            <div className="cart__buynow-btn">
                                                <div className={quantity === 0 ||  isAddToCartLoading  ? "checkout-btn-desktop checkout-btn-disabled": "checkout-btn-desktop"} onClick={() =>validateOrderCriteria()}>
                                                    <button disabled={quantity === 0 || isAddToCartLoading}>
                                                        {isAddToCartLoading ? <div style={{width: "1rem !important", height: "1rem !important"}} className="spinner-border shipment-spinner" role="status"></div> : "Add to cart"}</button>
                                                </div>

                                                <div className={quantity === 0  ? "buynow-btn-desktop checkout-btn-disabled": "buynow-btn-desktop"} onClick={validateShipNowCriteria}>
                                                    <button disabled={quantity === 0}>Pay and ship now</button>
                                                </div>
                                                </div>
                                            <div className="player-n-payment">
                                                <div className="share-product-desktop" onClick={() => setOpenShareModal(true)}><FaShareAlt /> Share this product</div>
                                                    <div className={videoUrl ? "watch-prod-video" : "d-none"} onClick={() => setShowVideoModal(true)} ref={ref} onMouseEnter={(e) => {e.stopPropagation(); setShowVideoText(true); mouseOverEffect()}} onMouseLeave={() => {setShowVideoText(false); mouseLeaveEffect()}}>
                                                    <FaPlayCircle className={showVideoText ? "video-play-btn player_bg" : "video-play-btn"}/>{showVideoText && <span>Watch product video</span>}
                                                    </div>
                                            </div>
                                            <div className="color__variation">
                                                    <div className="item-model">
                                                        <div className="item">
                                                            <div className="variation-label">Model</div> <div>-</div>
                                                        </div>
                                                    </div> 
                                                    <div className="item-color">
                                                        <div className="item">
                                                            <div className="variation-label">Color</div> <div>{color ? GetColorName(color) : "-"}</div>
                                                        </div>
                                                    </div> 
                                                    <div className="item-location">
                                                        <div className="item">
                                                            <div className="variation-label">Product Location</div> <div>{merchantNeighborhood + ", " + merchantLocality}</div>
                                                        </div>
                                                    </div> 
                                            </div>
                                        </div>
                                    </div> 
                                    <ShareOptionsModal>
                                        <div className="share-modal-page">
                                            {videoUrl && <div className="share-video-option" onClick={() => {shareOnSocialMedia(videoUrl, "Click to watch product video"); setOpenShareModal(false)}}><div>Share product video</div><div><FaShare /></div></div>}
                                            <div className="share-video-option" onClick={() => {shareOnSocialMedia(storeLink, "Click to view more products on merchants store"); setOpenShareModal(false)}}><div>Share store link</div><div><FaShare /></div></div>
                                            <div className="share-video-option" onClick={() => {shareOnSocialMedia(productLink, "Click to purchase product"); setOpenShareModal(false)}}><div>Share product link</div><div><FaShare /></div></div>
                                        </div>
                                    </ShareOptionsModal>   

                                </div>
                            <Modal/> 
                            <VideoModal setShowVideoModal={setShowVideoModal} showVideoModal={showVideoModal}>
                                <VideoCard setShowVideoModal={setShowVideoModal} showVideoModal={showVideoModal} videoUrl={videoUrl}/>
                            </VideoModal>
                        
                    </>
                }
                        </div>
                        {
                            merchantOtherProducts && merchantOtherProducts.length > 0 && showMobileDeliveryOption < 1 && <div className="other__product-section">
                            <div className="other__products">
                                <div></div>
                                <div>Other products from this Store</div>
                            </div>
                            <section className="other__products-merchant">
                                <div className="row mt-4">
                                        
                                        {getOtherItems && getOtherItems.map((product) => (
                                        
                                            <GiglCard product={product} key={`${product._id}_product_details`} handleValidateError={handleValidateError}/>
                                        ))}
                                    
                                </div>
                            </section>
                            {merchantOtherProducts && merchantOtherProducts.length > 4 && <Link to={`/stores/alpha/${slug}`} className="related__product_view-more">View more</Link>}
                        </div>
                        }
                        
                        </div>
                    
                    </div>
                    }
                
                </>
            </div>
        </PageWrap>
     )
}
export default GiglProductDetails;