import {  combineReducers  } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { LoginReducer } from './../Reducer/LoginReducer';
import { GetProductReducer } from './../Reducer/GetProductReducer';
import { GetHomeDeliveryReducer } from './../Reducer/GetHomeDelivery';
import { LocalStationReducer } from './../Reducer/AgilityLocalStation';
import { PaymentMethodReducer } from './../Reducer/PaymentMethod';
import { GigPickUpReducer } from './../Reducer/GigPickupLocation';
import { ShipmentPriceReducer,ShipmentPriceByQty } from './../Reducer/ShipmentPrice';
import { InitiateTransactionReducer } from './../Reducer/InitiateTransaction';
import { GetServiceCenterDetailsReducer } from './../Reducer/GetServiceCenterDetails';
import { VerifyOrderReducer } from './../Reducer/VerifyOrder';
import { GetStoreReducer } from './../Reducer/GetStore';
import { CreateOrderReducer } from './../Reducer/CreateOrder';
import { RegisterGuestReducer } from './../Reducer/RegisterGuest';
import { TrackOrderReducer } from './../Reducer/TrackOrder';
import { WaitListCreate } from './../Reducer/WaitList';
import { RefundPayment,BankList } from './../Reducer/RefundPayment';
import { searchAlphaProducts } from './../Reducer/SearchAlphaProducts';
import { getEta } from './../Reducer/GetEta';
import { allCategories } from './../Reducer/CategoryReducer.js/Category';
import { getSubscription } from './../Reducer/Subscription/Subscription';
import { orderSearchReducer } from './../Reducer/SearchOrder';
import { authApi } from "../Actions/Auth/Auth";
import { cartApi } from "../Actions/Cart/Cart";
import authReducer from "../Actions/Auth/authSlice";
import cartReducer from "../Actions/Cart/cartSlice";
import { priceByQtyApi, shipmentPriceApi } from "../Actions/Price/PriceByQuantity";
import { etaApi } from "../Actions/MultipleEtas/MultipleEtas";
import { auctionApi } from "../Actions/Auction/Auction";
import { getPromotionApi, listBannersApi } from "../Actions/Promotions/Promotions";
import { searchAlphaApi } from "../Actions/Search/SearchAlpha";
import { contactApi } from "../Actions/Contact/Contact";
import { orderApi } from "../Actions/Account/Account";
import { reviewsApi } from "../Actions/Reviews/Reviews"; 
import { productApi } from "../Actions/Products/Product";
import { getGIGLocationApi, getMerchantStoreApi, listPromotionsApi, uploadApi } from "../Actions/BulkUpload/BulkUpload";
import { verifyCreatedOrderApi } from "../Actions/Verify/Verify";

const reducer = combineReducers({
     LoginReducer,
     GetProductReducer,
     GetHomeDeliveryReducer,
     LocalStationReducer, 
     PaymentMethodReducer,
     GigPickUpReducer,
     ShipmentPriceReducer,
     ShipmentPriceByQty,
     InitiateTransactionReducer,
     GetServiceCenterDetailsReducer,
     VerifyOrderReducer,
     CreateOrderReducer,
     GetStoreReducer, 
     RegisterGuestReducer,
     TrackOrderReducer,
     WaitListCreate,
     RefundPayment,
     BankList,
     searchAlphaProducts,
     orderSearchReducer,
     getEta,
     allCategories,
     getSubscription,
     auth: authReducer,
     cartId: cartReducer,
     [authApi.reducerPath]: authApi.reducer,
     [cartApi.reducerPath]: cartApi.reducer,
     [priceByQtyApi.reducerPath] : priceByQtyApi.reducer,
     [etaApi.reducerPath] : etaApi.reducer,
     [auctionApi.reducerPath]: auctionApi.reducer,
     [getPromotionApi.reducerPath] : getPromotionApi.reducer,
     [listBannersApi.reducerPath] : listBannersApi.reducer,
     [searchAlphaApi.reducerPath] : searchAlphaApi.reducer,
     [contactApi.reducerPath] : contactApi.reducer,
     [shipmentPriceApi.reducerPath] : shipmentPriceApi.reducer,
    [orderApi.reducerPath] : orderApi.reducer,
    [reviewsApi.reducerPath] : reviewsApi.reducer,
    [productApi.reducerPath] : productApi.reducer,
    [getMerchantStoreApi.reducerPath] : getMerchantStoreApi.reducer,
    [getGIGLocationApi.reducerPath] : getGIGLocationApi.reducer,
    [uploadApi.reducerPath] : uploadApi.reducer,
    [listPromotionsApi.reducerPath]: listPromotionsApi.reducer,
    [verifyCreatedOrderApi.reducerPath] : verifyCreatedOrderApi.reducer
});

const store = configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false
        }).concat(authApi.middleware, cartApi.middleware, priceByQtyApi.middleware, etaApi.middleware, auctionApi.middleware, getPromotionApi.middleware, listBannersApi.middleware, searchAlphaApi.middleware, contactApi.middleware, orderApi.middleware, reviewsApi.middleware, shipmentPriceApi.middleware, productApi.middleware, getGIGLocationApi.middleware, getMerchantStoreApi.middleware, uploadApi.middleware, listPromotionsApi.middleware, verifyCreatedOrderApi.middleware),
    }
);

export default store;
