import { PRODUCT_GET_CLEAR, PRODUCT_GET_FAIL, PRODUCT_GET_REQUEST, PRODUCT_GET_SUCCESS } from "../constants/constants";
import { getUrl } from "../helpers/getUrl";

export function Product(id, src) {
   return async (dispatch) => {
    dispatch({type: PRODUCT_GET_REQUEST});
    try {
       const res = await fetch(`${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION,process.env.REACT_APP_PRODUCT_BASE_URL)}/product/guest/${id}?src=${src}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            ACCEPT: "application/json",
        }
      })
       const data = await res.json();
       if(data.error){
          return dispatch({type: PRODUCT_GET_FAIL, payload: data.message})
       }
       dispatch({type: PRODUCT_GET_SUCCESS, payload: data})
        
    } catch (error) {
     const message =
     error.response && error.response.data
       ? error.response.data.message
       : error.message;
       dispatch({type: PRODUCT_GET_FAIL, payload: message})
    }
  }
 }


 export const clearGetProduct = () => async (dispatch) => {
   dispatch({type: PRODUCT_GET_CLEAR});
}