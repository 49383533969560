import { useLayoutEffect, useState } from "react";

const useWindowSize = () => {
        const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    
        useLayoutEffect(() => {
            const handleSize = () => {
                setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
                });
            };
            
            window.addEventListener('resize', handleSize);
            return () => {
            window.removeEventListener('resize', handleSize);
            };
        }, []);
  return windowSize;
}

export default useWindowSize