import { CLEAR_SHIPMENT_PRICE, CLEAR_SHIPMENT_PRICE_REFETCH, GET_SHIPMENT_PRICE_BY_QUANTITY_FAIL, GET_SHIPMENT_PRICE_BY_QUANTITY_REQUEST, GET_SHIPMENT_PRICE_BY_QUANTITY_SUCCESS, SHIPMENT_PRICE_GET_FAIL, SHIPMENT_PRICE_GET_REQUEST, SHIPMENT_PRICE_GET_SUCCESS } from "../constants/constants";
     const initialState = {
         shipment_price_loading : false,
         shipment_price_by_qty_loading: false,
         shipment_price: {},
         shipment_price_error: "",
         shipment_price_by_qty: {},
         shipment_price_by_qty_error: "",

     }
     
    export const ShipmentPriceReducer = (state = initialState, action) => {
    switch (action.type) {
      case SHIPMENT_PRICE_GET_REQUEST:
        return {
             ...state,
             shipment_price_loading : true 
        };
      case SHIPMENT_PRICE_GET_SUCCESS:
        return {
          ...state,
          shipment_price_loading: false,
          shipment_price: action.payload
        };
      case SHIPMENT_PRICE_GET_FAIL:
        return {
          ...state,
          shipment_price_loading: false,
          shipment_price_error: action.payload,
        };
      case CLEAR_SHIPMENT_PRICE:
        return {
             ...state,
             shipment_price_loading: false,
             shipment_price: {},
             shipment_price_error: ""
        }
      case CLEAR_SHIPMENT_PRICE_REFETCH:
        return {
            ...state,
            shipment_price_loading: false,
            shipment_price_error: ""
        }
    default:
        return state;
    }
  };
   export const ShipmentPriceByQty = (state=initialState,action) => {
        switch(action.type){
             case GET_SHIPMENT_PRICE_BY_QUANTITY_REQUEST:
                 return {
                       ...state,
                       shipment_price_by_qty_loading: true
                 }
             case GET_SHIPMENT_PRICE_BY_QUANTITY_SUCCESS:
                 return {
                      ...state,
                      shipment_price_by_qty_loading: false,
                      shipment_price_by_qty: action.payload
                 }
             case GET_SHIPMENT_PRICE_BY_QUANTITY_FAIL:
                 return {
                      ...state,
                      shipment_price_by_qty_error: action.payload,
                      shipment_price_by_qty_loading: false
                 }
              default:
                   return {
                       state
                   }
        }
   }