import React from 'react'
import MobileImageUploadSection from './MobileImageUploadSection'
import TabletImageUploadSection from './TabletImageUploadSection'
import WebImageUploadSection from './WebImageUploadSection'

const UploadSection = () => {
  return (
    <section>
        <MobileImageUploadSection />
        <TabletImageUploadSection />
        <WebImageUploadSection />
    </section>
  )
}

export default UploadSection